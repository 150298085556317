import React, { Component } from "react";

import { withStyles } from "@material-ui/styles";

//import PropTypes from "prop-types";
import Customers from "./customers";
import Groups from "./groups";
import Contacts from "./contacts";
import FormHelperText from "@material-ui/core/FormHelperText";

//import Customers from "./customers";
import MaterialCheckboxRedux from "../../../material/Inputs/checkbox";

import Grid from "@material-ui/core/Grid";
import { Field } from "redux-form";
import MaterialInputTextAreaRedux from "../../../material/Inputs/textFieldArea";

class sendMessageBodyEnglish extends Component {
  render() {
    // const { classes } = this.props;
    let limitNumber = 120;
    let error = "";

    if (this.props.push_notification) {
      limitNumber = 2000;
    }

    if (this.props.sms_notification) {
      limitNumber = 120;
    }

    if (
      !this.props.push_notification &&
      !this.props.sms_notification &&
      this.props.submitFailed
    ) {
      error = (
        <FormHelperText
          id="component-error-text"
          style={{
            color: "#840000",
            textAlign: "left",
            paddingBottom: 20
          }}
        >
          Pelo menos um tipo de mensagem é necessário.
        </FormHelperText>
      );
    }

    return (
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: 20
            }}
          >
            <Field
              name="descriptionEn"
              type="text"
              label="Descrição da mensagem"
              placeholder="Digite a descrição da mensagem"
              variant="outlined"
              multiline
              limitNumber={limitNumber}
              limit={
                this.props.sms_notification || this.props.push_notification
              }
              rows={10}
              component={MaterialInputTextAreaRedux}
              id="descriptionEn"
            />
            <Field
              name="sms_notificationEn"
              //type="sms_notification"
              inputLabel="Enviar por SMS"
              component={MaterialCheckboxRedux}
              id="sms_notificationEn"
            />

            <Field
              name="push_notificationEn"
              // type="push_notification"
              inputLabel="Enviar para o App"
              component={MaterialCheckboxRedux}
              id="push_notificationEn"
            />
            {error}
          </div>
        </Grid>
        <Grid
          container
          item
          xs={9}
          spacing={0}
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={4}
            style={{
              borderRight: "1px rgba(0, 0, 0, 0.12) solid",
              borderLeft: "1px rgba(0, 0, 0, 0.12) solid",
              height: "100%"
            }}
          >
            <Customers />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              borderRight: "1px rgba(0, 0, 0, 0.12) solid",
              height: "100%"
            }}
          >
            <Groups
              language_id={2}
              groups={this.props.groups}
              currentCustumer={this.props.currentCustumer}
              loadingGroup={this.props.loadingGroup}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              borderRight: "1px rgba(0, 0, 0, 0.12) solid",
              height: "100%"
            }}
          >
            <Contacts contacts={this.props.contacts} />
          </Grid>
        </Grid>
        {error}
      </Grid>
    );
  }
}

const styles = {
  SendMessageTitle: {
    fontWeight: 400,
    color: "black",
    opacity: 1,
    margin: 10
  },
  button: {
    margin: 5
  }
};

/*sendMessageBodyPortuguese.propTypes = {
  classes: PropTypes.object.isRequired
};*/
sendMessageBodyEnglish = withStyles(styles)(sendMessageBodyEnglish);

export default sendMessageBodyEnglish;
