import { takeLatest, put, call, select } from "redux-saga/effects";
import { Types } from "../../ducks/check-in/customers";
import { Types as TypesCreate } from "../../ducks/check-in";
import { Types as TypesCreateGroup } from "../../ducks/check-in/groups";

import axios from "../../../services/api";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet() {
  return axios.get(`/customers`);
}

function* getCustomers() {
  try {
    const resp = yield call(apiGet);

    let customers = resp.data;

    const groupsSelecteds = yield select(
      state => state.CheckInContacts.contacts
    );

    customers = customers.map(customer => {
      let count = 0;
      groupsSelecteds.map(item => {
        if (item && item.customer_id == customer.id) {
          count++;
        }
      });

      customer.groupsSelected = count;
      return customer;
    });
    yield put({
      type: Types.SUCCESS_CHECKIN_GET_CUSTOMERS,
      payload: customers
    });

    if (resp.data && typeof resp.data != undefined && resp.data.length === 1) {
      yield put({
        type: Types.CHANGE_CHECKIN_CUSTUMER,
        payload: resp.data[0]
      });

      yield put({
        type: TypesCreateGroup.REQUEST_CHECKIN_GET_GROUPS,
        payload: {
          push_notification: true,
          sms_notification: true,
          id: resp.data[0].id
        }
      });
      return yield put({
        type: TypesCreate.CHANGE_PAGE_CREATE_CHECKIN,
        payload: 1
      });
    }
  } catch (error) {
    yield put({
      type: Types.FAILURE_CHECKIN_GET_CUSTOMERS
    });
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_CHECKIN_GET_CUSTOMERS, getCustomers);
}
