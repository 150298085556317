import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { reset, startSubmit, stopSubmit } from 'redux-form';
import { Types, setPage } from '../../../ducks/message/create/index';
import { Types as TypeMessage } from '../../../ducks/main/message';

import { setOpenCreate } from '../../../ducks/message/list';
import { changeContactSelecteds } from '../../../ducks/message/create/contacts';

import axios from '../../../../services/api';
import { Messages } from '../../../../components/alert/messages';

import { formatGroupsCorporate } from '../../../../utils/form/index';

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/
const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};

function apiCreate(formData, groups) {
  return axios.post(`/message/corporate`, {
    ...formData,
    groups: formatGroupsCorporate(groups).filter(distinct),
  });
}

function* deleteFile({ payload: id }) {
  const { files: oldFiles } = yield select(state => state.CreateMessage);

  try {
    yield call(axios.delete, '/files', { data: { ids: [id] } });

    const files = oldFiles.filter(file => file.id !== id);

    yield put({ type: Types.DELETE_FILE_SUCCESS, payload: { files } });
  } catch (err) {}
}

function* uploadFile({ payload: files }) {
  const { files: oldFiles } = yield select(state => state.CreateMessage);

  const dataFormatted = new FormData();

  const arrayOfFiles = [];
  for (let file = 0; file < files.length; file += 1) {
    arrayOfFiles.push(files[file]);
  }

  arrayOfFiles.forEach(file => dataFormatted.append('files', file));

  try {
    if (
      oldFiles.length >= 5 ||
      (oldFiles.length && oldFiles.length + arrayOfFiles.length > 5)
    ) {
      throw Error;
    }

    const { data } = yield call(axios.post, '/files', dataFormatted);

    const responseFiles = data.map(file => ({
      id: file.id,
      name: file.name,
      path: file.path,
      url: file.url,
    }));

    yield put({
      type: Types.UPLOAD_FILE_SUCCESS,
      payload: { files: responseFiles },
    });
  } catch (err) {
    yield put({ type: Types.FILE_ERROR });
  }
}

function* CreateMessage(action) {
  const { files } = yield select(state => state.CreateMessage);

  if (files.length) {
    const ids = files.map(file => file.id);
    action.payload.file_ids = ids;
  }

  try {
    yield put(startSubmit('MESSAGE_CREATE_FORM'));
    const contactSelecteds = yield select(
      state => state.CreateMessageContacts.contacts
    );

    yield call(apiCreate, action.payload, contactSelecteds);

    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: 'Mensagem enviada com sucesso.',
        variant: 'success',
      },
    });

    yield put(stopSubmit('MESSAGE_CREATE_FORM'));
    yield put(setPage(0));
    yield put(setOpenCreate(false));
    yield put(reset('MESSAGE_CREATE_FORM'));
    yield put({ type: Types.RESET_FILE_STATE });
    yield put(changeContactSelecteds([]));

    //  window.location.replace(process.env.PUBLIC_URL + "/");
  } catch (err) {
    console.log(err, 'erro');
    const messageError = err.response;
    let messageAlert = null;

    if (messageError && messageError.data.hasOwnProperty('errors')) {
      if (messageError.status === 422) {
        messageAlert = Messages.REQUIRED_FIELDS;
        if (messageError.data.errors) {
          const errorContacts = messageError.data.errors.contacts_id
            ? 'Algum contato precisa estar marcado'
            : '';
          yield put(
            stopSubmit('MESSAGE_CREATE_FORM', {
              ...messageError.data.errors,
              _error: errorContacts,
            })
          );
        }
      }
    }

    if (messageAlert) {
      yield put({
        type: TypeMessage.MESSAGE,
        payload: { message: messageAlert, variant: 'error' },
      });
    } else {
      yield put({
        type: TypeMessage.MESSAGE,
        payload: { message: Messages.ERROR_500, variant: 'error' },
      });
      yield put(stopSubmit('MESSAGE_CREATE_FORM'));
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(Types.REQUEST_CREATE_MESSAGE, CreateMessage),
    takeLatest(Types.UPLOAD_FILE_REQUEST, uploadFile),
    takeLatest(Types.DELETE_FILE, deleteFile),
  ]);
}
