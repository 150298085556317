import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Tooltip, CircularProgress } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';

function ToolTipMap({ contact_id, message_id, location }) {
  const [address, setAddress] = useState(null);
  const [actived, setActived] = useState(false);
  const [onLoad, setOnload] = useState(false);

  const getGeocode = async latlng => {
    const { google } = window;

    if (google) {
      return new Promise((resolve, reject) => {
        new google.maps.Geocoder().geocode({ location: latlng }, results => {
          if (results && results.length > 0) {
            return resolve(results[0].formatted_address);
          }
          return reject();
        });
      });
    }
    return Promise.reject(
      new Error('Houve um erro, tente novamente em alguns minutos')
    );
  };

  useEffect(() => {
    async function loadGeoCode() {
      if (actived && !onLoad && !address) {
        const { google } = window;

        const addressData = await getGeocode(
          new google.maps.LatLng(
            location.coordinates[1],
            location.coordinates[0]
          )
        );
        setOnload(true);
        setAddress(addressData);
      }
    }
    loadGeoCode();
  }, [location.coordinates, actived, onLoad, address]);

  return (
    <Tooltip
      title={
        address || <CircularProgress size={20} style={{ color: '#FFF' }} />
      }>
      <LocationOnIcon
        color="primary"
        onMouseMove={() => setActived(true)}
        fontSize="large"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          window.open(`/location/checkin/${message_id}/${contact_id}`, '_blank')
        }
      />
    </Tooltip>
  );
}

ToolTipMap.propTypes = {
  contact_id: PropTypes.number.isRequired,
  message_id: PropTypes.number.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ToolTipMap;
