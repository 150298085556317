import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { Field } from 'redux-form';
import MaterialInputSelectRedux from '../../../material/Inputs/select';
import { Error, SignalCellular4Bar, LocationOn } from '@material-ui/icons';

import Chip from '@material-ui/core/Chip';

class detailIncident extends Component {
  render() {
    const { incident, classes } = this.props;

    const category = categoryId => {
      switch (categoryId) {
        case 2: // banco monit  2 -> banco umessenger 1
          return (
            <Chip
              icon={<Error style={{ color: 'red' }} />}
              label="Segurança"
              variant="outlined"
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fff',
                color: 'red',
              }}
            />
          );
        case 1: // banco monit  1 -> banco umessenger 2
          return (
            <Chip
              icon={<SignalCellular4Bar />}
              label="Trânsito"
              variant="outlined"
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fff',
                color: '#217F4E',
              }}
            />
          );
        case 7: // banco monit  7 -> banco umessenger 3
          return (
            <Chip
              icon={<Error style={{ color: '#FFBB21' }} />}
              label="Contigência"
              variant="outlined"
              style={{
                fontWeight: 'bold',
                backgroundColor: '#fff',
                color: '#FFBB21',
              }}
            />
          );
        default:
          break;
      }
    };

    if (incident) {
      return (
        <Grid
          container
          spacing={0}
          style={{ paddingTop: 10, paddingBottom: 20 }}>
          <Grid item xs={1} className={classes.incidentTypeSymbol}>
            {category(incident.type.category.id)}
          </Grid>

          <Grid item xs={1} className={classes.incidentCredibility}>
            <Rating
              style={{}}
              value={incident.credibility}
              readOnly
              size="small"
            />
          </Grid>

          <Grid item xs={2} className={classes.incidentCriticality}>
            {incident.type.category.id === 2 ? ( // segurança
              <Chip
                label={'Criticidade Alta'}
                size="small"
                style={{
                  backgroundColor: '#5C5C5C',
                  color: '#fff',
                  marginRight: '0.5vw',
                }}
              />
            ) : (
              <div style={{ marginTop: -35 }}>
                <Field
                  name="criticality"
                  inputLabel="Criticidade do Alerta"
                  placeholder="Digite a operadora do contato"
                  data={[
                    {
                      id: 1,
                      name: 'Criticidade Baixa',
                    },
                    {
                      id: 2,
                      name: 'Criticidade Média',
                    },
                    {
                      id: 3,
                      name: 'Criticidade Alta',
                    },
                  ]}
                  component={MaterialInputSelectRedux}
                  id="criticality"
                />
              </div>
            )}
          </Grid>

          <Grid item xs={3} className={classes.incidentTime}>
            {incident.startedAt && (
              <>
                <span style={{ fontWeight: 'bold' }}>Início:</span>{' '}
                {moment(incident.startedAt)
                  .utc()
                  .format('DD/MM/YYYY - HH:mm')}
              </>
            )}
          </Grid>

          <Grid item xs={3} className={classes.incidentTime}>
            {incident.endedAt && (
              <>
                <span style={{ fontWeight: 'bold' }}>Término:</span>{' '}
                {moment(incident.endedAt)
                  .utc()
                  .format('DD/MM/YYYY - HH:mm')}
              </>
            )}
          </Grid>

          <Grid item xs={2} className={classes.incidentCriticality} />

          <Grid item xs={2} className={classes.incidentTypes}>
            {incident.type.type}
          </Grid>

          <Grid item xs={10} className={classes.incidentTypes}>
            <LocationOn style={{ color: '#2288FF' }} /> {incident.address}
          </Grid>
        </Grid>
      );
    } else {
      return <div />;
    }
  }
}

const styles = {
  incidentTypeSymbol: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 50,
  },
  incidentCredibility: {
    paddingTop: 17,
    paddingBottom: 10,
    paddingLeft: 50,
  },
  incidentCriticality: {
    paddingTop: 17,
    paddingBottom: 10,
    paddingLeft: 50,
  },
  incidentTypes: {
    paddingTop: 10,
    paddingLeft: 50,
    fontWeight: 'light',
    fontSize: 20,
  },
  incidentTime: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 50,
    fontWeight: 'light',
    fontSize: 20,
  },
};

detailIncident.propTypes = {
  classes: PropTypes.object.isRequired,
};

detailIncident = withStyles(styles)(detailIncident);

export default detailIncident;
