import styled from 'styled-components';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";


export const ListContainer = styled(List)`
  max-width: 100%;
  max-height: 400px;
  min-height: 400px;
  position: relative;
  overflow: auto;
  padding: 0;
  overflow-x: hidden;
  margin: 0;
`;

export const ListItemWrapper = styled(ListItem)`
  border-bottom: 1px solid #ccc;
`;

export const PaperContainer = styled(Paper)`
  min-width: 400px;
  max-width: 500px;
  margin-bottom: 10px;
`;

export const Input = styled(InputBase)`
  width: 80%;
`;

export const Loading = styled.div`
  margin: 50px;
  text-align: center;
`;



