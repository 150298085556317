import styled from 'styled-components';

import Grid from "@material-ui/core/Grid";

export const Search = styled(Grid)`
  margin: 0;
  flex-direction: row;
  display: flex;
  justify-content: start;
  align-items: center;

  div {
    margin: 0;
    width: 100%;
  }
`;

export const FilterContainer = styled(Grid)`
  margin: 0;
  position: absolute;
  right: 80px;

  h5 {
    font-weight: 600;
    color: #26408B;
    font-size: 13px;

    div {
      background-color: #26408B;
      color: #fff;
      height: 24px;
    }
  }
`;

export const FilterModal = styled.div`
  width: 300px;

  h5 {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
  }

  .close-icon {
    position: absolute; 
    right: 0; 
    top: 10px;
  }

  .status-input {
    margin: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  button {
    display: flex;
    justify-content: center;
    width: 200px !important;
    font-size: 15px;
    background-color: #3C5CB9;
    color: white;

    &:hover {
      background-color: #243e8a;
    }
  }
`;