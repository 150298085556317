import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";

import {
  getCustomers,
  setCustumer
} from "../../../store/ducks/check-in/customers";
import { getGroups } from "../../../store/ducks/check-in/groups";
import { setPage } from "../../../store/ducks/check-in";

import { 
  ListContainer, 
  ListChild, 
  TitleButton, 
  InputContainer, 
  Loading 
} from './styles';

function CheckInCustomer() {
  const [searchInput, setSearchInput] = useState('');
  
  const dispatch = useDispatch();
  const { 
    data: customers, 
    loading: loadingCustumers 
  } = useSelector(state => state.CheckInCustomers);

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  const changeCustomers = id => {
    let payload = {
      push_notification: true,
      sms_notification: true,
      id
    };
    dispatch(setCustumer(id));
    dispatch(getGroups(payload));
    dispatch(setPage(1));
  };

  const changeInputSearch = () => event => {
    setSearchInput(event.target.value);
  };

    let customersRender = [];
    if (customers) {
      customers.forEach(item => {
        if (
          searchInput === "" ||
          item.name
            .toUpperCase()
            .includes(searchInput.toUpperCase())
        ) {
          customersRender.push(
            <ListChild
              button
              onClick={() => changeCustomers(item.id)}
              key={item.id}
            >
              <ListItemText primary={item.name} />

              {item.groupsSelected && item.groupsSelected > 0 && (
                <Typography color={"primary"}>
                  {item.groupsSelected} selecionados
                </Typography>
              )}
            </ListChild>
          );
        }
      });
    }

    const Customers =
      !loadingCustumers && (customers || false) !== true ? (
        <div>
          <TitleButton variant="text" color="default">
            Empresas
          </TitleButton>
          <InputContainer>
            <IconButton disabled={true} aria-label="Search" type="submit">
              <SearchIcon />
            </IconButton>
            <InputBase
              name="search_input"
              onChange={changeInputSearch()}
              placeholder="Procurar por nome da empresa ..."
              id="search_input"
            />
          </InputContainer>

          <ListContainer>
            {customersRender.length > 0 ? (
              customersRender
            ) : (
              <Typography variant="h5" component="h4">
                Nenhuma empresa encontrada.
              </Typography>
            )}
          </ListContainer>
        </div>
      ) : (
        <Loading>
          <CircularProgress color="primary" size={50} />
        </Loading>
      );

    return <div>{Customers}</div>;
  }

export default CheckInCustomer;