import styled from 'styled-components';

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

export const PaperContainer = styled(Paper)`
  min-width: 400px;
  max-width: 500px;
`;

export const Input = styled(InputBase)`
  margin-right: 20px;
  width: 80%;
`;

export const ListContainer = styled(List)`
max-width: 100%;
max-height: 300px;
min-height: 300px;
position: relative;
overflow: auto;
padding: 0px;
overflow-x: hidden;
margin: 0px;
`;

export const ListItemWrapper = styled(ListItem)`
  border-bottom: 1px solid #ccc;
`;

export const Loading = styled.div`
  margin: 50px;
  text-align: center;
`;