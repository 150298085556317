import { takeLatest, put, call } from "redux-saga/effects";
import { Types } from "../../ducks/main/login";
import { Types as TypeMessage } from "../../ducks/main/message";
import axios from "../../../services/api.js";
import { login as loginRequest } from "../../../services/loginPassport";
import { Messages } from "../../../components/alert/messages";
import { startSubmit, stopSubmit } from "redux-form";
//import { reformateErrors } from "../../../utils/form";
import { removeSSOIntegration } from "../../../services/sso";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiPassport(email, password) {
  return loginRequest(email, password);
}
function apiLogin(email, password, access_token) {
  return axios.post(`/user/login`, { email, password, access_token });
}
function* Login(action) {
  try {
    yield put(startSubmit("LOGIN_FORM"));
    const resp = yield call(
      apiPassport,
      action.payload.email,
      action.payload.password
    );

    yield call(
      apiLogin,
      action.payload.email,
      action.payload.password,
      resp.data.access_token
    );

    const remember = action.payload.remember;
    let payload = {
      remember,
      email: action.payload.email
    };
    //console.log(action.payload);

    yield call(removeSSOIntegration);

    localStorage.setItem("token", resp.data.access_token);
    localStorage.setItem("refreshToken", resp.data.refresh_token);
    localStorage.setItem("rememberLogin", JSON.stringify(payload));

    yield put({
      type: Types.SUCCESS_LOGIN,
      resp
    });
    yield put(stopSubmit("LOGIN_FORM"));

    window.location.replace(process.env.PUBLIC_URL + "/");
  } catch (err) {
    let messageError = err.response;
    let messageAlert = null;

    if (messageError) {
      if (messageError.status === 400) {
        messageAlert = Messages.REQUIRED_FIELDS;
        yield put(stopSubmit("LOGIN_FORM", { email: [], password: [] }));
      } else if (messageError.status === 401) {
        messageAlert = Messages.CREDENTIALS_INVALID;
        yield put(stopSubmit("LOGIN_FORM", { email: [], password: [] }));
      }
    }

    yield put({ type: Types.FAILURE_LOGIN });

    if (messageAlert) {
      yield put({
        type: TypeMessage.MESSAGE,
        payload: { message: messageAlert, variant: "error" }
      });
    } else {
      yield put({
        type: TypeMessage.MESSAGE,
        payload: { message: Messages.ERROR_500, variant: "error" }
      });
    }
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_LOGIN, Login);
}
