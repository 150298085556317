// types
export const Types = {
  REQUEST_GET_CUSTOM_COVERAGE_AREAS: "REQUEST_GET_CUSTOM_COVERAGE_AREAS",
  SUCCESS_GET_CUSTOM_COVERAGE_AREAS: "SUCCESS_GET_CUSTOM_COVERAGE_AREAS",
  FAILURE_GET_CUSTOM_COVERAGE_AREAS: "FAILURE_GET_CUSTOM_COVERAGE_AREAS"
};

const INITIAL_VALUES = {
  loadingCustom: null,
  errorCustom: false,
  dataCustom: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_GET_CUSTOM_COVERAGE_AREAS:
      return {
        ...state,
        loadingCustom: true
      };
    case Types.SUCCESS_GET_CUSTOM_COVERAGE_AREAS:
      return {
        ...state,
        loadingCustom: false,
        errorCustom: false,
        dataCustom: action.payload
      };
    case Types.FAILURE_GET_CUSTOM_COVERAGE_AREAS:
      return { ...state, loadingCustom: false, errorCustom: true };

    default:
      return state;
  }
}

export function getCustomCoverageAreas() {
  return { type: Types.REQUEST_GET_CUSTOM_COVERAGE_AREAS };
}
