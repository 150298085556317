import styled from 'styled-components';
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";

export const classes = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  }
}));

export const Loading = styled(TableCell)`
  display: flex !important;
  justify-content: center !important;

  > div {
    margin: 50px 0 50px 0;
  }
`;

export const GridContainer = styled(Grid).attrs({
  container: true,
  spacing: 1,
})`
  padding: 20px;

  .MuiGrid-grid-xs-3 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
`;

export const NoContactFound = styled.div`
  h4 {
    font-weight: 400;
    color: #333;
    opacity: 0.6;
    text-align: center;
    margin-top: 20px;
  }
`;

export const Pagination = styled(TablePagination).attrs({
  rowsPerPageOptions: [25, 50, 100, 200]
})`
  display: flex;
`;