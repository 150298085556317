// types
export const Types = {
  CHANGE_MESSAGE_CONTACT_INCIDENT: "CHANGE_MESSAGE_CONTACT_INCIDENT",
  MESSAGE_CONTACT_INCIDENT_SET_DATA_CONTACTS_EN:
    "MESSAGE_CONTACT_INCIDENT_SET_DATA_CONTACTS_EN",
  MESSAGE_CONTACT_INCIDENT_SET_DATA_CONTACTS:
    "MESSAGE_CONTACT_INCIDENT_SET_DATA_CONTACTS"
};
const INITIAL_VALUES = {
  data: [],
  dataEn: [],
  contacts: []
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.MESSAGE_CONTACT_INCIDENT_SET_DATA_CONTACTS:
      return {
        ...state,
        data: action.payload
      };
    case Types.MESSAGE_CONTACT_INCIDENT_SET_DATA_CONTACTS_EN:
      return {
        ...state,
        dataEn: action.payload
      };
    case Types.CHANGE_MESSAGE_CONTACT_INCIDENT:
      return {
        ...state,
        contacts: action.payload
      };
    default:
      return state;
  }
}

export function getContacts(payload) {
  return { type: Types.MESSAGE_CONTACT_INCIDENT_SET_DATA_CONTACTS, payload };
}
export function getContactsEn(payload) {
  return { type: Types.MESSAGE_CONTACT_INCIDENT_SET_DATA_CONTACTS_EN, payload };
}

export function changeContactSelecteds(payload) {
  return { type: Types.CHANGE_MESSAGE_CONTACT_INCIDENT, payload };
}
