import { takeLatest, put, call, select } from "redux-saga/effects";
import { Types } from "../../ducks/group/create";
import axios from "../../../services/api.js";
import {
  reformateErrors,
  reformateArrayFieldReduxForm
} from "../../../utils/form";

import { startSubmit, stopSubmit } from "redux-form";
import { Types as TypeMessage } from "../../ducks/main/message";
import { Types as TypesAssociationGroup } from "../../ducks/group/associationUsers";
import { Messages } from "../../../components/alert/messages";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(parameters, customer, days, areas, custom = false) {
  let schedule_start = "00:00";
  let schedule_end = "23:59";
  let data = null;

  if (
    parameters.schedules &&
    parameters.schedules.length > 0 &&
    parameters.schedules[1].value === true
  ) {
    schedule_start = parameters.schedule_start;
    schedule_end = parameters.schedule_end;
  }
  data = {
    name: parameters.name,
    critical_to_traffic: parameters.critical_to_traffic,
    status_active: parameters.status_active,
    languages: reformateArrayFieldReduxForm(parameters.languages, false),
    receives: reformateArrayFieldReduxForm(parameters.receives, false),
    days,
    coverageAreas: areas,
    category_incidents: reformateArrayFieldReduxForm(
      parameters.category_incidents,
      false
    ),
    notification_types: reformateArrayFieldReduxForm(
      parameters.notification_types,
      false
    ),
    schedule_start,
    schedule_end,
    customer_id: customer.id,
    custom
  };

  return axios.post(`/group`, {
    ...data
  });
}

function* createGroup(action) {
  yield put(startSubmit("GROUP_FORM"));
  const custom = action.payload.user_custom_id ? true : false;
  try {
    const customer = yield select(state => state.Customers.customer);
    const days = yield select(state => state.GroupCreate.daysWeek);
    let areas = yield select(state => state.GroupCreate.coverageAreas);
    areas = areas.map(item => {
      return item.id;
    });
    const resp = yield call(
      apiGet,
      action.payload,
      customer,
      days,
      areas.length > 0 ? areas : null,
      custom
    );
    if (custom) {
      yield put({
        type: TypesAssociationGroup.REQUEST_ASSOCIATION_USER_CUSTOM_GROUP,
        payload: {
          user_id: action.payload.user_custom_id,
          group_id: resp.data.external_id
        }
      });
    }

    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.CREATE_SUCCESS_GROUP,
        variant: "success"
      }
    });
    if (!custom) {
      window.location.href =
        process.env.PUBLIC_URL +
        `/grupo/associarusuarios/${customer.id}/${resp.data.external_id}`;
    }

    yield put(stopSubmit("GROUP_FORM"));
  } catch ({ response }) {
    if (response && response.data) {
      yield put(
        stopSubmit("GROUP_FORM", reformateErrors(response.data.errors))
      );
    }
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.CREATE_ERROR_GROUP,
        variant: "error"
      }
    });
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_GROUP_FORM, createGroup);
}
