import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import StarsIcon from '@material-ui/icons/Stars';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';

import { reduxForm, Field } from 'redux-form';
import MaterialInputSwitchRedux from '../../material/Inputs/switch';
import ListGroup from './listGroup';

import { getContactEdit } from '~/store/ducks/contact/form';
import { Types } from '~/store/ducks/contact/update';

import {
  PaperContainer,
  Loading,
  PersonalizeContainer,
  PersonalizeButton,
  DataContainer,
  GridRow,
  GroupTableContainer,
  FooterContainer,
} from './styles';

function ContactFormEdit({ handleSubmit, submitting }) {
  const dispatch = useDispatch();
  const { contactId } = useParams();

  const { userContact, loadingContact, contact: initialValues } = useSelector(
    state => state.FormContact
  );
  const { groupsByContact: groups, loading: groupsLoading } = useSelector(
    state => state.AssociationGroup
  );

  useEffect(() => {
    dispatch(getContactEdit(contactId));
  }, [dispatch, contactId]);

  const checkExistsUser = groupData => {
    const groupsCustom = groupData.filter(
      group =>
        group.groupInfo.custom &&
        group.users.filter(
          user => parseInt(user.id, 10) === parseInt(contactId, 10)
        ).length > 0
    );
    if (groupsCustom.length > 0) return groupsCustom;

    return false;
  };

  function renderForm() {
    let groupRender = null;
    if (!groupsLoading) {
      const groupCustom = checkExistsUser(groups);
      if (groupCustom) {
        groupRender = (
          <PersonalizeContainer>
            <div>
              <Typography variant="h2" component="h2">
                Usuário personalizado
              </Typography>
              <div>
                <StarsIcon color="primary" />
              </div>
            </div>

            <PersonalizeButton
              onClick={() =>
                window.location.replace(`/grupo/editar/${groupCustom[0].id}`)
              }>
              <Typography variant="h2" component="h2">
                • Clique para visualizar
              </Typography>
            </PersonalizeButton>
          </PersonalizeContainer>
        );
      } else {
        groupRender = (
          <PersonalizeContainer>
            <div>
              <Typography variant="h2" component="h2">
                Usuário personalizado
              </Typography>
              <div>
                <StarsIcon color="primary" />
              </div>
            </div>
            <PersonalizeButton
              onClick={() =>
                window.location.replace(
                  `/grupo/novo?contact_name=${userContact.name}&contact_id=${userContact.id}`
                )
              }>
              <Typography variant="h2" component="h2">
                • Clique para criar
              </Typography>
            </PersonalizeButton>
          </PersonalizeContainer>
        );
      }
    }
    return (
      <form
        onSubmit={handleSubmit(values =>
          dispatch({
            type: Types.REQUEST_CONTACT_FORM_UPDATE,
            payload: values,
          })
        )}>
        <Grid container spacing={0}>
          <DataContainer item xs={9}>
            <Grid container spacing={0}>
              <GridRow item xs={12} sm={3}>
                <div className="row">
                  <Typography variant="h5" component="h4">
                    Dados pessoais
                  </Typography>

                  <FormControl margin="normal" fullWidth>
                    <InputLabel>Nome</InputLabel>
                    <br />
                    <div>
                      <Typography variant="h5" component="h4">
                        {userContact.name}
                      </Typography>
                      {userContact.contact.notification_token && (
                        <Tooltip title="Logado no Aplicativo">
                          <div>
                            <IconButton>
                              <FiberManualRecordIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </FormControl>
                </div>
              </GridRow>
              <GridRow item xs={12} sm={3}>
                <div className="row">
                  <br />
                  <FormControl margin="normal" fullWidth>
                    <InputLabel>E-mail</InputLabel>
                    <br />
                    <Typography variant="h5" component="h4">
                      {userContact.email}
                    </Typography>
                  </FormControl>
                </div>
              </GridRow>
              <GridRow item xs={12} sm={3}>
                <div className="row">
                  <br />
                  {userContact.phones && (
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Celular</InputLabel>
                      <br />
                      <Typography variant="h5" component="h4">
                        {userContact.phones && userContact.phones.length > 0 ? (
                          userContact.phones.map(phone => (
                            <div key={phone.id}>{phone.name}</div>
                          ))
                        ) : (
                          <div>Sem Celular</div>
                        )}
                      </Typography>
                    </FormControl>
                  )}
                </div>
              </GridRow>
            </Grid>

            <Grid container spacing={0}>
              <GridRow item xs={12} sm={2}>
                <Field
                  name="status_active"
                  type="checkbox"
                  ActiveLabel="Ativo"
                  DesibleLabel="Desativado"
                  component={MaterialInputSwitchRedux}
                  id="status_active"
                  autoFocus
                />
              </GridRow>
              <GridRow item xs={12} sm={12}>
                <div>
                  <Typography variant="h4" component="h3">
                    Personalização
                  </Typography>
                  {!groupsLoading && groupRender}
                </div>
              </GridRow>
            </Grid>
          </DataContainer>
          <GroupTableContainer item xs={3}>
            <ListGroup
              user_id={userContact.id}
              customer_id={userContact.customer_id}
            />
          </GroupTableContainer>
          <FooterContainer item xs={12}>
            <Grid container spacing={0}>
              <Fab
                variant="extended"
                size="small"
                type="submit"
                color="primary"
                aria-label="Save"
                disabled={submitting}>
                Salvar
              </Fab>
            </Grid>
          </FooterContainer>
        </Grid>
      </form>
    );
  }

  return (
    <PaperContainer>
      {!loadingContact && initialValues ? (
        renderForm()
      ) : (
        <Loading>
          <CircularProgress color="primary" size={50} />
        </Loading>
      )}
    </PaperContainer>
  );
}

ContactFormEdit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    initialValues: state.FormContact.contact,
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'CONTACT_FORM_EDIT', enableReinitialize: true })(
    ContactFormEdit
  )
);
