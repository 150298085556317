import axios from "axios";
import qs from "qs";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_URL_API
});

Axios.interceptors.response.use(undefined, err => {
  let res = err.response;

  if (res.status === 403) {
    window.location.replace("/403");
  }
  return Promise.reject(err);
});
export const getCustomers = token => {
  Axios.defaults.headers.common["Authorization"] = token;
  return Axios.get("/customers/operator");
};

export const getGroups = (token, data) => {
  Axios.defaults.headers.common["Authorization"] = token;

  return Axios.get("/groupsSendMessageMonit", {
    params: data,
    paramsSerializer: params => {
      return qs.stringify(params);
    }
  });
};

export const getIncident = (token, id) => {
  Axios.defaults.headers.common["Authorization"] = token;

  return Axios.get(`/incidents/${id}`);
};

export const createMessage = (token, data) => {
  Axios.defaults.headers.common["Authorization"] = token;

  return Axios.post(`/message/monit`, data);
};
