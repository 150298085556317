import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../components/login';
import Message from '../components/message/list';
import MessageCreate from '../components/message/create';

import ContactList from '../components/contact/list/index';
import GroupList from '../components/group/list/index';

import ContactFormEdit from '../components/contact/formEdit/index';
import Error404 from '../components/errors/404';
import Error403 from '../components/errors/403';

import Private from './private';
import Alert from '../components/alert';
import GroupForm from '../components/group/form';
import GroupEdit from '../components/group/formEdit';

import AssociationUser from '../components/group/associationUser';

import LoginSSO from '../components/login/sso';

import MonitMessageCreate from '../components/monit/message/create';
import MessageIncidentPublic from '../components/messageIncidentPublic';
import MessageCheckinPublic from '../components/messageCheckinPublic';
import MessageCheckinLocation from '../components/check-in/location';
import AppPrivacy from '../components/AppPrivacy';

export default function Routes() {
  return (
    <>
      <Alert />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Private
            path="/location/checkin/:id/:contact_id"
            exact
            component={MessageCheckinLocation}
          />
          <Route
            path="/incidente/:id"
            exact
            component={MessageIncidentPublic}
          />

          <Route
            path="/checkin/:external_access_code"
            exact
            component={MessageCheckinPublic}
          />

          <Route
            path="/app/privacy"
            exact
            component={AppPrivacy}
          />

          <Route path="/login" exact component={Login} />
          <Route path="/login/sso" exact component={LoginSSO} />
          <Private path="/" exact component={Message} />
          <Private path="/mensagem/nova" exact component={MessageCreate} />
          <Private path="/403" exact component={Error403} />
          <Private
            path="/contatos"
            exact
            customers
            component={ContactList}
            breadcrumbsProps={[
              {
                url: `${process.env.PUBLIC_URL}/`,
                name: 'Home',
              },
              {
                name: 'Contatos',
              },
            ]}
          />

          <Private
            path="/grupo/associarusuarios/:customerId/:groupId"
            exact
            customers
            groups
            disabledCustomers
            disabledGroups
            component={AssociationUser}
            breadcrumbsProps={[
              {
                url: `${process.env.PUBLIC_URL}/`,
                name: 'Home',
              },
              {
                url: `${process.env.PUBLIC_URL}/grupos`,
                name: 'Grupos',
              },
              {
                name: 'Associar Usuários',
              },
            ]}
          />

          <Private
            path="/grupo/novo"
            exact
            customers
            component={GroupForm}
            breadcrumbsProps={[
              {
                url: `${process.env.PUBLIC_URL}/`,
                name: 'Home',
              },
              {
                url: `${process.env.PUBLIC_URL}/grupos`,
                name: 'Grupos',
              },
              {
                name: 'Novo Grupo',
              },
            ]}
          />

          <Private
            path="/grupo/editar/:groupId"
            exact
            customers
            component={GroupEdit}
            breadcrumbsProps={[
              {
                url: `${process.env.PUBLIC_URL}/`,
                name: 'Home',
              },
              {
                url: `${process.env.PUBLIC_URL}/grupos`,
                name: 'Grupos',
              },
              {
                name: 'Editar Grupo',
              },
            ]}
          />
          <Private
            path="/grupos"
            exact
            customers
            component={GroupList}
            breadcrumbsProps={[
              {
                url: `${process.env.PUBLIC_URL}/`,
                name: 'Home',
              },
              {
                name: 'Grupos',
              },
            ]}
          />

          <Private
            path="/contato/editar/:contactId"
            exact
            customers={false}
            component={ContactFormEdit}
            breadcrumbsProps={[
              {
                url: `${process.env.PUBLIC_URL}/`,
                name: 'Home',
              },
              {
                url: `${process.env.PUBLIC_URL}/contatos`,
                name: 'Contatos',
              },
              {
                name: 'Editar Contato',
              },
            ]}
          />

          <Route
            path="/monitIncident/:incidentId"
            headerSimple
            exact
            component={MonitMessageCreate}
          />

          <Private path="*" exact component={Error404} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
