// types
export const Types = {
  REQUEST_DELETE_RESTORE_CONTACT: "REQUEST_DELETE_RESTORE_CONTACT",
  SUCCESS_DELETE_RESTORE_CONTACT: "SUCCESS_DELETE_RESTORE_CONTACT",
  FAILURE_DELETE_RESTORE_CONTACT: "FAILURE_DELETE_RESTORE_CONTACT"
};

const INITIAL_VALUES = {
  loading: false,
  error: false
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_DELETE_RESTORE_CONTACT:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_DELETE_RESTORE_CONTACT:
      return {
        loading: false,
        error: false
      };
    case Types.FAILURE_DELETE_RESTORE_CONTACT:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
}

export function deleteRestoreContact(contactId, status) {
  let payload = {
    contactId,
    status
  };
  return { type: Types.REQUEST_DELETE_RESTORE_CONTACT, payload };
}
