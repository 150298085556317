import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { getGroups, setGroup } from "../../store/ducks/group/list";

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  async componentDidUpdate(oldProps) {
    if (oldProps.customer !== this.props.customer) {
      this.props.getGroups();
    }
  }
  handleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange = event => {
    let payload = {
      id: event.target.value,
      name: event.target.name
    };
    this.props.setGroup({ ...payload });
  };

  renderSelect() {
    const { groups, loading, group, loadingCustomer } = this.props;

    if (loading || loadingCustomer) {
      return <CircularProgress color="primary" size={20} />;
    }
    let groupsItem =
      groups && groups.length > 0
        ? groups.map(item => (
            <MenuItem value={item.id} key={item.id}>
              <b>
                {item.groupInfo.status_active
                  ? item.name + " (Ativo)"
                  : item.name + " (Desativado)"}
              </b>
            </MenuItem>
          ))
        : "";
    return (
      <Fragment>
        {this.props.customer && this.props.groups && (
          <div>
            <span style={{ fontWeight: "600" }}> Grupo - </span>
            <Select
              open={this.state.open}
              onClose={this.handleOpen}
              onOpen={this.handleOpen}
              value={group && typeof group != undefined ? group.id : ""}
              onChange={this.handleChange}
              disableUnderline
              disabled={this.props.disabled}
              inputProps={{
                name: "name",
                id: "value"
              }}
            >
              {groupsItem}
            </Select>
          </div>
        )}
      </Fragment>
    );
  }

  render() {
    return (
      <div style={{ marginTop: 20, marginLeft: 45 }}>{this.renderSelect()}</div>
    );
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getGroups, setGroup }, dispatch);

const mapStateToProps = state => {
  return {
    groups: state.ListGroups.data,
    loading: state.ListGroups.loading,
    loadingCustomer: state.Customers.loading,
    group: state.ListGroups.group,
    customer: state.Customers.customer
  };
};

// Decorator para Redux Form
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Groups);
