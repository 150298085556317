import { takeLatest, put, call, all } from "redux-saga/effects";
import { Types } from "../../ducks/contact/associationGroup";
import axios from "../../../services/api.js";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGetAllGroups(customer_id, application_id) {
  return axios.get(
    `/groups?customer_id=${customer_id}&application_id=${application_id}`
  );
}

function* getGroups(action) {
  try {
    const application_id = process.env.REACT_APP_APPLICATION_ID;
    const resp = yield call(
      apiGetAllGroups,
      action.payload.customer_id,
      application_id
    );

    yield put({
      type: Types.SUCCESS_LIST_GROUP,
      payload: resp.data
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_LIST_GROUP
    });
  }
}

export default function* root() {
  yield all([takeLatest(Types.REQUEST_LIST_GROUP, getGroups)]);
}
