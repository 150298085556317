import React, { Component, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { submit, change, formValueSelector } from "redux-form";
import ContactTable from "./Table/index";

/*import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import UploadIcon from "@material-ui/icons/CloudUpload";
import CallMergeIcon from "@material-ui/icons/CallMerge";*/
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import { bindActionCreators } from "redux";
import Checkbox from "@material-ui/core/Checkbox";

import { associationUsersGroup } from "../../../store/ducks/group/associationUsers";
import {
  setTotalContactsSelected,
  showCancel
} from "../../../store/ducks/group/listContacts";
import { setGroup } from "../../../store/ducks/group/list";
import { setCustumer } from "../../../store/ducks/customers";

class ContactList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  componentDidMount() {
    let payloadCustomer = {
      id: this.props.match.params.customerId
    };
    this.props.setCustumer({ ...payloadCustomer });

    let payloadGroup = {
      id: this.props.match.params.groupId
    };
    this.props.setGroup({ ...payloadGroup });
  }

  componentDidUpdate = async oldProps => {
    if (oldProps.page !== this.props.page) {
      this.setState({ checked: false });
    }

    if (oldProps.rowsPerPage !== this.props.rowsPerPage) {
      this.setState({ checked: false });
    }
  };

  checkAll = event => {
    let check = !this.state.checked;
    let totalContactsPage = 0;
    let selected;
    const { change, contacts, rowsPerPage, page } = this.props;

    const selector = formValueSelector("FORM_ASSOCIATION_USERS_GROUP");

    this.setState({ checked: check });

    contacts
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(item => {
        change("FORM_ASSOCIATION_USERS_GROUP", `users_id.${item.id}}`, check);
        return true;
      });

    setTimeout(() => {
      contacts.map(item => {
        selected = selector(this.props.state, `users_id.${item.id}`);

        if (selected) {
          totalContactsPage++;
        }
        return true;
      });
      this.props.showCancel();
      this.props.setTotalContactsSelected(totalContactsPage);
    }, 500);
  };

  render() {
    const {
      history,
      submitForm,
      associationUsersGroup,
      totalContactsSelected
    } = this.props;
    /*  const buttons = (
      <Fragment>
    
       <Fab
          aria-label={"Adicionar novo contato"}
          color={"primary"}
          style={{ position: "fixed", right: 30, bottom: 20 }}
          onClick={() => history.push("/contato/novo")}
        >
          <AddIcon />
        </Fab>

        <Fab
          aria-label={"Upload Excel"}
          style={{
            position: "fixed",
            backgroundColor: "#999999",
            right: 30,
            bottom: 90
          }}
        >
          <UploadIcon style={{ color: "#FFF" }} />
        </Fab>

        <Fab
          aria-label={"Upload Excel"}
          style={{
            position: "fixed",
            backgroundColor: "#999999",
            right: 30,
            bottom: 160
          }}
        >
          <CallMergeIcon style={{ color: "#FFF" }} />
        </Fab>
      </Fragment>
    );*/
    return (
      <Fragment>
        {/*buttons*/}

        <div
          style={{
            marginTop: 20,
            marginRight: 50,
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center"
          }}
        >
          <div
            style={{
              backgroundColor: "#26408B",
              display: "flex",
              justifyContent: "flex-end",
              alignSelf: "center",
              borderRadius: "13px"
            }}
          >
            <Fab
              variant="extended"
              size="small"
              aria-label="Save"
              onClick={() => {
                submitForm("FORM_ASSOCIATION_USERS_GROUP");
              }}
              style={{
                width: 120,
                fontSize: 15,
                backgroundColor: "#3C5CB9",
                color: "white",
                margin: "5px"
              }}
            >
              Salvar
            </Fab>
            {this.props.varShowCancel && (
              <Fab
                variant="extended"
                size="small"
                aria-label="Save"
                onClick={() => {
                  window.location.reload();
                }}
                style={{
                  width: 120,
                  fontSize: 15,
                  backgroundColor: "#3C5CB9",
                  color: "white",
                  margin: "5px"
                }}
              >
                Cancelar
              </Fab>
            )}

            <Typography
              style={{
                fontWeight: 100,
                color: "white",
                fontSize: 15,
                textAlign: "center",
                alignSelf: "center",
                marginLeft: 10,
                marginRight: 10
              }}
            >
              {totalContactsSelected} contatos selecionados
            </Typography>
          </div>
          <Checkbox
            checked={this.state.checked}
            onChange={this.checkAll}
            color="primary"
          />
          <Typography
            style={{
              fontWeight: "bold",
              color: "black",
              opacity: 0.6,
              fontSize: 15,
              textAlign: "center",
              alignSelf: "center"
            }}
          >
            Selecionar todos os contatos
          </Typography>
        </div>

        <Paper style={{ margin: 20, marginLeft: 50, marginRight: 50 }}>
          <ContactTable
            history={history}
            onSubmit={values => {
              associationUsersGroup(values, history);
            }}
          />
        </Paper>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitForm: submit,
      associationUsersGroup,
      setTotalContactsSelected,
      change,
      setGroup,
      setCustumer,
      showCancel
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    contacts: state.ListContactsGroups.contacts,
    totalContactsSelected: state.ListContactsGroups.totalContactsSelected,
    varShowCancel: state.ListContactsGroups.showCancel,
    page: state.ListContactsGroups.page,
    rowsPerPage: state.ListContactsGroups.rowsPerPage,
    state: state
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactList);
