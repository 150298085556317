import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import ListGroup from "./listGroup";
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  reduxForm,
  Field,
  change,
  formValueSelector,
  FieldArray,
} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';

import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MaterialTextFieldRedux from '../../material/Inputs/textField';
import CoverageAreas from '../form/coverageAreas';
import MaterialSwitchRedux from '../../material/Inputs/switch';
import MaterialArraySwitchRedux from '../../material/Inputs/ArraySwitch';
import MaterialInputChipRedux from '../../material/Inputs/chip';
import MaterialInputSelectRedux from '../../material/Inputs/select';
import MaterialInputTextRedux from '../../material/Inputs/text';

import {
  editGroup,
  changePage,
  changeDaysWeek,
  getGroup,
  changeZoom,
  removeCoverage,
  addCoverage,
} from '../../../store/ducks/group/edit';

import CustomList from '../form/coverageAreas/CustomList/index';
import PlaceAutoComplete from '../form/coverageAreas/PlaceAutoComplete/index';

class GroupEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allDaysWeek: false,
      search_input_coverage_area: '',
      tabIndex: 1,
    };
  }

  async componentDidMount() {
    await this.props.getGroup(this.props.match.params.groupId);
  }

  removeCoverageChip = item => {
    const data = this.props.coverageAreasSelected;
    const result = data.filter(obj => obj.id !== item.id);
    this.props.removeCoverage(result);
    this.props.change(item.name);
  };

  changeCoverage = item => event => {
    /* let data = this.props.coverageAreasSelected;
    if (event.target.checked) {
      let area = item.area
        .replace("POLYGON ((", "")
        .replace("))", "")
        .split(",")
        .map(item => {
          const [teste, lng, lat] = item.split(" ");

          return { lat: parseFloat(lat), lng: parseFloat(lng) };
        });
      data.push(item);

      this.props.changeZoom(area[0]);
      this.props.addCoverage(data);
    } else {
      const result = data.filter(obj => obj.id !== item.id);
      this.props.removeCoverage(result);
    }
    this.setState({ teste: 2 }); */
  };

  changeInputSearch = () => event => {
    this.setState({ search_input_coverage_area: event.target.value });
  };

  componentDidUpdate = async oldProps => {
    if (oldProps.receivesPumais !== this.props.receivesPumais) {
      if (this.props.receivesPumais.value) {
        this.props.changePage(1);
        this.props.addCoverage(this.props.initialValues.coverageAreas);
        if (!oldProps.receivesPumais) {
          this.props.changeDaysWeek(this.props.initialValues.days);
        }
      } else {
        this.props.changePage(0);
        this.props.changeReduxForm('GROUP_EDIT_FORM', `schedules[0]`, {
          value: !this.props.schedulesCustom.value,
          label: '24h',
        });
      }
    }

    let inputPayload = null;
    if (
      oldProps.schedulesCustom &&
      oldProps.schedulesCustom.value !== this.props.schedulesCustom.value
    ) {
      inputPayload = {
        name: 'schedules[0]',
        payload: {
          value: !this.props.schedulesCustom.value,
          label: '24h',
        },
      };
    }

    if (
      oldProps.schedulesCustom &&
      oldProps.schedulesFull.value !== this.props.schedulesFull.value
    ) {
      inputPayload = {
        name: 'schedules[1]',
        payload: {
          value: !this.props.schedulesFull.value,
          label: 'Personalizado',
        },
      };
    }
    if (inputPayload) {
      this.props.changeReduxForm(
        'GROUP_EDIT_FORM',
        inputPayload.name,
        inputPayload.payload
      );
      inputPayload = null;
    }
  };

  submitFormEdit = values => {
    this.props.editGroup(this.props.match.params.groupId, values);
  };

  checkSeleted(id) {
    const { coverageAreasSelected } = this.props;
    let check = [];
    if ((coverageAreasSelected || false) !== false) {
      check = coverageAreasSelected.filter(item => item.id === id);
    }
    if (check.length > 0) return true;
    return false;
  }

  changeDaysWeek(value, checked) {
    let { daysWeek, changeDaysWeek } = this.props;
    if (!daysWeek.includes(value) && checked) {
      daysWeek.push(value);
      return changeDaysWeek(daysWeek);
    }

    if (daysWeek.includes(value) && !checked) {
      daysWeek = daysWeek.filter(function(item) {
        return item !== value;
      });
      return changeDaysWeek(daysWeek);
    }
  }

  changePage(value, id = null) {
    if (id === 1) {
      return this.props.changePage(value);
    }
  }

  changeDaysAllWeek(value) {
    const { changeDaysWeek } = this.props;

    this.setState({ allDaysWeek: !value });
    if (!this.state.allDaysWeek) {
      changeDaysWeek([1, 2, 3, 4, 5, 6, 7]);
    } else changeDaysWeek([]);
  }

  renderCoverageAreas() {
    const { coverageAreasSelected, submitFailed, page } = this.props;
    return (
      <>
        <Grid item xs={4} sm={4}>
          <Tabs
            value={this.state.tabIndex}
            textColor="primary"
            onChange={(event, newValue) =>
              this.setState({ tabIndex: newValue })
            }
            aria-label="tabAreas">
            <Tab label="Áreas Personalizadas" value={1} />
            <Tab label="Áreas Pré definidas" value={2} />
          </Tabs>
          {coverageAreasSelected.length === 0 && submitFailed && page === 2 && (
            <FormHelperText
              id="component-error-text"
              style={{
                color: '#840000',
                textAlign: 'center',
                paddingBottom: 20,
              }}>
              Pelo menos uma área de abrangência é obrigatório
            </FormHelperText>
          )}
          <div
            style={
              this.state.tabIndex === 1
                ? { marginTop: 20 }
                : { marginTop: 20, display: 'none' }
            }>
            <CustomList
              change={name =>
                setTimeout(() => {
                  this.setState({ name: Math.random() });
                }, 200)
              }
            />
          </div>

          <div
            style={
              this.state.tabIndex === 2
                ? { marginTop: 20 }
                : { marginTop: 20, display: 'none' }
            }>
            <PlaceAutoComplete
              change={name =>
                setTimeout(() => {
                  this.setState({ name: Math.random() });
                }, 500)
              }
            />
          </div>
        </Grid>

        <Grid item xs={8} sm={8}>
          <div>
            <div style={{ flexDirection: 'row', margin: 20 }}>
              {coverageAreasSelected.length > 0 && (
                <Typography
                  style={{
                    color: '#999999',
                    fontSize: '13px',
                    fontWeight: 'normal',
                    paddingBottom: 10,
                    opacity: '1',
                  }}
                  variant="h4"
                  component="h3">
                  Áreas selecionadas:
                </Typography>
              )}
              {coverageAreasSelected.map(item => (
                <Chip
                  key={item.id}
                  variant="outlined"
                  label={item.name}
                  color="primary"
                  style={{ marginRight: 5, marginLeft: 5, marginBottom: 5 }}
                  onDelete={() => this.removeCoverageChip(item)}
                />
              ))}
            </div>
            <div style={{ height: 500, padding: 0 }}>
              <CoverageAreas
                areas={coverageAreasSelected}
                zoomArea={this.props.zoomArea}
              />
            </div>
          </div>
        </Grid>
      </>
    );
  }

  renderForm() {
    const {
      submitFailed,
      page,
      traffictInput,
      schedulesCustom,
      daysWeek,
    } = this.props;

    let daysWeekRender = null;

    if (this.props.initialValues) {
      daysWeekRender = [
        { id: 1, name: 'SEG' },
        { id: 2, name: 'TER' },
        { id: 3, name: 'QUA' },
        { id: 4, name: 'QUI' },
        { id: 5, name: 'SEX' },
        { id: 6, name: 'SAB' },
        { id: 7, name: 'DOM' },
      ].map(item => (
        <MaterialInputChipRedux
          key={item.name + item.id}
          daysWeekDefault={daysWeek}
          onClick={(value, checked) => this.changeDaysWeek(value, checked)}
          props={{
            disabled: page === 0,
          }}
          label={item.name}
          value={item.id}
        />
      ));
    }

    return (
      <div
        style={
          this.props.page !== 2
            ? { margin: 50 /* , marginRight: 350 */ }
            : { margin: 25 }
        }>
        <Grid container>
          {page !== 2 && (
            <Grid
              item
              xs={4}
              sm={4}
              style={{
                borderColor: '#CCCCCC',
              }}>
              <Field
                name="name"
                type="text"
                inputLabel="Nome do grupo"
                placeholder="Digite o nome do grupo"
                component={MaterialInputTextRedux}
                id="name"
                autoFocus
                disabled={
                  !!(
                    this.props.initialValues && this.props.initialValues.custom
                  )
                }
              />

              <Field
                name="status_active"
                inputLabel="Status"
                placeholder="Digite a operadora do contato"
                data={[
                  {
                    id: 1,
                    name: 'Ativado',
                  },
                  {
                    id: 2,
                    name: 'Desativado',
                  },
                ]}
                component={MaterialInputSelectRedux}
                id="status_active"
              />
              <Divider />
              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                  opacity: '1',
                }}
                variant="h4"
                component="h3">
                Tipo de notificação
              </Typography>

              <Field
                name="notification_types[0].value"
                inputLabel="SMS"
                component={MaterialSwitchRedux}
                disabled={
                  !this.props.current_user.verifyCustomerCanSendMessage
                    .sms_notification
                }
              />
              <Field
                name="notification_types[1].value"
                inputLabel="Notificações via App"
                component={MaterialSwitchRedux}
                disabled={
                  !this.props.current_user.verifyCustomerCanSendMessage
                    .push_notification
                }
              />

              <Divider />

              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                }}
                variant="h4"
                component="h3">
                Mensagens
              </Typography>
              <FieldArray
                name="receives"
                component={MaterialArraySwitchRedux}
              />
            </Grid>
          )}

          {page !== 2 && (
            <Grid
              item
              xs={4}
              sm={4}
              style={
                page
                  ? {
                      opacity: '1',
                      borderLeft: '1px solid',
                      borderRight: '1px solid',
                      borderColor: '#CCCCCC',
                    }
                  : {
                      opacity: '0.5',
                      borderLeft: '1px solid',
                      borderRight: '1px solid',
                      borderColor: '#CCCCCC',
                    }
              }>
              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                }}
                variant="h4"
                component="h3">
                Idiomas
              </Typography>
              <FieldArray
                name="languages"
                component={MaterialArraySwitchRedux}
                props={{
                  disabled: page === 0,
                }}
              />

              <Divider />
              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                }}
                variant="h4"
                component="h3">
                Categoria de alerta
              </Typography>
              <FieldArray
                name="category_incidents"
                component={MaterialArraySwitchRedux}
                props={{
                  disabled: page === 0,
                }}
              />
              {traffictInput && traffictInput.value && (
                <Field
                  name="critical_to_traffic"
                  inputLabel="Criticidade em relação ao trânsito"
                  placeholder="Digite a operadora do contato"
                  props={{
                    disabled: page === 0,
                  }}
                  data={[
                    {
                      id: 1,
                      name: 'Baixa, Média e Alta',
                    },
                    {
                      id: 2,
                      name: 'Média e Alta',
                    },
                    {
                      id: 3,
                      name: 'Alta',
                    },
                  ]}
                  component={MaterialInputSelectRedux}
                  id="critical_to_traffic"
                />
              )}
              <Divider />
            </Grid>
          )}
          {page !== 2 && (
            <Grid
              item
              xs={4}
              sm={4}
              style={
                page
                  ? {
                      borderColor: '#CCCCCC',
                    }
                  : {
                      borderColor: '#CCCCCC',
                      opacity: '0.5',
                    }
              }>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 20,
                  marginBottom: 20,
                }}>
                <Typography
                  style={{
                    color: '#999999',
                    fontSize: '13px',
                    fontWeight: 'normal',
                    paddingTop: 10,
                  }}
                  variant="h4"
                  component="h3">
                  Dias da semana
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.allDaysWeek}
                      disabled={page === 0}
                      onChange={() =>
                        this.changeDaysAllWeek(this.state.allDaysWeek)
                      }
                      color="primary"
                    />
                  }
                  labelPlacement="start"
                  label="Todos"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 20,
                  marginBottom: 20,
                }}>
                {daysWeekRender || (
                  <CircularProgress color="primary" size={50} />
                )}
              </div>
              {this.props.daysWeek.length === 0 && submitFailed && page > 0 && (
                <FormHelperText
                  id="component-error-text"
                  style={{
                    color: '#840000',
                    textAlign: 'center',
                    paddingBottom: 20,
                  }}>
                  Pelo menos um dia da semana é obrigatório
                </FormHelperText>
              )}
              <Divider />
              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                }}
                variant="h4"
                component="h3">
                Horário
              </Typography>
              <FieldArray
                name="schedules"
                component={MaterialArraySwitchRedux}
                props={{
                  disabled: page === 0,
                }}
              />
              {page !== 0 && schedulesCustom && schedulesCustom.value && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 20,
                    marginBottom: 20,
                  }}>
                  <Typography
                    style={{
                      color: '#333333',
                      fontSize: '13px',
                      fontWeight: 'normal',
                      paddingTop: 25,
                    }}
                    variant="h4"
                    component="h3">
                    Horário de início
                  </Typography>

                  <Field
                    name="schedule_start"
                    type="time"
                    component={MaterialTextFieldRedux}
                    id="schedule_start"
                    autoFocus
                  />

                  <Typography
                    style={{
                      color: '#333333',
                      fontSize: '13px',
                      fontWeight: 'normal',
                      paddingTop: 25,
                    }}
                    variant="h4"
                    component="h3">
                    Horário de fim
                  </Typography>

                  <Field
                    name="schedule_end"
                    type="time"
                    component={MaterialTextFieldRedux}
                    id="schedule_end"
                    autoFocus
                  />
                </div>
              )}
              <Divider />
              <div style={{ display: 'block' }}>
                <Typography
                  style={{
                    color: '#999999',
                    fontSize: '13px',
                    fontWeight: 'normal',
                    paddingTop: 10,
                  }}
                  variant="h4"
                  component="h3">
                  Abrangência
                </Typography>
                {this.props.coverageAreasSelected.length === 0 &&
                  this.props.submitFailed &&
                  this.props.page > 0 && (
                    <FormHelperText
                      id="component-error-text"
                      style={{
                        color: '#840000',
                        textAlign: 'center',
                        paddingBottom: 20,
                      }}>
                      Pelo menos uma área de abrangência é obrigatório
                    </FormHelperText>
                  )}
                <div
                  style={{
                    marginTop: 50,
                    marginBottom: 50,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    display: 'flex',
                  }}>
                  <Link
                    onClick={() =>
                      this.props.page === 1 ? this.props.changePage(2) : ''
                    }
                    style={{ cursor: 'pointer' }}>
                    <Typography
                      style={{
                        color: '#333333',
                        fontSize: '13px',
                        fontWeight: 'normal',
                      }}
                      variant="h4"
                      component="h3">
                      Selecione as áreas de abrangência
                    </Typography>
                  </Link>
                  <Link
                    onClick={() =>
                      this.props.page === 1 ? this.props.changePage(2) : ''
                    }
                    style={{ cursor: 'pointer' }}>
                    {`${this.props.coverageAreasSelected.length} `}
                    selecionados
                  </Link>
                </div>

                <Divider />
              </div>
            </Grid>
          )}
          {page === 2 && this.renderCoverageAreas()}
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
        <form onSubmit={this.props.handleSubmit(this.submitFormEdit)}>
          <Paper style={{ margin: 50 }}>
            {this.renderForm()}
            <Divider variant="fullWidth" />
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                margin: 20,
              }}>
              <div
                style={{ flexDirection: 'row', margin: 40, display: 'flex' }}>
                <div
                  style={
                    this.props.page === 0
                      ? {
                          background: '#26408B',
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                      : {
                          background: '#fff',
                          border: '1px #26408B solid',
                          width: 18,
                          height: 18,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                  }
                />

                <div
                  style={
                    this.props.page === 1
                      ? {
                          background: '#26408B',
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                      : {
                          background: '#fff',
                          border: '1px #26408B solid',
                          width: 18,
                          height: 18,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                  }
                />

                <div
                  style={
                    this.props.page === 2
                      ? {
                          background: '#26408B',
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                      : {
                          background: '#fff',
                          border: '1px #26408B solid',
                          width: 18,
                          height: 18,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                  }
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                }}>
                {this.props.page === 2 ? (
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    aria-label="back"
                    onClick={() => this.props.changePage(this.props.page - 1)}
                    style={{
                      width: 120,
                      fontSize: 15,
                      backgroundColor: 'transparent',
                      color: '#26408B',
                    }}>
                    Voltar
                  </Fab>
                ) : (
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    aria-label="Close"
                    onClick={() =>
                      (window.location.href = `${process.env.PUBLIC_URL}/grupos`)
                    }
                    style={{
                      width: 120,
                      fontSize: 15,
                      backgroundColor: 'transparent',
                      color: '#26408B',
                    }}>
                    Cancelar
                  </Fab>
                )}

                <Fab
                  variant="extended"
                  size="small"
                  type="submit"
                  color="primary"
                  aria-label="Save"
                  disabled={this.props.submitting}
                  style={{
                    marginLeft: 30,
                    marginRight: 80,
                    marginTop: 50,
                    marginBottom: 50,
                    width: 120,
                    fontSize: 15,
                  }}>
                  Salvar
                </Fab>
              </div>
            </Grid>
          </Paper>
        </form>
        {/* <ListGroup /> */}
      </div>
    );
  }
}

const selector = formValueSelector('GROUP_EDIT_FORM');

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editGroup,
      changePage,
      changeDaysWeek,
      changeReduxForm: change,
      getGroup,
      changeZoom,
      removeCoverage,
      addCoverage,
    },
    dispatch
  );
const mapStateToProps = state => {
  if (state.GroupEdit.data) {
    const initialValues = {
      custom: state.GroupEdit.data.custom,
      coverageAreas: state.GroupEdit.data.coverageAreas,
      receives: [
        { value: state.GroupEdit.data.receives[0], label: 'Alerta - UMAIS' },
        { value: state.GroupEdit.data.receives[1], label: 'Comunicado' },
        { value: state.GroupEdit.data.receives[3], label: 'Mass Notification' },
        { value: state.GroupEdit.data.receives[2], label: 'Check in' },
      ],
      languages: [
        { value: state.GroupEdit.data.languages[1], label: 'Português' },
        { value: state.GroupEdit.data.languages[2], label: 'Inglês' },
      ],
      status_active: state.GroupEdit.data.status_active,

      notification_types: [
        {
          value: !!(
            state.Main.current_user.verifyCustomerCanSendMessage
              .sms_notification && state.GroupEdit.data.notification_types[1]
          ),
        },
        {
          value: !!(
            state.Main.current_user.verifyCustomerCanSendMessage
              .push_notification && state.GroupEdit.data.notification_types[2]
          ),
        },
      ],
      days: state.GroupEdit.data.days,
      name: state.GroupEdit.data.name,

      category_incidents: [
        {
          value: state.GroupEdit.data.category_incidents[1],
          label: 'Segurança',
        },
        {
          value: state.GroupEdit.data.category_incidents[2],
          label: 'Trânsito',
        },
        {
          value: state.GroupEdit.data.category_incidents[3],
          label: 'Contingência',
        },
      ],
      critical_to_traffic: state.GroupEdit.data.critical_to_traffic,
      schedule_end: state.GroupEdit.data.schedule_end,
      schedules: [
        { value: state.GroupEdit.data.schedules[1], label: '24h' },
        { value: state.GroupEdit.data.schedules[2], label: 'Personalizado' },
      ],
      schedule_start: state.GroupEdit.data.schedule_start,
    };
    return {
      page: state.GroupEdit.page,
      custom: state.GroupEdit.custom,
      daysWeek: state.GroupEdit.daysWeek,
      traffictInput: selector(state, 'category_incidents[1]'),
      schedulesCustom: selector(state, 'schedules[1]'),
      schedulesFull: selector(state, 'schedules[0]'),
      receivesPumais: selector(state, 'receives[0]'),
      initialValues,
      loadingCoverageArea: state.CoverageArea.loading,
      coverageAreas: state.CoverageArea.data,
      coverageAreasSelected: state.GroupEdit.coverageAreas,
      zoomArea: state.GroupEdit.mapZoom,
      current_user: state.Main.current_user,
    };
  }
  return {
    page: state.GroupEdit.page,
    custom: state.GroupEdit.custom,
    daysWeek: state.GroupEdit.daysWeek,
    traffictInput: selector(state, 'category_incidents[1]'),
    schedulesCustom: selector(state, 'schedules[1]'),
    schedulesFull: selector(state, 'schedules[0]'),
    receivesPumais: selector(state, 'receives[0]'),
    loadingCoverageArea: state.CoverageArea.loading,
    coverageAreas: state.CoverageArea.data,
    coverageAreasSelected: state.GroupEdit.coverageAreas,
    zoomArea: state.GroupEdit.mapZoom,
    current_user: state.Main.current_user,
    // initialValues: initialValues
  };
};

GroupEditForm = reduxForm({
  form: 'GROUP_EDIT_FORM',
  enableReinitialize: true,
  // initialValues: this.props.data
})(GroupEditForm);

// Decorator para Redux Form
export default connect(mapStateToProps, mapDispatchToProps)(GroupEditForm);
