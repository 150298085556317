// types
export const Types = {
  REQUEST_LOGIN: "REQUEST_LOGIN",
  SUCCESS_LOGIN: "SUCCESS_LOGIN",
  FAILURE_LOGIN: "FAILURE_LOGIN"
};

//reducers

const INITIAL_VALUES = {
  data: null,
  loading: false,
  error: false
};

export default function reducer(state = INITIAL_VALUES, action) {
  //console.log(action);
  switch (action.type) {
    case Types.REQUEST_LOGIN:
      return {
        ...state,
        loading: true,
        error: false
      };
    case Types.SUCCESS_LOGIN:
      return {
        loading: false,
        error: false
      };
    case Types.FAILURE_LOGIN:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}

export function login(payload) {
  return { type: Types.REQUEST_LOGIN, payload };
}
