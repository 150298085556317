import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
import Rating from '@material-ui/lab/Rating';
import { Reply } from '@material-ui/icons';

import moment from 'moment';
import { change } from 'redux-form';
import { criticality, category } from '../../Categorys';
import { receiveOptions } from '../ReceiveOptions';

import { setOpenCreate, getMessage } from '~/store/ducks/message/list';

import {
  Container,
  IconsContainer,
  GridStars,
  MessagesNatureIcon,
} from './styles';

function TableCustomRow({ item, setOpen, setOpenCheckin }) {
  const [mouseOver, setMouseOver] = useState(null);
  const [icon, setIcon] = useState('');
  const [messageType, setMessageType] = useState('');

  const dispatch = useDispatch();

  const handleOpen = message => {
    if (message.type_message !== 'CHECKIN') {
      dispatch(getMessage(message.id));
      setOpen(true);
    } else {
      dispatch(getMessage(message.id, true));
      setOpenCheckin(true);
    }
  };

  const onMouseOver = key => {
    setMouseOver(key);
  };

  const onMouseOut = () => {
    setMouseOver(null);
  };

  const handleRender = useCallback(() => {
    if (item.type_message === 'CORPORATE') {
      const categorys = category('Comunicado');

      setIcon(categorys);
      setMessageType(' Comunicado');
    } else if (item.type_message === 'PUMAIS') {
      const categorys = category(
        item.message_incident.category.id,
        item.message_incident.criticality
      );

      setIcon(categorys);
      setMessageType(' Alerta PUMAIS');
    } else if (item.type_message === 'MASS_NOTIFICATION') {
      const categorys = category('Mass Notification');

      setIcon(categorys);
      setMessageType(' Mass Notification');
    } else if (item.type_message === 'CHECKIN') {
      const categorys = category('Checkin');

      setMessageType(' Check-in');
      setIcon(categorys);
    }
    return null;
  }, [item]);

  useEffect(() => {
    handleRender();
  }, [handleRender]);

  return (
    <Container
      hover
      onMouseEnter={() => onMouseOver(item.id)}
      onMouseLeave={() => onMouseOut()}>
      <TableCell component="th" scope="column">
        <Grid container spacing={2}>
          <Grid item xs={4} lg={3} className="grey-font">
            <span className="text-title">Enviada por: </span>
            <span>{item.created_by_contact_name}</span>
          </Grid>

          <Grid item xs={4} lg={3} className="grey-font">
            {item.message_incident ? (
              <IconsContainer>
                <Chip
                  className="criticality"
                  label={criticality(item.message_incident.criticality)}
                />
                <Chip
                  label={item.message_incident.language.id === 1 ? 'PT' : 'EN'}
                  size="small"
                  variant="outlined"
                />
                <div className="receive-options">{receiveOptions(item)}</div>
              </IconsContainer>
            ) : (
              receiveOptions(item)
            )}
          </Grid>
          <Hidden mdDown>
            <Grid item lg={1} />
          </Hidden>

          <GridStars item xs={4}>
            {item.message_incident ? (
              <>
                <Tooltip
                  title="Credibilidade da Informação"
                  aria-label="credibility of information"
                  fontSize="small">
                  <div>
                    <Rating
                      value={item.message_incident.credibility}
                      readOnly
                      size="small"
                    />
                  </div>
                </Tooltip>
              </>
            ) : (
              <></>
            )}
          </GridStars>
          <Hidden lgDown>
            <Grid item lg={1} />
          </Hidden>
        </Grid>
        <MessagesNatureIcon>
          <Grid item xs={4} lg={3}>
            {icon}
          </Grid>
          <Grid item xs={6} lg={8}>
            {item.description.length > 100
              ? `${item.description.substring(0, 100)}...`
              : item.description}
          </Grid>
          <Grid item xs={2} lg={1} />
        </MessagesNatureIcon>
      </TableCell>
      <TableCell component="th" scope="column" size="small">
        <Grid container direction="row">
          <Grid item xs={12} className="grey-font">
            <span className="text-title">Tipo:</span>
            {messageType}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell component="th" scope="column" size="small">
        <Grid container direction="row">
          <Grid item xs={12} className="grey-font">
            <Chip
              label="Encaminhar mensagem"
              color="primary"
              icon={<Reply />}
              clickable
              onClick={() => {
                setTimeout(() => {
                  const { description, sms_notification, push_notification } = item;

                  dispatch(change('MESSAGE_CREATE_FORM', `description`, description));
                  dispatch(change('MESSAGE_CREATE_FORM', `sms_notification`, sms_notification));
                  dispatch(change('MESSAGE_CREATE_FORM', `push_notification`, push_notification));
                }, 400);

                dispatch(setOpenCreate(true));
              }}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell component="th" scope="column" size="small">
        <Grid container direction="column">
          <Grid item xs={12} className="grey-font">
            {mouseOver === item.id ? (
              <Chip
                label="Mais Detalhes"
                color="primary"
                clickable
                onClick={() => handleOpen(item)}
              />
            ) : (
              <Chip
                label={moment(item.createdAt).format('DD/MM/YYYY - HH:mm')}
                variant="outlined"
                style={{
                  backgroundColor: '#fff',
                }}
              />
            )}
          </Grid>
        </Grid>
      </TableCell>
    </Container>
  );
}

TableCustomRow.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setOpen: PropTypes.func.isRequired,
  setOpenCheckin: PropTypes.func.isRequired,
};

export default TableCustomRow;
