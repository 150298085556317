import {
  all,
  takeLatest,
  put,
  call,
  fork,
  take,
  delay
} from "redux-saga/effects";

import moment from "moment";

import { Types } from "../../ducks/main/index";
import axios from "../../../services/api.js";
import { startConfiguration } from "pusher-redux";

import * as ssoService from "../../../services/sso";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function clearAuth() {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("@sso_integration");
  localStorage.removeItem("@sso_expires_at");
}

function apiGet() {
  return axios.get(`/auth`);
}

function* checkToken() {
  try {
    const userToken = localStorage.getItem("token");

    if (userToken) {
      const { data } = yield call(apiGet);

      startConfiguration({
        // pass options
        auth: {
          headers: { Authorization: `Bearer ${userToken}` }
        }
      });

      yield put({ type: Types.SUCCESS_MAIN, payload: data });
    } else {
      yield put({ type: Types.FAILURE_MAIN });
    }
  } catch (error) {
    yield put({ type: Types.FAILURE_MAIN });
  }
}

function* ssoAuthenticate({ payload }) {
  yield call(ssoService.setSSOIntegration, payload.sso);

  try {
    const { data } = yield call(ssoService.ssoSession, payload.access_token);

    localStorage.setItem("token", data.access_token);
    localStorage.setItem("refreshToken", data.refresh_token);

    yield call(ssoService.setSSOExpiresAt, data.expires_at);

    yield all([put({ type: Types.SUCCESS_MAIN, payload: data.user })]);

    window.location.replace(process.env.PUBLIC_URL + "/");
  } catch (err) {
    //TODO: Abrir navegador e refazer login pelo SSO
    clearAuth();
  }
}

function* refreshSSOAuth() {

  let payload = (yield take(Types.SUCCESS_MAIN)).payload;

  while (!payload) {        
    payload = (yield take(Types.SUCCESS_MAIN)).payload;    
  }

  const sso = yield call(ssoService.getSSOIntegration);

  if (sso) {
    while (true) {
      const expiresAt = yield call(ssoService.getSSOExpiresAt);
      const refreshToken = localStorage.getItem("refreshToken");

      if (expiresAt) {
        
        const diffToExpires = moment(expiresAt).diff(moment(), "minutes");

        if (diffToExpires <= 5) {
          try {
            const { data } = yield call(ssoService.ssoRefresh, {
              sso,
              refresh_token: refreshToken
            });

            localStorage.setItem("token", data.access_token);
            localStorage.setItem("refreshToken", data.refresh_token);

            yield call(ssoService.setSSOExpiresAt, data.expires_at);

            yield put({ type: Types.SUCCESS_MAIN, payload: data.user });
          } catch (err) {
            window.location.href = `${
              process.env.REACT_APP_SSO_URL
            }/login?sso=${sso}&redirect=${
              process.env.REACT_APP_URL_APP
            }/login/sso`;
            take("FOREVER");
          }
        }
      }

      yield delay(60000);
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(Types.REQUEST_MAIN, checkToken),
    takeLatest(Types.ASYNC_SSO_AUTHENTICATE, ssoAuthenticate),
    fork(refreshSSOAuth)
  ]);
}
