import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import ListGroup from "./listGroup";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import qs from 'qs';

import {
  Field,
  FieldArray,
  reduxForm,
  change,
  formValueSelector,
} from 'redux-form';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import Fab from '@material-ui/core/Fab';
import MaterialTextFieldRedux from '../../material/Inputs/textField';
import CustomList from './coverageAreas/CustomList/index';
import MaterialSwitchRedux from '../../material/Inputs/switch';
import MaterialArraySwitchRedux from '../../material/Inputs/ArraySwitch';
import MaterialInputChipRedux from '../../material/Inputs/chip';
import MaterialInputSelectRedux from '../../material/Inputs/select';
import MaterialInputTextRedux from '../../material/Inputs/text';
import CoverageAreas from './coverageAreas';
import PlaceAutoComplete from './coverageAreas/PlaceAutoComplete/index';

import {
  createGroup,
  changePage,
  changeDaysWeek,
  removeCoverage,
} from '../../../store/ducks/group/create';

class GroupForm extends Component {
  constructor(props) {
    super(props);
    const contact_name = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).contact_name
      ? qs.parse(props.location.search, {
          ignoreQueryPrefix: true,
        }).contact_name
      : null;
    const contact_id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).contact_id
      ? qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
          .contact_id
      : null;
    if (contact_id && contact_name)
      props.changeReduxForm(
        'GROUP_FORM',
        'name',
        `Personalizado - ${contact_name}`
      );
    if (contact_id && contact_name)
      props.changeReduxForm('GROUP_FORM', 'receives.[0]', {
        value: true,
        label: 'Alerta - PUMAIS',
      });

    this.state = {
      allDaysWeek: false,
      search_input_coverage_area: '',
      tabIndex: 1,
      contact_name,
      contact_id,
    };
  }

  removeCoverageChip = item => {
    const data = this.props.coverageAreasSelected;
    const result = data.filter(obj => obj.id !== item.id);
    this.props.removeCoverage(result);
    this.props.change(item.name);
  };

  componentWillMount() {
    if (this.props.current_user) {
      this.props.change(
        'sms_notification',
        this.props.current_user.verifyCustomerCanSendMessage.sms_notification
      );
      this.props.change(
        'push_notification',
        this.props.current_user.verifyCustomerCanSendMessage.push_notification
      );
    }
  }

  componentDidUpdate = async oldProps => {
    if (oldProps.receivesPumais !== this.props.receivesPumais) {
      if (this.props.receivesPumais.value) {
        this.props.changePage(1);
      } else {
        this.props.changePage(0);
      }
    }
    let inputPayload = null;
    if (
      oldProps.schedulesCustom &&
      oldProps.schedulesCustom.value !== this.props.schedulesCustom.value
    ) {
      inputPayload = {
        name: 'schedules[0]',
        payload: {
          value: !this.props.schedulesCustom.value,
          label: '24h',
        },
      };
    }

    if (
      oldProps.schedulesCustom &&
      oldProps.schedulesFull.value !== this.props.schedulesFull.value
    ) {
      inputPayload = {
        name: 'schedules[1]',
        payload: {
          value: !this.props.schedulesFull.value,
          label: 'Personalizado',
        },
      };
    }
    if (inputPayload) {
      this.props.changeReduxForm(
        'GROUP_FORM',
        inputPayload.name,
        inputPayload.payload
      );
      inputPayload = null;
    }
  };

  changeDaysWeek(value, checked) {
    let { daysWeek, changeDaysWeek } = this.props;
    if (!daysWeek.includes(value) && checked) {
      daysWeek.push(value);
      return changeDaysWeek(daysWeek);
    }

    if (daysWeek.includes(value) && !checked) {
      daysWeek = daysWeek.filter(function(item) {
        return item !== value;
      });

      return changeDaysWeek(daysWeek);
    }

    // this.props.changeDaysWeek();
    // console.log(value, checked, "teste");
  }

  changeDaysAllWeek(value) {
    const { changeDaysWeek } = this.props;
    this.setState({ allDaysWeek: !value });
    if (!this.state.allDaysWeek) {
      changeDaysWeek([1, 2, 3, 4, 5, 6, 7]);
    } else changeDaysWeek([]);
  }

  // async componentWillMount() {}
  changePage(value, id = null) {
    if (id === 1) {
      return this.props.changePage(value);
    }
  }

  renderForm() {
    const {
      submitFailed,
      page,
      daysWeek,
      traffictInput,
      schedulesCustom,
      coverageAreasSelected,
    } = this.props;

    const daysWeekRender = [
      { id: 1, name: 'SEG' },
      { id: 2, name: 'TER' },
      { id: 3, name: 'QUA' },
      { id: 4, name: 'QUI' },
      { id: 5, name: 'SEX' },
      { id: 6, name: 'SAB' },
      { id: 7, name: 'DOM' },
    ].map(item => (
      <MaterialInputChipRedux
        key={item.name + item.id}
        daysWeekDefault={daysWeek}
        onClick={(value, checked) => this.changeDaysWeek(value, checked)}
        props={{
          disabled: page === 0,
        }}
        label={item.name}
        value={item.id}
      />
    ));

    return (
      <div
        style={
          this.props.page !== 2
            ? { margin: 50 /* , marginRight: 350 */ }
            : { margin: 25 }
        }>
        <Grid container>
          {page !== 2 && (
            <Grid
              item
              xs={4}
              sm={4}
              style={{
                borderColor: '#CCCCCC',
              }}>
              <Field
                name="name"
                type="text"
                inputLabel="Nome do grupo"
                placeholder="Digite o nome do grupo"
                // validate={[required]}
                disabled={!!(this.state.contact_name && this.state.contact_id)}
                component={MaterialInputTextRedux}
                id="name"
                autoFocus
              />

              <Field
                name="status_active"
                inputLabel="Status"
                placeholder="Digite a operadora do contato"
                data={[
                  {
                    id: 1,
                    name: 'Ativado',
                  },
                  {
                    id: 2,
                    name: 'Desativado',
                  },
                ]}
                component={MaterialInputSelectRedux}
                id="status_active"
              />
              <Divider />
              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                  opacity: '1',
                }}
                variant="h4"
                component="h3">
                Tipo de notificação
              </Typography>
              {/* <FieldArray
                name="notification_types"
                component={MaterialArraySwitchRedux}
              /> */}

              <Field
                name="notification_types[0].value"
                inputLabel="SMS"
                component={MaterialSwitchRedux}
                disabled={
                  !this.props.current_user.verifyCustomerCanSendMessage
                    .sms_notification
                }
              />
              <Field
                name="notification_types[1].value"
                inputLabel="Notificações via App"
                component={MaterialSwitchRedux}
                disabled={
                  !this.props.current_user.verifyCustomerCanSendMessage
                    .push_notification
                }
              />

              <Divider />

              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                }}
                variant="h4"
                component="h3">
                Mensagens
              </Typography>

              <FieldArray
                name="receives"
                component={MaterialArraySwitchRedux}
              />
              <Divider />
            </Grid>
          )}

          {page !== 2 && (
            <Grid
              item
              xs={4}
              sm={4}
              style={
                page
                  ? {
                      opacity: '1',
                      borderLeft: '1px solid',
                      borderRight: '1px solid',
                      borderColor: '#CCCCCC',
                    }
                  : {
                      opacity: '0.5',
                      borderLeft: '1px solid',
                      borderRight: '1px solid',
                      borderColor: '#CCCCCC',
                    }
              }>
              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                }}
                variant="h4"
                component="h3">
                Idiomas
              </Typography>
              {/* languages */}
              <FieldArray
                name="languages"
                component={MaterialArraySwitchRedux}
                props={{
                  disabled: page === 0,
                }}
              />

              <Divider />
              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                }}
                variant="h4"
                component="h3">
                Categoria de alerta
              </Typography>
              <FieldArray
                name="category_incidents"
                component={MaterialArraySwitchRedux}
                props={{
                  disabled: page === 0,
                }}
              />
              {traffictInput && traffictInput.value && (
                <Field
                  name="critical_to_traffic"
                  inputLabel="Criticidade em relação ao trânsito"
                  placeholder="Digite a operadora do contato"
                  props={{
                    disabled: page === 0,
                  }}
                  data={[
                    {
                      id: 1,
                      name: 'Baixa, Média e Alta',
                    },
                    {
                      id: 2,
                      name: 'Média e Alta',
                    },
                    {
                      id: 3,
                      name: 'Alta',
                    },
                  ]}
                  component={MaterialInputSelectRedux}
                  id="critical_to_traffic"
                />
              )}
              <Divider />
            </Grid>
          )}
          {page !== 2 && (
            <Grid
              item
              xs={4}
              sm={4}
              style={
                page
                  ? {
                      borderColor: '#CCCCCC',
                    }
                  : {
                      borderColor: '#CCCCCC',
                      opacity: '0.5',
                    }
              }>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 20,
                  marginBottom: 20,
                }}>
                <Typography
                  style={{
                    color: '#999999',
                    fontSize: '13px',
                    fontWeight: 'normal',
                    paddingTop: 10,
                  }}
                  variant="h4"
                  component="h3">
                  Dias da semana
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.allDaysWeek}
                      onChange={() =>
                        this.changeDaysAllWeek(this.state.allDaysWeek)
                      }
                      color="primary"
                    />
                  }
                  labelPlacement="start"
                  label="Todos"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 20,
                  marginBottom: 20,
                }}>
                {daysWeekRender}
              </div>
              {this.props.daysWeek.length === 0 && submitFailed && page > 0 && (
                <FormHelperText
                  id="component-error-text"
                  style={{
                    color: '#840000',
                    textAlign: 'center',
                    paddingBottom: 20,
                  }}>
                  Pelo menos um dia da semana é obrigatório
                </FormHelperText>
              )}
              <Divider />
              <Typography
                style={{
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  paddingTop: 10,
                }}
                variant="h4"
                component="h3">
                Horário
              </Typography>
              <FieldArray
                name="schedules"
                component={MaterialArraySwitchRedux}
                props={{
                  disabled: page === 0,
                }}
              />

              {page !== 0 && schedulesCustom && schedulesCustom.value && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 20,
                    marginBottom: 20,
                  }}>
                  <Typography
                    style={{
                      color: '#333333',
                      fontSize: '13px',
                      fontWeight: 'normal',
                      paddingTop: 25,
                    }}
                    variant="h4"
                    component="h3">
                    Horário de início
                  </Typography>

                  <Field
                    name="schedule_start"
                    type="time"
                    component={MaterialTextFieldRedux}
                    id="schedule_start"
                    autoFocus
                  />

                  <Typography
                    style={{
                      color: '#333333',
                      fontSize: '13px',
                      fontWeight: 'normal',
                      paddingTop: 25,
                    }}
                    variant="h4"
                    component="h3">
                    Horário de fim
                  </Typography>

                  <Field
                    name="schedule_end"
                    type="time"
                    component={MaterialTextFieldRedux}
                    id="schedule_end"
                    autoFocus
                  />
                </div>
              )}
              <Divider />
              <div style={{ display: 'block' }}>
                <Typography
                  style={{
                    color: '#999999',
                    fontSize: '13px',
                    fontWeight: 'normal',
                    paddingTop: 10,
                  }}
                  variant="h4"
                  component="h3">
                  Abrangência
                </Typography>
                {coverageAreasSelected.length === 0 &&
                  this.props.submitFailed &&
                  this.props.page > 0 && (
                    <FormHelperText
                      id="component-error-text"
                      style={{
                        color: '#840000',
                        textAlign: 'center',
                        paddingBottom: 20,
                      }}>
                      Pelo menos uma área de abrangência é obrigatório
                    </FormHelperText>
                  )}
                <div
                  style={{
                    marginTop: 50,
                    marginBottom: 50,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    display: 'flex',
                  }}>
                  <Link
                    onClick={() =>
                      this.props.page === 1 ? this.props.changePage(2) : ''
                    }
                    style={{ cursor: 'pointer' }}>
                    <Typography
                      style={{
                        color: '#333333',
                        fontSize: '13px',
                        fontWeight: 'normal',
                      }}
                      variant="h4"
                      component="h3">
                      Selecione as áreas de abrangência
                    </Typography>
                  </Link>
                  <Link
                    onClick={() =>
                      this.props.page === 1 ? this.props.changePage(2) : ''
                    }
                    style={{ cursor: 'pointer' }}>
                    {`${coverageAreasSelected.length} `}
                    selecionados
                  </Link>
                </div>

                <Divider />
              </div>
            </Grid>
          )}
          {page === 2 && this.renderCoverageAreas()}
        </Grid>
      </div>
    );
  }

  renderCoverageAreas() {
    const { coverageAreasSelected, submitFailed, page } = this.props;
    return (
      <>
        <Grid item xs={4} sm={4}>
          <Tabs
            value={this.state.tabIndex}
            textColor="primary"
            onChange={(event, newValue) =>
              this.setState({ tabIndex: newValue })
            }
            aria-label="tabAreas">
            <Tab label="Áreas Personalizadas" value={1} />
            <Tab label="Áreas Pré definidas" value={2} />
          </Tabs>
          {coverageAreasSelected.length === 0 && submitFailed && page === 2 && (
            <FormHelperText
              id="component-error-text"
              style={{
                color: '#840000',
                textAlign: 'center',
                paddingBottom: 20,
              }}>
              Pelo menos uma área de abrangência é obrigatório
            </FormHelperText>
          )}
          <div
            style={
              this.state.tabIndex === 1
                ? { marginTop: 20 }
                : { marginTop: 20, display: 'none' }
            }>
            <CustomList
              action="create"
              change={name =>
                setTimeout(() => {
                  this.setState({ name: Math.random() });
                }, 200)
              }
            />
          </div>

          <div
            style={
              this.state.tabIndex === 2
                ? { marginTop: 20 }
                : { marginTop: 20, display: 'none' }
            }>
            <PlaceAutoComplete
              action="create"
              change={name =>
                setTimeout(() => {
                  this.setState({ name: Math.random() });
                }, 500)
              }
            />
          </div>
        </Grid>

        <Grid item xs={8} sm={8}>
          <div>
            <div style={{ flexDirection: 'row', margin: 20 }}>
              {coverageAreasSelected.length > 0 && (
                <Typography
                  style={{
                    color: '#999999',
                    fontSize: '13px',
                    fontWeight: 'normal',
                    paddingBottom: 10,
                    opacity: '1',
                  }}
                  variant="h4"
                  component="h3">
                  Áreas selecionadas:
                </Typography>
              )}
              {coverageAreasSelected.map(item => (
                <Chip
                  key={item.id}
                  variant="outlined"
                  label={item.name}
                  color="primary"
                  style={{ marginRight: 5, marginLeft: 5, marginBottom: 5 }}
                  onDelete={() => this.removeCoverageChip(item)}
                />
              ))}
            </div>
            <div style={{ height: 500, padding: 0 }}>
              <CoverageAreas
                areas={coverageAreasSelected}
                zoomArea={this.props.zoomArea}
              />
            </div>
          </div>
        </Grid>
      </>
    );
  }

  render() {
    return (
      <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
        <form
          onSubmit={this.props.handleSubmit(values =>
            this.props.createGroup({
              ...values,
              user_custom_id: this.state.contact_id,
            })
          )}>
          <Paper style={{ margin: 50 }}>
            {this.renderForm()}
            <Divider variant="fullWidth" />
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                margin: 20,
              }}>
              <div
                style={{ flexDirection: 'row', margin: 40, display: 'flex' }}>
                <div
                  style={
                    this.props.page === 0
                      ? {
                          background: '#26408B',
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                      : {
                          background: '#fff',
                          border: '1px #26408B solid',
                          width: 18,
                          height: 18,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                  }
                />

                <div
                  style={
                    this.props.page === 1
                      ? {
                          background: '#26408B',
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                      : {
                          background: '#fff',
                          border: '1px #26408B solid',
                          width: 18,
                          height: 18,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                  }
                />

                <div
                  style={
                    this.props.page === 2
                      ? {
                          background: '#26408B',
                          width: 20,
                          height: 20,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                      : {
                          background: '#fff',
                          border: '1px #26408B solid',
                          width: 18,
                          height: 18,
                          borderRadius: 50,
                          marginLeft: 20,
                        }
                  }
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                }}>
                {this.props.page === 2 ? (
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    aria-label="back"
                    onClick={() => this.props.changePage(this.props.page - 1)}
                    style={{
                      width: 120,
                      fontSize: 15,
                      backgroundColor: 'transparent',
                      color: '#26408B',
                    }}>
                    Voltar
                  </Fab>
                ) : (
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    aria-label="Close"
                    onClick={() =>
                      (window.location.href = `${process.env.PUBLIC_URL}/grupos`)
                    }
                    style={{
                      width: 120,
                      fontSize: 15,
                      backgroundColor: 'transparent',
                      color: '#26408B',
                    }}>
                    Cancelar
                  </Fab>
                )}

                <Fab
                  variant="extended"
                  size="small"
                  type="submit"
                  color="primary"
                  aria-label="Save"
                  disabled={this.props.submitting}
                  style={{
                    marginLeft: 30,
                    marginRight: 80,
                    marginTop: 50,
                    marginBottom: 50,
                    width: 120,
                    fontSize: 15,
                  }}>
                  Salvar
                </Fab>
              </div>
            </Grid>
          </Paper>
        </form>
        {/* <ListGroup /> */}
      </div>
    );
  }
}

/* const style = {
  border_none_container: {
    borderColor: "#CCCCCC"
  },
  border_container: {
    borderLeft: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC"
  }
}; */
const selector = formValueSelector('GROUP_FORM');

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createGroup,
      changePage,
      changeDaysWeek,
      changeReduxForm: change,
      removeCoverage,
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    page: state.GroupCreate.page,
    coverageAreasSelected: state.GroupCreate.coverageAreas,
    zoomArea: state.GroupCreate.mapZoom,
    daysWeek: state.GroupCreate.daysWeek,
    traffictInput: selector(state, 'category_incidents[1]'),
    schedulesCustom: selector(state, 'schedules[1]'),
    schedulesFull: selector(state, 'schedules[0]'),
    receivesPumais: selector(state, 'receives.[0]'),
    current_user: state.Main.current_user,
  };
};
GroupForm = reduxForm({
  form: 'GROUP_FORM',
  initialValues: {
    receives: [
      { value: false, label: 'Alerta - UMAIS' },
      { value: false, label: 'Comunicado' },
      { value: false, label: 'Mass Notification' },
      { value: false, label: 'Check in' },
    ],
    languages: [
      { value: false, label: 'Português' },
      { value: false, label: 'Inglês' },
    ],
    status_active: 1,
    notification_types: [
      { value: false, label: 'SMS' },
      { value: false, label: 'Notificação via App ' },
    ],
    category_incidents: [
      { value: false, label: 'Segurança' },
      { value: false, label: 'Trânsito' },
      { value: false, label: 'Contingência' },
    ],
    critical_to_traffic: null,
    schedule_end: '18:30',
    schedules: [
      { value: true, label: '24h' },
      { value: false, label: 'Personalizado' },
    ],
    schedule_start: '07:30',
  },
})(GroupForm);

// Decorator para Redux Form
export default connect(mapStateToProps, mapDispatchToProps)(GroupForm);
