// types
export const Types = {
  REQUEST_MESSAGE_GET_CONTACT: "REQUEST_MESSAGE_GET_CONTACT",
  SUCCESS_MESSAGE_GET_CONTACT: "SUCCESS_MESSAGE_GET_CONTACT",
  FAILURE_MESSAGE_GET_CONTACT: "FAILURE_MESSAGE_GET_CONTACT",
  CHANGE_MESSAGE_CONTACT: "CHANGE_MESSAGE_CONTACT",
  REQUEST_CHANGE_CONTACT: "REQUEST_CHANGE_CONTACT"
};
const INITIAL_VALUES = {
  loading: null,
  error: false,
  data: null,
  contacts: []
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_MESSAGE_GET_CONTACT:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_MESSAGE_GET_CONTACT:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case Types.FAILURE_MESSAGE_GET_CONTACT:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_MESSAGE_CONTACT:
      const contacts = [...new Array([...action.payload])][0];
      return {
        ...state,
        contacts
      };

    default:
      return { ...state };
  }
}

export function getContacts(payload) {
  return { type: Types.REQUEST_MESSAGE_GET_CONTACT, payload };
}

export function changeContactSelecteds(payload) {
  return { type: Types.REQUEST_CHANGE_CONTACT, payload };
}
