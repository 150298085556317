import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { withGoogleMap, GoogleMap } from "react-google-maps";

import styles from './styles.json'

const MAPS_DEFAULT_CENTER = { lat: -22.9746102, lng: -43.5317493 };
const MAPS_DEFAULT_ZOOM = 9;

const MAPS_DEFAULT_OPTIONS = {
  mapTypeControl: false,
  disableDefaultUI: false,
  styles: styles
};
class Map extends Component {
  handleClickOutside = () => {
    this.props.resetMark();
  };
  componentDidUpdate = async oldProps => {
    if (oldProps.zoomArea !== this.props.zoomArea && this.props.zoomArea) {
      const polygons = this.props.zoomArea;

      let bounds = new window.google.maps.LatLngBounds();
      if (polygons.length > 1) {
        //Multipolygon

        polygons.forEach(polygon => {
          window.google.maps.geometry.encoding
            .decodePath(!Array.isArray(polygon[0]) ? polygon[0] : polygon[0][0])
            .forEach(item => {
              bounds.extend(
                new window.google.maps.LatLng(item.lat(), item.lng())
              );
            });
        });
      } else {
        window.google.maps.geometry.encoding
          .decodePath(
            !Array.isArray(polygons[0]) ? polygons[0] : polygons[0][0]
          )
          .forEach(item => {
            bounds.extend(
              new window.google.maps.LatLng(item.lat(), item.lng())
            );
          });
      }

      this.refs.mapRef.fitBounds(bounds);
    }
  };

  render() {
    //let zoomFocus = !this.props.zoom ? MAPS_DEFAULT_ZOOM : this.props.zoom;
    // let center = !this.props.center ? MAPS_DEFAULT_CENTER : this.props.center;

    const outerProps = {}

    if(this.props.zoom) {
      outerProps.zoom = this.props.zoom;
    }

    if(this.props.center) {
      outerProps.center = this.props.center;
    }

    return (    
      <GoogleMap
        ref={"mapRef"}
        showsUserLocation={true}
        //    center={center}
        //    zoom={zoomFocus}
        defaultZoom={MAPS_DEFAULT_ZOOM}
        options={MAPS_DEFAULT_OPTIONS}
        defaultCenter={MAPS_DEFAULT_CENTER}

        {...outerProps}
      >
        {this.props.children}
      </GoogleMap>      
    );
  }
}

Map = compose(
  withProps({
    containerElement: (
      <div
        style={{
          width: "100%",
          height: "100%"
        }}
      />
    ),
    mapElement: <div style={{ width: "100%", height: `100%` }} />
  }),
  withGoogleMap
)(Map);

export default Map;
