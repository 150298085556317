import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

import {
  getCustomers,
  setCustumer,
} from '~/store/ducks/message/create/customers';
import { getGroups } from '~/store/ducks/message/create/groups';
import { setPage } from '~/store/ducks/message/create/index';

import {
  ListChild,
  ListContainer,
  Loading,
  TitleButton,
  SearchContainer,
} from './styles';

function MessageCreateCustomer({ push_notification, sms_notification }) {
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();

  const { data: customers, loading: loadingCustumers } = useSelector(
    state => state.CreateMessageCustomer
  );

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  const changeCustomers = id => {
    const payload = {
      push_notification,
      sms_notification,
      id,
    };
    dispatch(setCustumer(id));
    dispatch(getGroups(payload));
    dispatch(setPage(1));
  };

  const changeInputSearch = () => event => {
    setSearchInput(event.target.value);
  };

  const customersRender = [];
  if ((customers || false) !== false) {
    customers.forEach(item => {
      if (
        searchInput === '' ||
        item.name.toUpperCase().includes(searchInput.toUpperCase())
      ) {
        customersRender.push(
          <ListChild
            button
            onClick={() => changeCustomers(item.id)}
            key={item.id}>
            <ListItemText primary={item.name} />
            {item.groupsSelected && item.groupsSelected > 0 && (
              <Typography color="primary">
                {item.groupsSelected} selecionados
              </Typography>
            )}
          </ListChild>
        );
      }
    });
  }

  const Customers =
    !loadingCustumers && (customers || false) !== false ? (
      <div>
        <TitleButton color="default">Empresas</TitleButton>
        <SearchContainer>
          <IconButton disabled aria-label="Search" type="submit">
            <SearchIcon />
          </IconButton>
          <InputBase
            name="search_input"
            onChange={changeInputSearch()}
            placeholder="Procurar por nome da empresa ..."
            id="search_input"
          />
        </SearchContainer>

        <ListContainer>
          {customersRender.length > 0 ? (
            customersRender
          ) : (
            <Typography variant="h5" component="h4">
              Nenhuma empresa encontrada.
            </Typography>
          )}
        </ListContainer>
      </div>
    ) : (
      <Loading>
        <CircularProgress color="primary" size={50} />
      </Loading>
    );

  return <div>{Customers}</div>;
}

MessageCreateCustomer.defaultProps = {
  push_notification: true,
  sms_notification: true,
};

MessageCreateCustomer.propTypes = {
  push_notification: PropTypes.bool,
  sms_notification: PropTypes.bool,
};

export default MessageCreateCustomer;
