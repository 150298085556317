import styled from 'styled-components';


export const Container = styled.div`
  .search {
    margin: 0;
    width: 100%;
  }
`;

export const StatusContainer = styled.div`
  margin: 20px 20px 0 20px !important;
`;

export const Content = styled.div`
  width: 300px;

  > .MuiTypography-h6 {
  text-align: left;
  margin: 20px;
  font-size: 18px;
  font-weight: 600;
  } 
`;

export const GridContainer = styled.div`

  .MuiGrid-grid-xs-10 {
    margin: 0;
    flex-direction: row;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .MuiGrid-grid-xs-2 {
    margin: 0; 
    position: absolute; 
    right: 110px;
  }  
`;

export const GridContent = styled.div`
  .MuiTypography-h6 {
    color: #26408B;
    font-weight: 600;
    font-size: 13px;
  }

  .MuiChip-sizeSmall {
    background: #3C5CB9;
    color: #fff;
  }
`;

export const TitleContainer = styled.div`
  margin: 0 20px;

  .MuiTypography-h6 {
    text-align: left;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
  }
`;

export const SubmitContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiFab-sizeSmall {
    display: flex;
    justify-content: center;
    width: 200px !important;
    font-size: 15px;
    background: #3C5CB9;
    color: #fff;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 10px;
`;