export const Types = {
  REQUEST_GET_MESSAGES: 'REQUEST_GET_MESSAGES',
  REQUEST_FILTER_MESSAGES: 'REQUEST_FILTER_MESSAGES',
  SUCCESS_GET_MESSAGES: 'SUCCESS_GET_MESSAGES',
  FAILURE_GET_MESSAGES: 'FAILURE_GET_MESSAGES',

  TOOGLE_DIALOG_DELETE: 'TOOGLE_DIALOG_DELETE',
  FILTER_MESSAGES_LIST: 'FILTER_MESSAGES_LIST',
  FILTER_MESSAGES_FORM: 'FILTER_MESSAGES_FORM',
  NEW_MESSAGE: 'NEW_MESSAGE',

  REQUEST_GET_MESSAGE: 'REQUEST_GET_MESSAGE',
  SUCCESS_GET_MESSAGE: 'SUCCESS_GET_MESSAGE',
  FAILURE_GET_MESSAGE: 'FAILURE_GET_MESSAGE',
  CHANGE_MODAL_CREATE: 'CHANGE_MODAL_CREATE',
  REDIRECT_LOCATION: 'REDIRECT_LOCATION',

  ASYNC_MESSAGE_GROUNDS: 'ASYNC_MESSAGE_GROUNDS',
  MESSAGE_GROUNDS: 'MESSAGE_GROUNDS',
  STOP_GET_MESSAGE: 'STOP_GET_MESSAGE',
};

const INITIAL_VALUES = {
  loading: false,
  error: false,
  total: null,
  data: null,
  messages: [],
  messageDetail: {},
  onLoad: true,
  loadingMessageDetail: false,
  errorMessageDetail: false,
  openCreate: false,
  typeCreate: null,

  groundsLoaded: false,
  grounds: [],
};

export default function reducer(state = INITIAL_VALUES, action) {
  const messages = [...state.messages];

  switch (action.type) {
    case Types.ASYNC_MESSAGE_GROUNDS:
      return {
        ...state,
        groundsLoaded: false,
      };
    case Types.MESSAGE_GROUNDS:
      return {
        ...state,
        grounds: action.payload,
        groundsLoaded: true,
      };
    case Types.REQUEST_GET_MESSAGES:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_GET_MESSAGES:
      return {
        ...state,
        messages: action.payload.docs,
        total: action.payload.total,
        loading: false,
      };
    case Types.FAILURE_GET_MESSAGES:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case Types.NEW_MESSAGE:
      messages.unshift(action.data);
      return {
        ...state,
        messages,
      };
    case Types.CHANGE_MODAL_CREATE:
      return {
        ...state,
        openCreate: action.payload.open,
        typeCreate: action.payload.type_message,
      };

    case Types.REQUEST_GET_MESSAGE:
      return {
        ...state,
        loadingMessageDetail: true,
        onLoad: true,
      };
    case Types.STOP_GET_MESSAGE:
      return {
        ...state,
        loadingMessageDetail: false,
      };
    case Types.SUCCESS_GET_MESSAGE:
      return {
        ...state,
        messageDetail: action.payload,
        loadingMessageDetail: false,
        onLoad: action.onLoad === false ? action.onLoad : true,
      };
    case Types.FAILURE_GET_MESSAGE:
      return {
        ...state,
        loadingMessageDetail: false,
        errorMessageDetail: action.payload,
        grounds: [],
        groundsLoaded: false,
      };

    default:
      return state;
  }
}

export function getMessages(page, per_page) {
  return { type: Types.REQUEST_GET_MESSAGES, payload: { page, per_page } };
}

export function getMessage(id, checkin = false) {
  return { type: Types.REQUEST_GET_MESSAGE, payload: id, checkin };
}

export function redirectLocation(payload) {
  return { type: Types.REDIRECT_LOCATION, payload };
}

export function setOpenCreate(open, type_message) {
  return { type: Types.CHANGE_MODAL_CREATE, payload: { open, type_message } };
}

export function filterMessages(values) {
  return { type: Types.FILTER_MESSAGES_LIST, payload: values };
}

export function filtersMessages(formData) {
  const payload = formData;
  return { type: Types.FILTER_MESSAGES_FORM, payload };
}

export function getGroundMessage(id) {
  return { type: Types.ASYNC_MESSAGE_GROUNDS, payload: { id } };
}
