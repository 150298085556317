import React, { Component } from "react";
import BreadCrumb from "./breadcrumb";
import Header from "./header";
import Customers from "./customers";
import Groups from "./groups";
import Grid from "@material-ui/core/Grid";

class Layout extends Component {
  render() {
    const { breadcrumbsProps, customers, groups, headerSimple } = this.props;

    let items = breadcrumbsProps
      ? breadcrumbsProps
      : [{ name: "Home", url: process.env.PUBLIC_URL + "/" }];
    return (
      <div>
        <Header headerSimple={headerSimple} />
        {!headerSimple && <BreadCrumb items={items} />}

        <Grid container spacing={0}>
          {customers && <Customers disabled={this.props.disabledCustomers} />}
          {groups && <Groups disabled={this.props.disabledGroups} />}
        </Grid>
      </div>
    );
  }
}

export default Layout;
