// types
export const Types = {
  REQUEST_MAIN: "REQUEST_MAIN",
  SUCCESS_MAIN: "SUCCESS_MAIN",
  FAILURE_MAIN: "FAILURE_MAIN",
  CHANGE_MENU: "CHANGE_MENU",

  ASYNC_SSO_AUTHENTICATE: "ASYNC_SSO_AUTHENTICATE",
  ASYNC_SSO_REFRESH_AUTHENTICATE: "ASYNC_SSO_REFRESH_AUTHENTICATE",
};

const INITIAL_VALUES = {
  loading: true,
  error: false,
  current_user: null,
  menu_open: false
};

export default function reducer(state = INITIAL_VALUES, action) {
  //console.log(action);
  switch (action.type) {
    case Types.REQUEST_MAIN:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_MAIN:
      return {
        ...state,
        loading: false,
        error: false,
        current_user: action.payload
      };
    case Types.FAILURE_MAIN:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_MENU:
      return { ...state, menu_open: action.payload };

    default:
      return state;
  }
}

export const loginSSO = (sso, access_token) => ({
  type: Types.ASYNC_SSO_AUTHENTICATE,
  payload: { sso, access_token }
});

export function checkToken() {
  return { type: Types.REQUEST_MAIN };
}

export function changeMenu(value) {
  return { type: Types.CHANGE_MENU, payload: value };
}

