import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

export const TitleButton = styled(Button).attrs({
  variant: 'text',
})`
  background-color: #fff;
  border: 0px;
  font-weight: bold !important;
  font-size: 20px !important;
`;

export const ListContainer = styled(List)`
  max-height: 400px;
  position: relative;
  overflow: auto;
  padding: 0px;
  margin: 0px;
`;

export const NoDataTitle = styled(Typography)`
  font-weight: 400;
  color: #333;
  opacity: 0.6;
  text-align: center;
  margin-top: 20px;
`;

export const ListChild = styled(ListItem)`
  border-bottom: 1px solid #ccc;

  svg {
    margin-right: 10px;
  }
`;

export const SelectedButton = styled(Button)`
  margin-right: 20px;

  &:hover {
    background-color: transparent !important;
  }

  span {
    color: #1356a4;
    font-size: 16px;
    text-transform: lowercase;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SearchContainer = styled.div`
  margin: 0;
  width: 100%;

  > div {
    width: 80%;
  }
`;

export const Loading = styled.div`
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
`;
