import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ListItemText from '@material-ui/core/ListItemText';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { setPage } from '~/store/ducks/message/create/index';
import { changeContactSelecteds } from '~/store/ducks/message/create/contacts';

import {
  Loading,
  NoDataTitle,
  ContactList,
  ListChild,
  BackButton,
} from './styles';

function MessageCreateContacts() {
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();

  const {
    data: contacts,
    loading: loadingContacts,
    contacts: contactsSelected,
  } = useSelector(state => state.CreateMessageContacts);

  const changeInputSearch = () => event => {
    setSearchInput(event.target.value);
  };

  const setAllContacts = group => event => {
    if ((event.target || false) !== false)
      dispatch(
        changeContactSelecteds({
          group,
          checked: event.target.checked,
          setAll: true,
        })
      );
  };

  const setContact = (contact, group) => event => {
    if ((event.target || false) !== false) {
      const payload = {
        contact,
        group,
        checked: event.target.checked,
      };

      dispatch(changeContactSelecteds(payload));
    }
  };

  const checkedContactInGroup = (id, group_id) => {
    let contact = [];
    if (contactsSelected[group_id] && contactsSelected[group_id].users) {
      contact = contactsSelected[group_id].users.filter(item => item.id === id);
    }
    return contact.length > 0;
  };

  const getAllContactGroup = group => {
    let count = 0;
    if (contactsSelected[group.id] && contactsSelected[group.id].users) {
      contactsSelected[group.id].users.forEach(item => {
        if (group.users.filter(user => user.id === item.id)) count += 1;
      });
    }
    return count;
  };

  const contactsData = [];
  const group = contacts;

  if ((group || false) !== false && (group.users || false) !== false) {
    group.users.forEach(item => {
      if (
        searchInput === '' ||
        item.name.toUpperCase().includes(searchInput.toUpperCase())
      ) {
        contactsData.push(
          <ListChild button key={item.id}>
            <Checkbox
              checked={checkedContactInGroup(item.id, group.id) || false}
              onClick={setContact(item, group)}
              color="primary"
            />
            <ListItemText primary={item.name} />
          </ListChild>
        );
      }
    });
  }

  let contentContats = (
    <Loading>
      <CircularProgress color="primary" size={50} />
    </Loading>
  );

  if (
    !loadingContacts &&
    (contacts || false) !== false &&
    contacts.users.length > 0
  ) {
    contentContats = (
      <ContactList>
        <div>
          <IconButton disabled aria-label="Search" type="submit">
            <SearchIcon />
          </IconButton>
          <InputBase
            name="search_input"
            onChange={changeInputSearch()}
            placeholder="Procurar por nome do contato ..."
            id="search_input"
          />
        </div>
        <ListChild button key={0}>
          <Checkbox
            onClick={setAllContacts(group)}
            checked={
              (contacts.users.length === getAllContactGroup(contacts) &&
                contacts.users.length !== 0) ||
              false
            }
            indeterminate={
              (getAllContactGroup(contacts) < contacts.users.length &&
                contacts.users.length !== 0 &&
                getAllContactGroup(contacts) !== 0) ||
              false
            }
            color="primary"
          />

          <ListItemText primary="Selecionar todos" />
        </ListChild>
        {contactsData.length > 0 ? (
          contactsData
        ) : (
          <NoDataTitle variant="h5" component="h4">
            Nenhum contato encontrado.
          </NoDataTitle>
        )}
      </ContactList>
    );
  } else if (
    !loadingContacts &&
    (contacts || false) !== false &&
    contacts.users.length === 0
  ) {
    contentContats = (
      <NoDataTitle variant="h5" component="h4">
        Nenhum contato encontrado.
      </NoDataTitle>
    );
  }

  const Contacts = (
    <div>
      <BackButton onClick={() => dispatch(setPage(1))}>
        <ArrowBackIcon style={{ position: 50 }} />
        Contatos
      </BackButton>
      <Divider />

      {contentContats}
    </div>
  );

  return <div>{Contacts}</div>;
}

export default MessageCreateContacts;
