import React, { Component, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MaterialInputTextAreaRedux from '../material/Inputs/textFieldArea';
import {
  setPage,
  createCheckin,
  setModalCheckin,
} from '../../store/ducks/check-in';
import { getGroups } from '../../store/ducks/check-in/groups';

import {
  changeContactSelecteds,
  // getContacts
} from '../../store/ducks/check-in/contacts';

import MaterialCheckboxRedux from '../material/Inputs/checkbox';
//import FormHelperText from "@material-ui/core/FormHelperText";
//import { formatArray } from "../../../utils/form/index";
import Customers from './customers';
import Contacts from './contacts';

import Groups from './groups';

import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';

import CircularProgress from '@material-ui/core/CircularProgress';
class CheckInForm extends Component {
  handleOpen = () => {
    this.props.setModalCheckin(true);
  };

  handleClose = () => {
    this.props.setModalCheckin(false);
  };
  componentWillMount() {
    if (this.props.current_user) {
      this.props.change(
        'sms_notification',
        this.props.current_user.verifyCustomerCanSendMessage.sms_notification
      );
      this.props.change(
        'push_notification',
        this.props.current_user.verifyCustomerCanSendMessage.push_notification
      );
      this.props.change(
        'force_location',
        this.props.current_user.verifyCustomerCanSendMessage.force_location
      );
    }
  }

  componentDidUpdate = async oldProps => {
    if (
      (oldProps.push_notification !== this.props.push_notification &&
        this.props.pageModal > 0) ||
      (oldProps.sms_notification !== this.props.sms_notification &&
        this.props.pageModal > 0)
    ) {
      if (this.props.customersData && this.props.customersData.length > 1)
        this.props.setPage(0);
      else {
        this.props.getGroups({
          sms_notification: this.props.sms_notification,
          push_notification: this.props.push_notification,
        });
        this.props.setPage(1);
      }
      this.props.changeContactSelecteds([]);
    }
  };

  render() {
    let limitNumber = 142;

    if (this.props.push_notification) {
      limitNumber = 2000;
    }

    if (this.props.sms_notification) {
      limitNumber = 142;
    }
    return (
      <Fragment>
        <Dialog
          open={this.props.openModal}
          //onClose={this.handleClose}
          maxWidth={'xl'}
          scroll={'body'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          {!this.props.submitting ? (
            <Fragment>
              <DialogTitle id="alert-dialog-title">
                {'Enviar Check In'}
              </DialogTitle>
              <IconButton style={{ position: 'absolute', right: 0, top: 0 }}>
                <CloseIcon onClick={() => this.props.setModalCheckin(false)} />
              </IconButton>
              <Divider />

              <DialogContent style={{ width: '100vw', overflowX: 'hidden' }}>
                <form
                  onSubmit={this.props.handleSubmit(this.props.createCheckin)}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={6}>
                      <div
                        style={{
                          padding: 40,
                        }}>
                        <Field
                          name="description"
                          type="description"
                          label="Mensagem de Check-In"
                          placeholder="Digite a descrição da mensagem de check in ..."
                          variant="outlined"
                          multiline
                          limitNumber={limitNumber}
                          limit={
                            this.props.sms_notification ||
                            this.props.push_notification
                          }
                          rows={10}
                          component={MaterialInputTextAreaRedux}
                          id="description"
                        />
                      </div>
                    </Grid>
                    <div
                      style={{
                        position: 'absolute',
                        height: 510,
                        float: 'right',
                        borderLeft: '1px solid #CCCCCCCC',
                      }}
                    />
                    <Grid item xs={6}>
                      {this.props.submitFailed && this.props.error && (
                        <FormHelperText
                          id="component-error-text"
                          style={{
                            color: '#840000',
                            textAlign: 'left',
                            paddingBottom: 20,
                          }}>
                          {this.props.error}
                        </FormHelperText>
                      )}
                      {this.props.pageModal === 0 ? <Customers /> : ''}
                      {this.props.pageModal === 1 ? (
                        <Groups
                          push_notification={this.props.push_notification}
                          sms_notification={this.props.sms_notification}
                        />
                      ) : (
                        ''
                      )}
                      {this.props.pageModal === 2 ? <Contacts /> : ''}
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      justifyContent: 'flex-start',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Field
                      name="sms_notification"
                      type="sms_notification"
                      props={{
                        disabled: !this.props.current_user
                          .verifyCustomerCanSendMessage.sms_notification,
                      }}
                      inputLabel="Enviar por SMS"
                      component={MaterialCheckboxRedux}
                      id="sms_notification"
                    />

                    <Field
                      name="push_notification"
                      type="push_notification"
                      props={{
                        disabled: !this.props.current_user
                          .verifyCustomerCanSendMessage.push_notification,
                      }}
                      inputLabel="Enviar para o App"
                      component={MaterialCheckboxRedux}
                      id="push_notification"
                    />
                  </div>

                  {!this.props.sms_notification && (
                    <Field
                      style={{ alignSelf: 'center', display: 'flex' }}
                      name="force_location"
                      props={{
                        disabled: !this.props.current_user
                          .verifyCustomerCanSendMessage.force_location,
                      }}
                      type="force_location"
                      inputLabel="Forçar localização"
                      component={MaterialCheckboxRedux}
                      id="force_location"
                    />
                  )}

                  <Divider />

                  {!this.props.sms_notification && (
                    <h5>
                      Ao ativar a opção, forçar localização, revela a
                      localização do(s) contato(s) caso não haja confirmação.
                    </h5>
                  )}

                  <div
                    style={{
                      backgroundColor: '#FFF',
                      marginTop: 80,
                    }}>
                    <Fab
                      variant="extended"
                      size="small"
                      type="submit"
                      color="primary"
                      aria-label="Save"
                      disabled={this.props.submitting}
                      style={{
                        width: 120,
                        fontSize: 15,
                        position: 'absolute',
                        right: 50,
                        bottom: 20,
                      }}>
                      Enviar
                    </Fab>
                  </div>
                </form>
              </DialogContent>
            </Fragment>
          ) : (
            <div
              style={{
                minHeight: 400,
                minWidth: 1200,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                display: 'flex',
              }}>
              <CircularProgress color="primary" size={50} />
            </div>
          )}
        </Dialog>
      </Fragment>
    );
  }
}

const selector = formValueSelector('CHECK_IN_FORM');

CheckInForm = reduxForm({
  form: 'CHECK_IN_FORM',
  initialValues: {
    /* sms_notification: true,
    push_notification: this.props.current_user.verifyCustomerCanSendMessage
      .push_notification,
    force_location: false*/
  },
})(CheckInForm);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPage,
      changeContactSelecteds,
      createCheckin,
      setModalCheckin,
      change,
      getGroups,
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    pageModal: state.CheckIn.page,
    current_user: state.Main.current_user,
    openModal: state.CheckIn.modalCheckin,
    contactSelecteds: state.CheckInContacts.contacts,
    customersData: state.CheckInCustomers.data,
    customersDataLoading: state.CheckInCustomers.loading,
    customersDataSelected: state.CheckInCustomers.customer,
    openCreate: state.CheckIn.openCreate,
    sms_notification: selector(state, 'sms_notification'),
    force_location: selector(state, 'force_location'),
    push_notification: selector(state, 'push_notification'),
  };
};

// Decorator para Redux Form
export default connect(mapStateToProps, mapDispatchToProps)(CheckInForm);
