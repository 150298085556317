import styled from 'styled-components';

import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

export const Container = styled(TableRow)`
  height: 5vh;

  .grey-font {
    color: #999;
    font-size: 14px;
    flex-grow: 1;

    > div {
      display: inline-flex;
    }
  }

  .text-title {
    color: #999;
    font-size: 14px;
  }
`;

export const IconsContainer = styled.div`
  .MuiChip-sizeSmall {
    background-color: #5c5c5c;
    color: #fff;
  }

  .criticality {
    margin-right: 0.5vw;
  }

  .receive-options {
    flex: 1;
    display: inline-flex;
    margin-left: 0.5vw;
  }
`;

export const GridStars = styled(Grid)`
  display: flex;
  align-items: center;

  > div {
    display: inline-flex;
  }
`;

export const MessagesNatureIcon = styled(Grid).attrs({
  container: true,
})`
  height: 72px;
  justify-content: space-between;
  align-items: center;

  > div {
    min-width: 26% !important;
  }

  .MuiGrid-grid-xs-6 {
    word-break: break-word !important;
  }
`;
