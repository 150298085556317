import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';

import PropTypes from 'prop-types';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialInputSwitchRedux from '../../material/Inputs/switch';

import { getGroups } from '~/store/ducks/contact/associationGroup';

import { Loading, ListGroupContainer } from './styles';

function ListGroup({ user_id, customer_id }) {
  const dispatch = useDispatch();

  const { groupsByContact, loading } = useSelector(
    state => state.AssociationGroup
  );

  const checkExistsUser = (userId, users) => {
    const filterUser = users.filter(
      user => parseInt(user.id, 10) === parseInt(userId, 10)
    );
    if (filterUser.length > 0) return true;

    return false;
  };

  const [checkedGroup, setCheckedGroup] = useState(false);

  const changeAll = () => {
    setCheckedGroup(!checkedGroup);
    groupsByContact.map(group => {
      dispatch(
        change('CONTACT_FORM_EDIT', `groups_id.${group.id}`, !checkedGroup)
      );
      return true;
    });
  };

  useEffect(() => {
    dispatch(getGroups(user_id, customer_id));
  }, [dispatch, customer_id, user_id]);

  if (loading) {
    return (
      <Loading>
        <CircularProgress color="primary" size={50} />
      </Loading>
    );
  }
  return (
    <ListGroupContainer>
      {groupsByContact.length > 0 ? (
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={checkedGroup}
                onChange={() => {
                  changeAll();
                }}
                color="primary"
              />
            }
            labelPlacement="start"
            label="Selecionar todos os grupos"
          />
          <Divider />
          <List>
            {groupsByContact.map(group => {
              if (
                (group.groupInfo && !group.groupInfo.custom) ||
                checkExistsUser(user_id, group.users)
              )
                return (
                  <ListItem key={group.id}>
                    <Field
                      name={`groups_id.${group.id}`}
                      inputLabel={
                        group.groupInfo.status_active
                          ? `${group.name} (Ativo)`
                          : `${group.name} (Desativado)`
                      }
                      component={MaterialInputSwitchRedux}
                      id={group.id}
                      autoFocus
                    />
                  </ListItem>
                );

              return false;
            })}
          </List>
        </div>
      ) : (
        <Typography variant="h5" component="h4">
          Nenhum grupo encontrado.
        </Typography>
      )}
    </ListGroupContainer>
  );
}

ListGroup.propTypes = {
  user_id: PropTypes.number.isRequired,
  customer_id: PropTypes.number.isRequired,
};

export default ListGroup;
