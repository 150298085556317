import { takeLatest, put, call } from "redux-saga/effects";
import { Types } from "../../ducks/group/delete";
import axios from "../../../services/api.js";
import { Types as TypeMessage } from "../../ducks/main/message";
import { Messages } from "../../../components/alert/messages";
/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiDelete(groupId) {
  return axios.delete(`/group/${groupId}`);
}

function* deleteGroup(action) {
  try {
    let groupId = action.payload;

    yield call(apiDelete, groupId);

    yield put({
      type: Types.FINISH_DELETE_GROUP
    });

    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.DELETE_SUCCESS_GROUP,
        variant: "success"
      }
    });

    yield put({
      type: "REQUEST_GET_GROUPS"
    });
  } catch (error) {
    yield put({
      type: Types.FINISH_DELETE_GROUP
    });

    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.DELETE_ERROR_GROUP,
        variant: "error"
      }
    });
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_DELETE_GROUP, deleteGroup);
}
