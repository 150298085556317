import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

class MaterialTextFieldRedux extends Component {
  renderInput() {
    const {
      defaultValue,
      inputLabel,
      name,
      input,
      type,
      meta: { error }
    } = this.props;
    // console.log(this.props);
    const hasError = (error || false) !== false;
    let disabled = this.props.disabled || false;
    return (
      <FormControl margin="normal" error={hasError} fullWidth>
        <InputLabel htmlFor={name}>{inputLabel}</InputLabel>
        <TextField
          disabled={disabled}
          {...input}
          type={type}
          error={hasError}
          defaultValue={defaultValue}
        />
        <FormHelperText id="component-error-text">
          {hasError ? error.join(", ") : ""}
        </FormHelperText>
      </FormControl>
    );
  }

  render() {
    return <div>{this.renderInput()}</div>;
  }
}

export default MaterialTextFieldRedux;
