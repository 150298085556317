// types
export const Types = {
  REQUEST_LIST_CONTACTS_GROUPS: "REQUEST_LIST_CONTACTS_GROUPS",
  SUCCESS_LIST_CONTACTS_GROUPS: "SUCCESS_LIST_CONTACTS_GROUPS",
  FAILURE_LIST_CONTACTS_GROUPS: "FAILURE_LIST_CONTACTS_GROUPS",
  UPDATE_CONTACT_LIST_GROUPS: "UPDATE_CONTACT_LIST_GROUPS",
  FILTER_CONTACT_LIST_GROUPS: "FILTER_CONTACT_LIST_GROUPS",
  INITIALIZE_FORM_ASSOCIATION_USERS_GROUP:
    "INITIALIZE_FORM_ASSOCIATION_USERS_GROUP",
  TOTAL_CONTACTS_GROUP: "TOTAL_CONTACTS_GROUP",
  TOTAL_CONTACTS_GROUP_SUM: "TOTAL_CONTACTS_GROUP_SUM",
  SET_PAGE: "SET_PAGE",
  SET_ROW_PER_PAGE: "SET_ROW_PER_PAGE",
  SHOW_CANCEL: "SHOW_CANCEL"
};

const INITIAL_VALUES = {
  loading: false,
  error: false,
  contacts: [],
  page: 0,
  rowsPerPage: 0,
  total: 0,
  totalContactsSelected: 0,
  users_id_in_group: null,
  showCancel: false
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.REQUEST_LIST_CONTACTS_GROUPS:
      return {
        ...state,
        loading: true
      };
    case Types.INITIALIZE_FORM_ASSOCIATION_USERS_GROUP:
      return {
        ...state,
        users_id_in_group: action.payload
      };
    case Types.FILTER_CONTACT_LIST_GROUPS:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_LIST_CONTACTS_GROUPS:
      return {
        ...state,
        loading: false,
        error: false,
        contacts: action.payload.contacts,
        total: action.payload.total
      };
    case Types.FAILURE_LIST_CONTACTS_GROUPS:
      return { ...state, loading: false, error: true };
    case Types.UPDATE_CONTACT_LIST_GROUPS:
      let contacts = state.contacts.map(contact =>
        contact.id === action.payload.id ? action.payload : contact
      );

      return {
        ...state,
        contacts: contacts
      };
    case Types.TOTAL_CONTACTS_GROUP:
      return {
        ...state,
        totalContactsSelected: action.payload
      };
    case Types.TOTAL_CONTACTS_GROUP_SUM:
      return {
        ...state,
        totalContactsSelected: state.totalContactsSelected + action.payload
      };
    case Types.SET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case Types.SET_ROW_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload
      };
    case Types.SHOW_CANCEL:
      return {
        ...state,
        showCancel: action.payload.showCancel
      };
    default:
      return state;
  }
};

export function getContacts() {
  return { type: Types.REQUEST_LIST_CONTACTS_GROUPS };
}

export function setTotalContactsSelectedSum(value) {
  return {
    type: Types.TOTAL_CONTACTS_GROUP_SUM,
    payload: value
  };
}

export function setPage(page) {
  return { type: Types.SET_PAGE, payload: page };
}

export function setRowsPerPage(rowPerPage) {
  return { type: Types.SET_ROW_PER_PAGE, payload: rowPerPage };
}

export function setTotalContactsSelected(totalContactsSelected) {
  return {
    type: Types.TOTAL_CONTACTS_GROUP,
    payload: totalContactsSelected
  };
}

export function filterContacts(formData) {
  let payload = {
    formData
  };
  return { type: Types.FILTER_CONTACT_LIST_GROUPS, payload };
}

export function showCancel() {
  let payload = {
    showCancel: true
  };
  return { type: Types.SHOW_CANCEL, payload };
}
