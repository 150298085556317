import React, { Component } from "react";
import Input from "@material-ui/core/Input";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

class MaterialInputRedux extends Component {
  renderInput() {
    const {
      placeholder,
      inputLabel,
      name,
      input,
      type,
      disabled = false,
      meta: { error }
    } = this.props;

    const hasError = (error || false) !== false;
    return (
      <FormControl margin="normal" error={hasError} fullWidth>
        <InputLabel htmlFor={name}>{inputLabel}</InputLabel>
        <Input
          {...input}
          disabled={disabled}
          type={type}
          error={hasError}
          placeholder={placeholder}
        />
        <FormHelperText id="component-error-text">
          {hasError ? error.join(", ") : ""}
        </FormHelperText>
      </FormControl>
    );
  }

  render() {
    return <div>{this.renderInput()}</div>;
  }
}

export default MaterialInputRedux;
