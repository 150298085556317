import { takeLatest, put, call, all, delay, select } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { Types } from '../../ducks/message/list';
import axios from '~/services/api';

function apiGetMessages(page = 1, per_page = 25) {
  return axios.get(`/messages?page=${page}&per_page=${per_page}`);
}

function apiGetMessage(id) {
  return axios.get(`/message/${id}`);
}

function apiGetFilteredMessages(input = '') {
  return axios.get(`/messages/filter?input=${input}`);
}

function apiGetGroundsMessages(id) {
  return axios.get(`user/messages/${id}/grounds`);
}

function* loadActiveMessage({ payload }) {
  try {
    const { data } = yield call(apiGetGroundsMessages, payload.id);

    yield put({ type: Types.MESSAGE_GROUNDS, payload: data });
  } catch (err) {
    yield all([put({ type: Types.MESSAGE_GROUNDS, payload: [] })]);
  }
}

function* getMessages({ payload }) {
  try {
    const pageTable = (payload.page || 0) + 1;
    const { data: messages } = yield call(
      apiGetMessages,
      pageTable,
      payload.per_page
    );

    yield put({
      type: Types.SUCCESS_GET_MESSAGES,
      payload: {
        ...messages,
        docs: [...messages.docs],
      },
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_GET_MESSAGES,
      payload: error,
    });
  }
}

function* loadMessage(id) {
  const { data } = yield call(apiGetMessage, id);

  yield put({
    type: Types.SUCCESS_GET_MESSAGE,
    payload: data,
  });
}

function* getMessage(action) {
  if (!action.checkin) {
    try {
      yield call(loadMessage, action.payload);
    } catch (error) {
      yield put({
        type: Types.FAILURE_GET_MESSAGE,
        payload: error,
      });
    }
  }

  while (action.checkin) {
    const { onLoad } = yield select(state => state.MessageList);

    if (!onLoad) {
      yield put({
        type: Types.STOP_GET_MESSAGE,
      });
      break;
    }

    yield call(loadMessage, action.payload);
    yield delay(30000);
  }
}

function* filtersMessages(action) {
  yield put(startSubmit('FILTER_MESSAGES_FORM'));
  yield put(stopSubmit('FILTER_MESSAGES_FORM'));

  if (Object.keys(action.payload).length > 0) {
    try {
      const filters = action.payload;
      const input = filters.search_input;

      const response = yield call(apiGetFilteredMessages, input);
      const messages = response.data;

      yield put({
        type: Types.SUCCESS_GET_MESSAGES,
        payload: messages,
      });
    } catch (error) {
      yield put({
        type: Types.FAILURE_GET_MESSAGES,
        payload: error,
      });
    }
  } else {
    yield put({
      type: Types.FILTER_MESSAGES_LIST,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(Types.REQUEST_GET_MESSAGES, getMessages),
    takeLatest(Types.REQUEST_GET_MESSAGE, getMessage),
    takeLatest(Types.FILTER_MESSAGES_FORM, filtersMessages),
    takeLatest(Types.ASYNC_MESSAGE_GROUNDS, loadActiveMessage),
  ]);
}
