import { takeLatest, put, call, all } from "redux-saga/effects";
import { Types } from "../../ducks/contact/form";
import axios from "../../../services/api.js";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGetContact(contactId) {
  return axios.get(`/contact/${contactId}`);
}

function apiGetAllGroupsByUserId(user_id) {
  return axios.get(`/contact/${user_id}/groups`);
}

function* getContact(action) {
  try {
    const resp = yield call(apiGetContact, action.payload.contactId);
    const respGroups = yield call(apiGetAllGroupsByUserId, resp.data.id);

    resp.data.contact.groups_id = [];

    respGroups.data.groups.map((group, index) => {
      if (group) {
        resp.data.contact.groups_id[group.id] = true;
        return true;
      }
      return false;
    });

    yield put({
      type: Types.SUCCESS_REQUEST_GET_CONTACT,
      payload: resp.data
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_REQUEST_GET_CONTACT
    });
  }
}

export default function* root() {
  yield all([takeLatest(Types.REQUEST_GET_CONTACT, getContact)]);
}
