import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Marker } from 'react-google-maps';
import MetaTags from 'react-meta-tags';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import moment from 'moment';
import Logo from '~/utils/imgs/logo.png';

import Map from '../map';
import {
  Root,
  Container,
  ContainerInfos,
  ImageContainer,
  ImageSpacing,
  ImageLogo,
  MapContainer,
  Loading,
  Paragraphy,
} from './styles';

function MessageIncidentPublic({ location }) {
  const [incident, setIncident] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tab, setTab] = useState(0);

  const { google } = window;

  const dataIncident = useMemo(() => incident && incident.startedAt, [
    incident,
  ]);
  const dataFormated = moment(dataIncident).format('DD/MM/YYYY [às] HH:mm');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    setTab(queryParams.get('lang') === 'en' ? 1 : 0);

    if (queryParams.has('data')) {
      try {
        const buff = Buffer.from(queryParams.get('data'), 'base64');
        const incidentData = JSON.parse(buff.toString('utf8'));

        setIncident(incidentData);
        setLoaded(true);
      } catch (err) {
        setIncident(null);
        setLoaded(true);
      }
    } else {
      setIncident(null);
      setLoaded(true);
    }
  }, [location, location.search]);

  const handleChangeTab = (event, tabs) => setTab(tabs);

  const renderInfo = () => {
    if (!loaded) {
      return (
        <Loading>
          <CircularProgress style={{ color: '#FFF' }} />
        </Loading>
      );
    }

    if (!incident) {
      return (
        <ContainerInfos>
          <Typography style={{ color: '#EFEFEF', marginTop: 20 }} variant="h5">
            Não foram encontradas informações
          </Typography>
        </ContainerInfos>
      );
    }

    return (
      <>
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            aria-label="Lang Tabs"
            variant="fullWidth">
            <Tab
              label="PT-BR"
              id="lang-tab-0"
              aria-controls="lang-tabpanel-0"
            />
            <Tab label="EN" id="lang-tab-1" aria-controls="lang-tabpanel-1" />
          </Tabs>
        </AppBar>
        <ContainerInfos>
          <div hidden={tab !== 0}>
            <Paragraphy>
              <small>Tipo:</small>
              <br />
              <span>{`${incident.category} - ${incident.type}`}</span>
            </Paragraphy>
            <Paragraphy breakword="true">
              <small>Descrição:</small>
              <br />
              <span>{incident.description}</span>
            </Paragraphy>
            <Paragraphy>
              <small>Iniciado em:</small>
              <br />
              {dataFormated} (UTC-3)
            </Paragraphy>
          </div>
          <div hidden={tab !== 1}>
            <Paragraphy>
              <small>Type:</small>
              <br />
              <span>{`${incident.categoryEn} - ${incident.typeEn}`}</span>
            </Paragraphy>
            <Paragraphy breakword="true">
              <small>Description:</small>
              <br />
              <span>{incident.descriptionEn}</span>
            </Paragraphy>

            <Paragraphy>
              <small>Started at:</small>
              <br />
              {dataFormated} (UTC-3)
            </Paragraphy>
          </div>
        </ContainerInfos>
      </>
    );
  };

  return (
    <Root>
      <MetaTags>
        <meta property="og:title" content="Umessenger" />
        <meta property="og:description" content="Veja mais detalhes" />
        <meta property="og:image" content="../../utils/imgs/umessenger.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />
      </MetaTags>
      <Container>
        <ImageContainer>
          <ImageSpacing>
            <ImageLogo src={Logo} alt="Logo UMessenger" />
          </ImageSpacing>
        </ImageContainer>
        {renderInfo()}
      </Container>
      <MapContainer>
        {loaded && incident ? (
          (() => {
            const center = new google.maps.LatLng(
              incident.location.lat,
              incident.location.lng
            );
            return (
              <Map center={center} zoom={17}>
                <Marker
                  position={
                    new window.google.maps.LatLng(
                      incident.location.lat,
                      incident.location.lng
                    )
                  }
                  icon={{ url: incident.icon, optimized: true }}
                />
              </Map>
            );
          })()
        ) : (
          <Map zoom={17} />
        )}
      </MapContainer>
    </Root>
  );
}

MessageIncidentPublic.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default MessageIncidentPublic;
