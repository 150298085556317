import { takeLatest, put, call, select } from "redux-saga/effects";
import { Types } from "../../ducks/contact/create";
import axios from "../../../services/api.js";
import { startSubmit, stopSubmit } from "redux-form";
import { Types as TypeMessage } from "../../ducks/main/message";
import { Messages } from "../../../components/alert/messages";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(parameters, customer) {
  return axios.post(`/contact`, {
    ...parameters,
    customer_id: customer.id,
    customer_name: customer.name
  });
}

function* createContact(action) {
  yield put(startSubmit("CONTACT_FORM"));
  try {
    const customer = yield select(state => state.Customers.customer);
    yield call(apiGet, action.payload, customer);

    yield put(stopSubmit("CONTACT_FORM"));
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.CREATE_SUCCESS_CONTACT,
        variant: "success"
      }
    });
  } catch ({ response }) {
    if (response && response.data)
      yield put(stopSubmit("CONTACT_FORM", response.data.errors));
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.CREATE_ERROR_CONTACT,
        variant: "error"
      }
    });
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_CONTACT_FORM, createContact);
}
