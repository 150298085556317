import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { checkToken } from "../store/ducks/main";

import AppRouter from "../routes";
import { compose, withProps } from "recompose";
import { withScriptjs } from "react-google-maps";

import LoadingScreen from "./screenLoading";

class App extends Component 
{
  componentDidMount = () => {
    this.props.checkToken();
  };

  render() {
    const { loading } = this.props;

    if (loading) {
      return <LoadingScreen />;
    }

    return <AppRouter />;
  }
}

const librariesForMap = ["geometry", "drawing", "places", "visualization"];

App = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      process.env.REACT_APP_GOOGLE_KEY
    }&v=3.exp&libraries=${librariesForMap.join(",")}`,
    loadingElement: <div style={{ width: "100%", height: `100%` }} />
  }),
  withScriptjs
)(App);

const mapStateToProps = state => ({
  loading: state.Main.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ checkToken }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
