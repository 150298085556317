// types
export const Types = {
  REQUEST_OPERACTORS_PHONES: "REQUEST_OPERACTORS_PHONES",
  SUCCESS_OPERACTORS_PHONES: "SUCCESS_OPERACTORS_PHONES",
  FAILURE_OPERACTORS_PHONES: "FAILURE_OPERACTORS_PHONES",
  REQUEST_GET_CONTACT: "REQUEST_GET_CONTACT",
  SUCCESS_REQUEST_GET_CONTACT: "SUCCESS_REQUEST_GET_CONTACT",
  FAILURE_REQUEST_GET_CONTACT: "FAILURE_REQUEST_GET_CONTACT"
};

const INITIAL_VALUES = {
  loadingOperatorsPhoneData: false,
  operatorsPhoneData: null,
  loadingContact: false,
  contact: null,
  userContact: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_OPERACTORS_PHONES:
      return {
        ...state,
        loadingOperatorsPhoneData: true
      };
    case Types.SUCCESS_OPERACTORS_PHONES:
      return {
        ...state,
        loadingOperatorsPhoneData: false,
        operatorsPhoneData: action.payload
      };
    case Types.FAILURE_OPERACTORS_PHONES:
      return { ...state, loadingOperatorsPhoneData: false };

    case Types.REQUEST_GET_CONTACT:
      return {
        ...state,
        loadingContact: true
      };
    case Types.SUCCESS_REQUEST_GET_CONTACT:
      return {
        ...state,
        loadingContact: false,
        contact: action.payload.contact,
        userContact: action.payload
      };
    case Types.FAILURE_REQUEST_GET_CONTACT:
      return { ...state, loadingContact: false };

    default:
      return state;
  }
}

export function getCellphoneOperators() {
  return { type: Types.REQUEST_OPERACTORS_PHONES };
}

export function getContactEdit(contactId) {
  let payload = {
    contactId
  };
  return { type: Types.REQUEST_GET_CONTACT, payload };
}
