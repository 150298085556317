import { takeLatest, put, call, select, all } from "redux-saga/effects";
import { Types } from "../../ducks/contact/list";
import axios from "../../../services/api.js";
import { startSubmit, stopSubmit } from "redux-form";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(customer) {
  return axios.get(`/contacts?customer_id=${customer.id}`);
}

function filterStatus(contacts, status) {
  let status_active = status === 1 ? true : false;
  return contacts.filter(item => {
    if (item.contact) return item.contact.status_active === status_active;
    else if (status_active) return item;
    return false;
  });
}

function filterNameOrCel(contacts, search) {
  return contacts.filter(item => {
    if (
      item.name
        .toUpperCase()
        .trim()
        .includes(search.toUpperCase().trim())
    ) {
      return item;
    }
    if (item.phones[0] && item.phones[0].name.includes(search)) return item;
    return false;
  });
}

function filterReceive(contacts, search) {
  return contacts.filter(item => {
    if (item.contact && item.contact.recieve_and_send_option === search)
      return item;
    else if (!item.contact && search === 5) return item;

    return false;
  });
}

function* Contacts() {
  try {
    const customer = yield select(state => state.Customers.customer);
    const resp = yield call(apiGet, customer);

    const contacts = resp.data.contacts;
    let contactAtive = [];
    let contactInative = [];

    contacts.map((contact, index) => {
      if (contact.contact) {
        if (contact.contact.status_active === true) {
          contactAtive.push(contact);
        } else {
          contactInative.push(contact);
        }
      } else {
        contactAtive.push(contact);
      }

      return true;
    });

    yield put({
      type: Types.SUCCESS_LIST_CONTACTS,
      payload: { contacts: contactAtive.concat(contactInative) }
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_LIST_CONTACTS
    });
  }
}

function* FilterContacts(action) {
  yield put(startSubmit("FILTER_CONTACT_FORM"));
  yield put(stopSubmit("FILTER_CONTACT_FORM"));
  try {
    const customer = yield select(state => state.Customers.customer);
    const resp = yield call(apiGet, customer);
    let contactsFilter = resp.data.contacts;
    let dataForm = action.payload.formData;

    if (dataForm.search_input) {
      contactsFilter = yield call(
        filterNameOrCel,
        contactsFilter,
        dataForm.search_input
      );
    }

    if (dataForm.status_active && dataForm.status_active !== 3) {
      contactsFilter = yield call(
        filterStatus,
        contactsFilter,
        dataForm.status_active
      );
    }

    if (dataForm.recieve && dataForm.recieve !== 4) {
      contactsFilter = yield call(
        filterReceive,
        contactsFilter,
        dataForm.recieve
      );
    }

    const Allcontacts = contactsFilter;
    let contactAtive = [];
    let contactInative = [];

    Allcontacts.map((contact, index) => {
      if (contact.contact) {
        if (contact.contact.status_active === true) {
          contactAtive.push(contact);
        } else {
          contactInative.push(contact);
        }
      } else {
        contactAtive.push(contact);
      }

      return true;
    });

    let contacts = {
      contacts: contactAtive.concat(contactInative),
      total: contactsFilter.length
    };
    return yield put({
      type: Types.SUCCESS_LIST_CONTACTS,
      payload: contacts
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_LIST_CONTACTS
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(Types.REQUEST_LIST_CONTACTS, Contacts),
    takeLatest(Types.FILTER_CONTACT_LIST, FilterContacts)
  ]);
}
