import styled from 'styled-components';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";

export const ListContainer = styled(List)`
  max-width: 85%;
  max-height: 400px;
  position: relative;
  overflow: auto;
  padding: 0;

  h4 {
    font-weight: 400;
    color: #333;
    opacity: 0.6;
    text-align: center;
    margin-top: 20px;
  }

  > div {
    margin: 0;
    width: 100%;

    .MuiInputBase-root {
      width: 80%;
    }
  }
`;

export const ListChild = styled(ListItem)`
  border-bottom: 1px solid #CCC;
`;

export const Loading = styled.div`
  text-align: center; 
  margin-top: 200px; 
  margin-bottom: 200px;
`;


export const BackButton = styled(Button)`
  background-color: #FFF;
  border: 0;
  font-weight: bold !important;
  font-size: 20px !important;
`;