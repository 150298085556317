import React, { useState, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';

import {
  Tooltip,
  ListItem,
  InputBase,
  Typography,
  IconButton,
  ListItemText,
} from '@material-ui/core';

import { Search as SearchIcon } from '@material-ui/icons'

import {
  ListContainer,
  SuccessIcon,
  PendingIcon,
  SentIcon,
  FailedToSendIcon,
  FailedToBeDeliveredIcon,
} from './styles';

function DetailGroups({ groups }) {
  const [searchInput, setSearchInput] = useState('');
  const groupsSize = useMemo(() => groups?.length, [groups]);

  let timeout = 0;

  const changeInputSearch = () => event => {
    const searchText = event.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearchInput(searchText);
    }, 1000);
  };

  const filter = (groupsData, groupsSearch) => {
    const groupsSearchName = [];
    const groupsSearchContacts = [];
    const contactsSearchPhone = [];

    if (groupsData?.length) {
      if (!searchInput) {
        return groupsData;
      }

      if (searchInput) {
        groups.forEach(group => {
          if (
            group.name.toUpperCase().includes(searchInput.toUpperCase().trim())
          ) {
            groupsSearchName.push(group);
          }

          group.contacts.forEach(contact => {
            if (
              contact.name
                .toUpperCase()
                .includes(searchInput.toUpperCase().trim())
            ) {
              groupsSearchContacts.push(group);
            }

            contact.phones.forEach(phone => {
              if (
                phone.data
                  .toUpperCase()
                  .includes(searchInput.toUpperCase().trim())
              ) {
                contactsSearchPhone.push(group);
              }

              if (
                phone.status
                  .toUpperCase()
                  .includes(searchInput.toUpperCase().trim())
              ) {
                contactsSearchPhone.push(group);
              }
            });

            contact.phonesByPassport.forEach(phone => {
              if (
                phone.name
                  .toUpperCase()
                  .includes(searchInput.toUpperCase().trim())
              ) {
                contactsSearchPhone.push(group);
              }
            });
          });
          groupsSearch = groupsSearchName.concat(
            groupsSearchContacts,
            contactsSearchPhone
          );
        });
        return Array.from(new Set(groupsSearch));
      }
    }
    return null;
  };

  const getIconMessageStatus = useCallback((phones) => {
    if (!Array.isArray(phones) || phones.length === 0)
      return null

    const iconsComponents = {
      'None': () => null,
      'Pendente': () => <PendingIcon />,
      'Erro ao enviar': () => <FailedToSendIcon />,
      'Enviado': () => <SentIcon />,
      'Não recebido': () => <FailedToBeDeliveredIcon />,
      'Entregue': () => <SuccessIcon />,
    }

    return phones.map(phone => {
      const phoneStatusSMS = phone.status ?? 'None'
      const componentKey = iconsComponents.hasOwnProperty(phoneStatusSMS)
        ? phoneStatusSMS
        : 'None'

      return (
        <div className="contact-phone">
          <span>{phone.data}</span>
          <Tooltip title={componentKey} arrow>
            {iconsComponents[componentKey]()}
          </Tooltip>
        </div>
      )
    })
  }, [])

  let groupsSearch = [];
  let contentGroups = <div />;

  groupsSearch = filter(groups, groupsSearch);

  console.log('heyyy', groupsSearch)

  if (groupsSize > 0) {
    contentGroups = (
      <ListContainer>
        <ListItem key={0}>
          <IconButton aria-label="Search" type="submit">
            <SearchIcon />
          </IconButton>
          <InputBase
            name="search_input"
            autoComplete="off"
            onChange={changeInputSearch()}
            placeholder="Procurar pelo nome do contato , telefone ou grupo"
            id="search_input"
          />
        </ListItem>
        {groupsSearch.map(group => (
          <ListItem key={group.id}>
            <ListItemText
              primary={
                <>
                  <Typography>{group.name}</Typography>
                </>
              }
              secondary={
                <div className='MuiTypography-body1'>
                  <div>Contatos</div>
                  {group.contacts.map((contact) => (
                    <div key={contact.id}>
                      <div className='group-contacts'>
                        <div>{contact.name}</div>
                        <div className='contact-phones'>
                          {getIconMessageStatus(contact.phones)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          </ListItem>
        ))}
      </ListContainer>
    );
  }

  return (
    <div>
      <div>{contentGroups}</div>
    </div>
  );
}

DetailGroups.propTypes = {
  groups: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default DetailGroups;
