import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import { subscribe, unsubscribe } from 'pusher-redux';
import { Types, getMessages } from '~/store/ducks/message/list';

import TableCustomRow from './TableRow';
import MessageDetail from '../Detail';
import MessageDetailCheckin from '../DetailCheckin';

import { Loading, NoMessages, Paginator } from './styles';

function MessageList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [open, setOpen] = useState(false);
  const [openCheckin, setOpenCheckin] = useState(false);

  const { messages, loading } = useSelector(state => state.MessageList);

  const { total } = useSelector(state => state.MessageList);
  const { current_user } = useSelector(state => state.Main);

  const dispatch = useDispatch();

  useEffect(() => {
    subscribe(
      `private-umessenger-messages-${current_user.customer_id}`,
      'new-message',
      Types.NEW_MESSAGE
    );

    return () => {
      unsubscribe(
        `private-umessenger-messages-${current_user.customer_id}`,
        'new-message',
        Types.NEW_MESSAGE
      );
    };
  });

  const handleClose = () => {
    setOpen(false);
    setOpenCheckin(false);
  };

  const handleChangeRowsPerPage = event => {
    const currentRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(currentRowsPerPage);
    setPage(0);
    dispatch(getMessages(0, currentRowsPerPage));
  };

  const handleChangePage = (event, newPage) => {
    const currentPage = newPage;
    dispatch(getMessages(currentPage, rowsPerPage));
    setPage(currentPage);
  };

  return (
    <>
      {open && <MessageDetail open={open} handleClose={handleClose} />}
      {openCheckin && (
        <MessageDetailCheckin open={openCheckin} handleClose={handleClose} />
      )}

      <Table>
        {loading ? (
          <Loading>
            <tr>
              <th>
                <CircularProgress />
              </th>
            </tr>
          </Loading>
        ) : (
          <TableBody style={{ margin: 0 }}>
            {messages.length <= 0 ? (
              <TableRow>
                <NoMessages component="th" scope="column">
                  Não há mensagens
                </NoMessages>
              </TableRow>
            ) : (
              messages.map(item => (
                <TableCustomRow
                  key={item.id}
                  item={item}
                  setOpen={setOpen}
                  setOpenCheckin={setOpenCheckin}
                />
              ))
            )}
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            <Paginator
              labelRowsPerPage="Itens por página"
              count={total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'Rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}

export default MessageList;
