import { takeLatest, put, call, select } from "redux-saga/effects";
import { Types } from "../../../ducks/message/create/customers";
import { Types as TypesCreate } from "../../../ducks/message/create/index";
import { Types as TypesCreateGroup } from "../../../ducks/message/create/groups";

import axios from "../../../../services/api";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet() {
  return axios.get(`/customers`);
}

//function apiGetVerifyCustomerCanSendMessage() {
//return axios.get(`/message/customer/verifyCanSendMessage`);
//}

function* getCustomers() {
  try {
    const resp = yield call(apiGet);
    let customers = resp.data;

    const groupsSelecteds = yield select(
      state => state.CreateMessageContacts.contacts
    );

    customers = customers.map(customer => {
      let count = 0;
      groupsSelecteds.map(item => {
        if (item && item.customer_id == customer.id) {
          count++;
        }
      });

      customer.groupsSelected = count;
      return customer;
    });

    yield put({
      type: Types.SUCCESS_MESSAGE_GET_CUSTOMERS,
      payload: resp.data
    });
    const getCurrentUser = yield select(state => state.Main.current_user);

    if (resp.data && typeof resp.data != undefined && resp.data.length === 1) {
      yield put({
        type: Types.CHANGE_MESSAGE_CUSTUMER,
        payload: resp.data[0]
      });

      yield put({
        type: TypesCreateGroup.REQUEST_MESSAGE_GET_GROUP,
        payload: {
          push_notification:
            getCurrentUser.verifyCustomerCanSendMessage.push_notification,
          sms_notification:
            getCurrentUser.verifyCustomerCanSendMessage.sms_notification,
          id: resp.data[0].id
        }
      });
      return yield put({
        type: TypesCreate.CHANGE_PAGE_CREATE_MESSAGE,
        payload: 1
      });
    }
  } catch (error) {
    yield put({
      type: Types.FAILURE_MESSAGE_GET_CUSTOMERS
    });
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_MESSAGE_GET_CUSTOMERS, getCustomers);
}
