import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GroupIcon from "@material-ui/icons/GroupWork";
import MessageIcon from "@material-ui/icons/Message";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Types } from "../../store/ducks/main/index";

export default function MenuAppBar(props) {
  const current_user = useSelector(state => state.Main.current_user);
  const menu_open = useSelector(state => state.Main.menu_open);
  const dispatch = useDispatch();
  return (
    <div>
      <Drawer
        anchor="left"
        open={menu_open}
        onClose={() =>
          dispatch({ type: Types.CHANGE_MENU, payload: !menu_open })
        }
      >
        <div style={{ minWidth: 300 }} />
        <div style={{ position: "absolute", right: 0 }}>
          <IconButton
            onClick={() =>
              dispatch({ type: Types.CHANGE_MENU, payload: !menu_open })
            }
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Typography
          style={{
            textAlign: "left",
            marginLeft: 20,
            marginTop: 20,
            fontWeight: 600,
            fontSize: 18
          }}
          variant="h6"
          component="h5"
        >
          {current_user ? current_user.name.split(" ")[0] : ""}
        </Typography>

        <Typography
          style={{
            textAlign: "left",
            marginLeft: 20,
            marginBottom: 20,
            fontWeight: 400,
            fontSize: 13
          }}
          variant="h6"
          component="h5"
        >
          {current_user ? current_user.email : ""}
        </Typography>

        <Divider />
        <List>
          <Link to="/" style={{ textDecoration: "none", color: "#000" }}>
            <ListItem
              button
              onClick={() =>
                dispatch({ type: Types.CHANGE_MENU, payload: !menu_open })
              }
            >
              <ListItemIcon>
                <MessageIcon />
              </ListItemIcon>
              <ListItemText primary={"Mensagens"} />
            </ListItem>
          </Link>
          <Link to="/grupos" style={{ textDecoration: "none", color: "#000" }}>
            <ListItem
              button
              onClick={() =>
                dispatch({ type: Types.CHANGE_MENU, payload: !menu_open })
              }
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={"Grupos"} />
            </ListItem>
          </Link>

          <Link
            to="/contatos"
            style={{ textDecoration: "none", color: "#000" }}
          >
            <ListItem
              button
              onClick={() =>
                dispatch({ type: Types.CHANGE_MENU, payload: !menu_open })
              }
            >
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary={"Contatos"} />
            </ListItem>
          </Link>

          {/* <ListItem button>
            <ListItemIcon>
              <SwapHorizIcon />
            </ListItemIcon>
            <ListItemText primary={"Configuração dos status das mensagens"} />
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <SmartphoneIcon />
            </ListItemIcon>
            <ListItemText primary={"Operações Ativas"} />
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <TranslateIcon />
            </ListItemIcon>
            <ListItemText primary={"Idiomas Ativas"} />
          </ListItem> */}
        </List>
        <Divider />
      </Drawer>
    </div>
  );
}
