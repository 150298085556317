import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";

class MaterialSearchRedux extends Component {
  constructor(props) {
    super(props);
    const { daysWeekDefault, value } = this.props;

    this.state = {
      checked: !daysWeekDefault.includes(value) ? true : false
    };
  }
  change = event => {
    const {
      props: { disabled }
    } = this.props;
    if (!disabled) {
      this.setState({ checked: !this.state.checked });
      this.props.onClick(this.props.value, this.state.checked);
    }
  };
  componentDidUpdate = async oldProps => {
    const {
      daysWeekDefault,
      value,
      props: { disabled }
    } = this.props;

    if (oldProps.daysWeekDefault !== daysWeekDefault && !disabled) {
      this.setState({
        checked: !daysWeekDefault.includes(value) ? true : false
      });
    }
  };

  renderInput() {
    const { label } = this.props;
    return (
      <FormControl margin="none" fullWidth>
        {this.state.checked ? (
          <Chip
            color="primary"
            label={label}
            onClick={this.change}
            variant="outlined"
          />
        ) : (
          <Chip color="primary" label={label} onClick={this.change} />
        )}
      </FormControl>
    );
  }

  render() {
    return <div>{this.renderInput()}</div>;
  }
}

export default MaterialSearchRedux;
