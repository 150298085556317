import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Divider from "@material-ui/core/Divider";

import Typography from "@material-ui/core/Typography";

import SendMessageBodyPortuguese from "./sendMessageBodyPortuguese";
import SendMessageBodyEnglish from "./sendMessageBodyEnglish";

class sendMessageBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h6"
              className={classes.SendMessageTitle}
            >
              Envio de Mensagem
            </Typography>
            <Divider />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ borderRight: "1px rgba(0, 0, 0, 0.12) solid" }}
          >
            <Tabs
              value={this.state.tabIndex}
              indicatorColor={"primary"}
              textColor={"primary"}
              onChange={(event, newValue) =>
                this.setState({ tabIndex: newValue })
              }
              aria-label="tabAreas"
            >
              <Tab label="Destinatários em PT" value={1} />
              <Tab label="Destinatários em EN" value={2} />
            </Tabs>
            <Divider />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ borderRight: "1px rgba(0, 0, 0, 0.12) solid" }}
          >
            <Typography
              style={{
                backgroundColor: "#FFF",
                fontWeight: "bold",
                marginLeft: "20px",
                fontSize: "20px"
              }}
            >
              EMPRESAS
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              borderRight: "1px rgba(0, 0, 0, 0.12) solid",
              height: "100%"
            }}
          >
            <Typography
              style={{
                backgroundColor: "#FFF",
                fontWeight: "bold",
                marginLeft: "20px",
                fontSize: "20px"
              }}
            >
              GRUPOS
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              style={{
                backgroundColor: "#FFF",
                fontWeight: "bold",
                marginLeft: "20px",
                fontSize: "20px"
              }}
            >
              CONTATOS
            </Typography>
          </Grid>
        </Grid>
        <Divider />

        <Grid container spacing={0}>
          <Grid item xs={12}>
            {this.state.tabIndex === 1 ? (
              <SendMessageBodyPortuguese
                groups={this.props.groups}
                currentCustumer={this.props.currentCustumer}
                loadingGroup={this.props.loadingGroup}
                contacts={this.props.contacts}
                sms_notification={this.props.sms_notification}
                push_notification={this.props.push_notification}
                submitFailed={this.props.submitFailed}
              />
            ) : (
              <SendMessageBodyEnglish
                groups={this.props.groupsEn}
                currentCustumer={this.props.currentCustumer}
                loadingGroup={this.props.loadingGroup}
                contacts={this.props.contactsEn}
                sms_notification={this.props.sms_notificationEn}
                push_notification={this.props.push_notificationEn}
                submitFailed={this.props.submitFailed}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = {
  SendMessageTitle: {
    fontWeight: 400,
    color: "black",
    opacity: 1,
    margin: 10
  },
  button: {
    margin: 5
  }
};

sendMessageBody.propTypes = {
  classes: PropTypes.object.isRequired
};

sendMessageBody = withStyles(styles)(sendMessageBody);
const selector = formValueSelector("MONIT_MESSAGE_CREATE_FORM");

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
const mapStateToProps = state => {
  return {
    incident: state.MonitCreateMessage.incident,
    messageIncidentBodyResquest:
      state.MonitCreateMessage.messageIncidentBodyResquest,
    groups: state.MonitGroupsCreateMessage.data,
    groupsEn: state.MonitGroupsCreateMessage.dataEn,
    loadingGroup: state.MonitGroupsCreateMessage.loading,
    currentCustumer: state.MonitCustomersCreateMessage.customer,
    contacts: state.MonitContactsCreateMessage.data,
    contactsEn: state.MonitContactsCreateMessage.dataEn,
    sms_notificationEn: selector(state, "sms_notificationEn"),
    push_notificationEn: selector(state, "push_notificationEn"),
    sms_notification: selector(state, "sms_notification"),
    push_notification: selector(state, "push_notification")
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(sendMessageBody);
