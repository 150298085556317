import styled from 'styled-components';

import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

export const ListContainer = styled(List)`
  max-width: 85%;
  max-height: 400px;
  position: relative;
  overflow: auto;
  padding: 0;
`;

export const ListChild = styled(ListItem)`
  border-bottom: 1px solid #CCC;

  a {
    margin-right: 20px;
  }
`;

export const NoData = styled(Typography)`
  font-weight: 400;
  color: #333;
  opacity: 0.6;
  text-align: center;
  margin-top: 20px;
`;

export const CursorTitle = styled(Tooltip)`
  margin-right: 10px;
`;

export const InputContainer = styled.div`
  margin: 0;
  width: 100%;

  > div {
    width: 80%;
  }
`;

export const TitleButton = styled(Button)`
  background-color: #FFF;
  border: 0;
  font-weight: bold !important;
  font-size: 20px !important;
`;

export const Loading = styled.div`
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
`;