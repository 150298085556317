import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

export default createMuiTheme({
  typography: {
    useNextVariants: true
  },
  props: {
    // Name of the component ⚛️
    MuiGrid: {
      // The properties to apply
      spacing: 5
    }
  },
  palette: {
    primary: {
      main: "#26408B"
    },
    secondary: {
      main: "#EFEFEF"
    },
    success: {
      main: green[900],
      dark: green[800],
      color: "#EFEFEF"
    },
    error: {
      main: "#840000",
      dark: red[800],
      color: "#EFEFEF"
    },
    danger: {
      main: "#840000",
      dark: red[800],
      color: "#EFEFEF"
    },
    warning: {
      main: "#D9C400"
    }
  }
});
