import styled from 'styled-components';
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

export const classes = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: 'auto'
  }
}));

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#E8E9EC",
    color: "#999999"
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

export const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

export const TableWrapper = styled(TableCell).attrs({
  align: 'center'
})`
`;

export const ProgressCircular = styled(CircularProgress).attrs({
  color: 'primary',
  size: 50
})`
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const MenuChild = styled(MenuItem)`
  opacity: 0.6;
`;

export const Title = styled(Typography)`
  font-weight: 400;
  color: #333;
  opacity: 0.6;
  text-align: center;
  margin-top: 20px;
`;

export const Pagination = styled(TablePagination)`
  display: flex;
`;