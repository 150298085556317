import React, { useState } from 'react';

import PropTypes from 'prop-types';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { LocalPhone } from '@material-ui/icons';

import { NumberContainer, ListContainer } from './styles';

function DetailContacts({ contacts, message }) {
  const [searchInput, setSearchInput] = useState('');

  let timeout = 0;

  const changeInputSearch = () => event => {
    const searchText = event.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearchInput(searchText);
    }, 1000);
  };

  const filter = (contactsData, contactsSearch) => {
    const contactsSearchName = [];
    const contactsSearchGroups = [];
    const contactsSearchPhone = [];

    if (contactsData && contactsData?.length) {
      if (searchInput === '') {
        return contactsData;
      }
      contacts.forEach(contact => {
        if (
          contact.name.toUpperCase().includes(searchInput.toUpperCase().trim())
        ) {
          contactsSearchName.push(contact);
        }

        contact.phones.forEach(phone => {
          if (
            phone.data.toUpperCase().includes(searchInput.toUpperCase().trim())
          ) {
            contactsSearchPhone.push(contact);
          }

          if (
            phone.status
              .toUpperCase()
              .includes(searchInput.toUpperCase().trim())
          ) {
            contactsSearchPhone.push(contact);
          }
        });

        contact.groups.forEach(group => {
          if (
            group.name.toUpperCase().includes(searchInput.toUpperCase().trim())
          ) {
            contactsSearchGroups.push(contact);
          }
        });
      });

      contactsSearch = contactsSearchName.concat(
        contactsSearchGroups,
        contactsSearchPhone
      );

      return Array.from(new Set(contactsSearch));
    }
    return null;
  };

  const statusColor = status => {
    let color = '';
    switch (status) {
      case 'Entregue':
        color = '#1fe036';
        break;
      case 'Enviado':
        color = '#1519d6';
        break;
      case 'Pendente':
        color = '#f0ec1d';
        break;
      case 'Não recebido':
        color = '#1bddf2';
        break;
      case 'Erro ao enviar':
        color = '#eb130c';
        break;
      case 'Bloqueado':
        color = '#000000';
        break;
      default:
        break;
    }

    return (
      <NumberContainer color={color}>
        {' ( '}
        <span />
        {` ${status} )`}
      </NumberContainer>
    );
  };

  let contactsSearch = [];

  let contentContacts = <div />;

  contactsSearch = filter(contacts, contactsSearch);

  if (contacts.length > 0) {
    contentContacts = (
      <ListContainer>
        <ListItem key={0}>
          <IconButton aria-label="Search" type="submit">
            <SearchIcon />
          </IconButton>
          <InputBase
            name="search_input"
            onChange={changeInputSearch()}
            placeholder="Procurar pelo nome do contato , telefone ou grupo"
            id="search_input"
          />
        </ListItem>
        {contactsSearch.map(item => (
          <ListItem key={item.id + 1}>
            <ListItemText
              primary={
                <>
                  <Typography>
                    {item.name}
                    {message.push_notification && (
                      <span>
                        {' ( '}
                        <span />
                        {' Enviado por notificação )'}
                      </span>
                    )}
                  </Typography>
                </>
              }
              secondary={
                <>
                  {item.phones && item.phones.length > 0 && (
                    <span className="MuiTypography-body1 status-color">
                      <LocalPhone />
                      {item.phones.map((phone, index) => (
                        <>
                          <span>{phone.data}</span>
                          {statusColor(phone.status)}
                          {item.phones.length - 1 > index && ', '}
                        </>
                      ))}
                    </span>
                  )}
                  {message.sms_notification && item.phones.length === 0 && (
                    <span className="MuiTypography-body1">
                      <LocalPhone />
                      <span>
                        {item.phonesByPassport &&
                        item.phonesByPassport.length === 0 ? (
                          <> Sem telefone ( </>
                        ) : (
                          <>{` ${item.phonesByPassport[0].name} ( `}</>
                        )}

                        <span />
                        {' Não enviado )'}
                      </span>
                    </span>
                  )}

                  <span className="MuiTypography-body1">
                    Grupos:{' '}
                    {item.groups.map((group, index) => (
                      <span key={group.id} className="group-name">
                        {group.name}
                        {item.groups.length - 1 > index && ', '}
                      </span>
                    ))}
                  </span>
                </>
              }
            />
          </ListItem>
        ))}
      </ListContainer>
    );
  }

  const Contacts = <div>{contentContacts}</div>;

  return <div>{Contacts}</div>;
}

DetailContacts.propTypes = {
  message: PropTypes.oneOfType([PropTypes.object]).isRequired,
  contacts: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default DetailContacts;
