import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

class MaterialTextFieldAreaRedux extends Component {
  renderInput() {
    const {
      input: { onChange, value },
      type,
      multiline,
      variant,
      rows,
      label,
      placeholder,
      limit,
      limitNumber = 120,
      meta: { error }
    } = this.props;
    const hasError = (error || false) !== false;
    let disabled = this.props.disabled || false;
    return (
      <FormControl margin="normal" error={hasError} fullWidth>
        <TextField
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          multiline={multiline}
          variant={variant}
          value={value}
          label={label}
          rows={rows}
          type={type}
          error={hasError || (value.length > limitNumber && limit)}
        />
        {limit && (
          <Typography
            style={{
              marginLeft: 10,
              marginTop: 10,
              fontWeight: "bold",
              fontSize: 14
            }}
          >
            {limitNumber - value.length} caracteres restantes
          </Typography>
        )}

        <FormHelperText id="component-error-text">
          {hasError ? error.join(", ") : ""}
        </FormHelperText>
      </FormControl>
    );
  }

  render() {
    return <div>{this.renderInput()}</div>;
  }
}

export default MaterialTextFieldAreaRedux;
