import React, { Component, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { setCustumer } from "../../../../../store/ducks/monit/message/create/customers";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Typography from "@material-ui/core/Typography";

class MonitMessageCreateCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_input: ""
    };
  }
  ChangeCustomers = id => {
    this.props.setCustumer(id);
  };
  changeInputSearch = () => event => {
    this.setState({ search_input: event.target.value });
  };
  render() {
    const { customers, loadingCustumers } = this.props;
    let customersRender = [];
    if ((customers || false) !== false) {
      customers.forEach(item => {
        if (
          this.state.search_input === "" ||
          item.name
            .toUpperCase()
            .includes(this.state.search_input.toUpperCase())
        ) {
          customersRender.push(
            <ListItem
              button
              onClick={() => this.ChangeCustomers(item.id)}
              key={item.id}
              style={{
                borderBottom: "1px solid #CCCCCCCC"
              }}
            >
              <ListItemText primary={item.name} />
            </ListItem>
          );
        }
      });
    }

    const Customers =
      !loadingCustumers && (customers || false) !== false ? (
        <Fragment>
          <div style={{ margin: 0, width: "100%" }}>
            <IconButton disabled={true} aria-label="Search" type="submit">
              <SearchIcon />
            </IconButton>
            <InputBase
              style={{ width: "80%" }}
              name="search_input"
              onChange={this.changeInputSearch()}
              placeholder="Procurar por nome da empresa ..."
              id="search_input"
            />
          </div>

          <List
            style={{
              maxWidth: "100%",
              maxHeight: 400,
              position: "relative",
              overflow: "auto",
              padding: "0px",
              margin: "0px"
            }}
          >
            {customersRender.length > 0 ? (
              customersRender
            ) : (
              <Typography
                variant="h5"
                component="h4"
                style={{
                  fontWeight: 400,
                  color: "#333333",
                  opacity: 0.6,
                  textAlign: "center",
                  marginTop: 20
                }}
              >
                Nenhuma empresa encontrada.
              </Typography>
            )}
          </List>
        </Fragment>
      ) : (
        <div style={{ textAlign: "center", marginTop: 200, marginBottom: 200 }}>
          <CircularProgress color="primary" size={50} />
        </div>
      );

    return <Fragment>{Customers}</Fragment>;
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCustumer
    },
    dispatch
  );
const mapStateToProps = state => {
  return {
    customers: state.MonitCustomersCreateMessage.data,
    loadingCustumers: state.MonitCustomersCreateMessage.loading
  };
};

// Decorator para Redux Form
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitMessageCreateCustomer);
