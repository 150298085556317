// types
export const Types = {
  REQUEST_GROUP_EDIT_FORM: "REQUEST_GROUP_EDIT_FORM",
  CHANGE_PAGE: "CHANGE_PAGE",
  CHANGE_DAYS_WEEK: "CHANGE_DAYS_WEEK",
  REQUEST_GET_GROUP: "REQUEST_GET_GROUP",
  SUCCESS_GET_GROUP: "SUCCESS_GET_GROUP",
  FAILURE_GET_GROUP: "FAILURE_GET_GROUPS",
  ADD_COVERAGE_AREA: "ADD_COVERAGE_AREA",
  REMOVE_COVERAGE_AREA: "REMOVE_COVERAGE_AREA",
  CHANGE_ZOOM: "CHANGE_ZOOM"
};

const INITIAL_VALUES = {
  loading: false,
  page: 0,
  data: null,
  daysWeek: [],
  coverageAreas: [],
  zoomArea: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_GET_CUSTOMERS:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_GET_GROUP:
      return {
        ...state,
        loading: false,
        data: action.payload
      };

    case Types.CHANGE_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case Types.CHANGE_DAYS_WEEK:
      return {
        ...state,
        daysWeek: action.payload
      };
    case Types.ADD_COVERAGE_AREA:
      return {
        ...state,
        coverageAreas: [...new Array([...action.payload])][0]
      };
    case Types.REMOVE_COVERAGE_AREA:
      return {
        ...state,
        coverageAreas: [...new Array([...action.payload])][0]
      };
    case Types.CHANGE_ZOOM:
      return {
        ...state,
        mapZoom: action.payload
      };
    default:
      return state;
  }
}

export function editGroup(id, parameters) {
  return { type: Types.REQUEST_GROUP_EDIT_FORM, payload: { parameters, id } };
}

export function changePage(page) {
  return { type: Types.CHANGE_PAGE, payload: page };
}

export function changeDaysWeek(days) {
  return { type: Types.CHANGE_DAYS_WEEK, payload: days };
}

export function getGroup(id) {
  return { type: Types.REQUEST_GET_GROUP, payload: id };
}

export function addCoverage(payload) {
  return { type: Types.ADD_COVERAGE_AREA, payload };
}

export function changeZoom(payload) {
  return { type: Types.CHANGE_ZOOM, payload };
}

export function removeCoverage(payload) {
  return { type: Types.REMOVE_COVERAGE_AREA, payload };
}
