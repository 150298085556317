import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputBase from '@material-ui/core/InputBase';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import TooltipMap from '../Tooltip';
import { Types } from '~/store/ducks/message/list';

import {
  TableHeadContainer,
  FilterContainer,
  StyledTableRow,
  IconContainer,
  GridContainer,
  Search,
  Loading,
  LocateIcon,
  ButtonGroup,
  NoData,
} from './styles';

function MessageDetailCheckin({ open, handleClose }) {
  const [searchInput, setSearchInput] = useState('');
  const [sortData, setSortData] = useState([]);
  const [arrow, setArrow] = useState('down');
  const [checkinStatus, setCheckinStatus] = useState('');

  const { messageDetail: message, loadingMessageDetail } = useSelector(
    state => state.MessageList
  );

  const dispatch = useDispatch();

  const onLoad = useCallback(data => {
    setSortData(data);
  }, []);

  useEffect(() => {
    if (message && message.type_message === 'CHECKIN') {
      onLoad(message);
    }
    setCheckinStatus('Todos');
  }, [message, onLoad]);

  const changeInputSearch = () => async event => {
    setSearchInput(event.target.value);
  };

  const handleCloseModal = () => {
    dispatch({
      type: Types.SUCCESS_GET_MESSAGE,
      payload: message,
      onLoad: false,
    });

    handleClose();
  };

  const handleSort = data => {
    let dataSorted = {};

    if (sortData.contacts) {
      dataSorted = sortBy(sortData.contacts, data).map(filter => filter);
    } else {
      dataSorted = sortBy(sortData, data).map(filter => filter);
    }

    if (dataSorted.length === 1) return;

    if (isEqual(dataSorted, sortData)) {
      const reverseSorted = sortBy(sortData, data).reverse();

      const newData = reverseSorted.map(filter => filter);

      setSortData(newData);
      setArrow(arrow === data ? 'none' : data);
    } else {
      setSortData(dataSorted);
      setArrow(data);
    }
  };

  const filterByStatus = data => {
    let filter = {};

    if (message.contacts.length === 1) return;

    if (data === 'Pendente') {
      filter = message.contacts.filter(
        status => status.checkin[0].status === 'Pendente'
      );

      setSortData(filter);
      setCheckinStatus(data);
    } else if (data === 'Confirmado') {
      filter = message.contacts.filter(
        status => status.checkin[0].status === 'Estou bem'
      );
      setSortData(filter);
      setCheckinStatus(data);
    } else {
      filter = message.contacts.map(messageData => messageData);
      setSortData(filter);
      setCheckinStatus(data);
    }

    if (filter.length === 1) setArrow('down');
  };

  const renderArrow = data => {
    return arrow !== data || arrow === 'down' ? (
      <ArrowDownwardIcon />
    ) : (
      <ArrowUpwardIcon />
    );
  };

  const renderButtons = () => {
    return (
      <ButtonGroup>
        {['Pendente', 'Confirmado', 'Todos'].map(filter => {
          return (
            <Button
              key={filter}
              aria-pressed={filter === checkinStatus}
              onClick={() => filterByStatus(filter)}>
              {filter}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  };

  const renderHeader = () => {
    return (
      <TableHeadContainer>
        <TableRow>
          <TableCell>Código</TableCell>
          <TableCell>
            <Button onClick={() => handleSort('name')}>Nome</Button>
            {renderArrow('name')}
          </TableCell>
          <TableCell>
            <Button onClick={() => handleSort('checkin[0].createdAt')}>
              Data de envio
            </Button>
            {renderArrow('checkin[0].createdAt')}
          </TableCell>
          <TableCell>
            <Button onClick={() => handleSort('checkin[0].updatedAt')}>
              Data de resposta
            </Button>
            {renderArrow('checkin[0].updatedAt')}
          </TableCell>
          <TableCell>
            <Button onClick={() => handleSort('checkin[0].status')}>
              Status
            </Button>
            {renderArrow('checkin[0].status')}
          </TableCell>
          <TableCell>Mapa</TableCell>
        </TableRow>
      </TableHeadContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Dialog
        open={open}
        onClose={handleCloseModal}
        onExited={handleCloseModal}
        maxWidth="md"
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Check In</DialogTitle>
        <IconContainer onClick={handleCloseModal}>
          <CloseIcon />
        </IconContainer>
        <Divider />

        <DialogContent>
          <Loading>
            <CircularProgress color="primary" size={50} />
          </Loading>
        </DialogContent>
      </Dialog>
    );
  };

  const renderData = data => {
    const contentData = data.contacts ? data.contacts : data;

    return (
      <Dialog
        open={open}
        onClose={handleCloseModal}
        onExited={handleCloseModal}
        maxWidth="xl"
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Search>
          <DialogTitle id="alert-dialog-title">Check In</DialogTitle>
          <FilterContainer>
            <IconButton aria-label="Search" type="submit">
              <SearchIcon />
            </IconButton>
            <InputBase
              name="search_input"
              onChange={changeInputSearch()}
              placeholder="Procurar pelo nome do contato ..."
              id="search_input"
            />
            {renderButtons()}
          </FilterContainer>
        </Search>

        <IconContainer onClick={handleCloseModal}>
          <CloseIcon />
        </IconContainer>
        <Divider />

        <DialogContent>
          <GridContainer>
            <Grid item xs={12}>
              <Grid container>
                {contentData.length < 1 ? (
                  <NoData>
                    <h2>
                      Não há Checkins{' '}
                      {checkinStatus !== 'Todos' ? `${checkinStatus}s` : ''}
                    </h2>
                  </NoData>
                ) : (
                  <Table>
                    {renderHeader()}
                    <TableBody>
                      {contentData.map(item => (
                        <Fragment key={item.id}>
                          {(searchInput === '' ||
                            item.name
                              .toUpperCase()
                              .includes(searchInput.toUpperCase())) && (
                            <StyledTableRow>
                              <TableCell
                                align="center"
                                component="th"
                                scope="row">
                                {item.id}
                              </TableCell>
                              <TableCell
                                component="th"
                                align="center"
                                scope="row">
                                {item.name}
                              </TableCell>
                              <TableCell
                                component="th"
                                align="center"
                                scope="row">
                                {moment(
                                  item.checkin && item.checkin[0].createdAt
                                ).format('DD/MM/YYYY - HH:mm')}
                              </TableCell>
                              <TableCell
                                component="th"
                                align="center"
                                scope="row">
                                {item.checkin && item.checkin[0].response_at
                                  ? moment(item.checkin[0].response_at).format(
                                      'DD/MM/YYYY - HH:mm'
                                    )
                                  : '-'}
                              </TableCell>
                              <TableCell
                                component="th"
                                align="center"
                                scope="row">
                                {item.checkin &&
                                item.checkin[0].status !== 'Estou bem'
                                  ? 'Pendente'
                                  : 'Confirmado'}
                              </TableCell>
                              <TableCell
                                component="th"
                                align="center"
                                scope="row">
                                {item.checkin && item.checkin[0].location ? (
                                  <TooltipMap
                                    contact_id={item.id}
                                    location={item.checkin[0].location}
                                    message_id={message.id}
                                  />
                                ) : (
                                  <LocateIcon />
                                )}
                              </TableCell>
                            </StyledTableRow>
                          )}
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Grid>
            </Grid>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  return !loadingMessageDetail && sortData
    ? renderData(sortData)
    : renderLoading();
}

MessageDetailCheckin.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MessageDetailCheckin;
