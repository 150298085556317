import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import { Types } from '../../../../ducks/monit/message/create';
import {
  getIncident as getIncidentApi,
  createMessage as createMessageApi,
} from '../../../../../services/monit';
import { startSubmit, stopSubmit, setSubmitSucceeded } from 'redux-form';
import { Types as TypeMessage } from '../../../../ducks/main/message';
import { Messages } from '../../../../../components/alert/messages';

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/
function createCategoryBodyRequest(messageIncidentBodyResquest, incident) {
  switch (incident.type.category.id) {
    case 2: // banco monit  2 -> banco umessenger 1 // segurança
      messageIncidentBodyResquest.category_incident_id = 1;
      break;
    case 1: // banco monit  1 -> banco umessenger 2 // transito
      messageIncidentBodyResquest.category_incident_id = 2;
      break;
    case 7: // banco monit  7 -> banco umessenger 3 // contingencia
      messageIncidentBodyResquest.category_incident_id = 3;
      break;
    default:
      break;
  }
  return messageIncidentBodyResquest;
}

function createCriticalityBodyRequest(messageIncidentBodyResquest) {
  switch (messageIncidentBodyResquest.category_incident_id) {
    case 1:
      messageIncidentBodyResquest.criticality = 3;
      break;
    case 2:
      break;
    case 3:
      messageIncidentBodyResquest.criticality = 3;
      break;
    default:
      break;
  }
  return messageIncidentBodyResquest;
}

function createGroundsBodyRequest(messageIncidentBodyResquest, report) {
  const groundText = {};
  messageIncidentBodyResquest.grounds = [];

  groundText.description = report.description;
  groundText.ground_type_id = 1;

  messageIncidentBodyResquest.grounds.push(groundText);

  if (report.links) {
    report.links.forEach(link => {
      const groundLinks = {};
      if (link.title) {
        groundLinks.description = link.title + ' - ' + link.url;
      } else {
        groundLinks.description = link.url;
      }
      groundLinks.ground_type_id = 2;
      messageIncidentBodyResquest.grounds.push(groundLinks);
    });
  }

  if (report.files) {
    report.files.forEach(file => {
      const groundFiles = {};
      if (file.name) {
        groundFiles.description = file.name + ' - ' + file.url;
      } else {
        groundFiles.description = file.url;
      }
      groundFiles.ground_type_id = 2;
      messageIncidentBodyResquest.grounds.push(groundFiles);
    });
  }

  return messageIncidentBodyResquest;
}

function createStatusGroundsBodyRequest(messageIncidentBodyResquest, incident) {
  let reports = incident.reports;

  if (!reports || reports.length <= 0) {
    messageIncidentBodyResquest.status_id = 1;

    let reportInital = {};

    reportInital.links = incident.links;
    reportInital.files = incident.files;
    reportInital.description = incident.grounding;

    messageIncidentBodyResquest = createGroundsBodyRequest(
      messageIncidentBodyResquest,
      reportInital
    );
  } else {
    let reportsEnded = reports.filter(report => {
      if (report.status === 'ended') {
        return report;
      }
      return false;
    });

    let reportsRemains = reports.filter(report => {
      if (report.status === 'remains') {
        return report;
      }
      return false;
    });

    if (reportsEnded.length > 0) {
      messageIncidentBodyResquest.status_id = 3;

      messageIncidentBodyResquest = createGroundsBodyRequest(
        messageIncidentBodyResquest,
        reportsEnded[0]
      );

      return messageIncidentBodyResquest;
    }

    if (reportsRemains.length > 0) {
      messageIncidentBodyResquest.status_id = 2;

      messageIncidentBodyResquest = createGroundsBodyRequest(
        messageIncidentBodyResquest,
        reportsRemains[reportsRemains.length - 1]
      );

      return messageIncidentBodyResquest;
    }
  }

  return messageIncidentBodyResquest;
}

function apiGet(payload) {
  return getIncidentApi(payload.token, payload.id);
}

function apiCreateMessage(data) {
  return createMessageApi(data.token, data);
}

function createBodyRequestMessage(incident) {
  let messageIncidentBodyResquest = {};

  messageIncidentBodyResquest = createCategoryBodyRequest(
    messageIncidentBodyResquest,
    incident
  );

  messageIncidentBodyResquest = createCriticalityBodyRequest(
    messageIncidentBodyResquest
  );

  messageIncidentBodyResquest.credibility = incident.credibility;

  messageIncidentBodyResquest.external_incident_id = incident.id;

  messageIncidentBodyResquest = createStatusGroundsBodyRequest(
    messageIncidentBodyResquest,
    incident
  );
  return messageIncidentBodyResquest;
}

function* getIncident(action) {
  try {
    yield put({
      type: Types.REQUEST_SET_MESSAGE_INCIDENT_BODY,
    });

    const resp = yield call(apiGet, action.payload);

    yield put({
      type: Types.SUCCESS_MESSAGE_GET_INCIDENT,
      payload: resp.data,
    });

    if (resp.data) {
      let messageIncidentBodyResquest = createBodyRequestMessage(resp.data);

      yield put({
        type: Types.SUCCESS_SET_MESSAGE_INCIDENT_BODY,
        payload: messageIncidentBodyResquest,
      });
    }
  } catch (error) {
    yield put({
      type: Types.FAILURE_MESSAGE_GET_INCIDENT,
      payload: 'Nenhum incidente encontrado',
    });
  }
}
function reformateErrors(errors) {
  const errorsReformat = {
    descriptionEn: errors['message_en.description'],
    sms_notificationEn: errors['message_en.sms_notification'],
    push_notificationEn: errors['message_en.push_notification'],
    description: errors['message_pt.description'],
    sms_notification: errors['message_pt.sms_notification'],
    push_notification: errors['message_pt.push_notification'],
  };

  return errorsReformat;
}

function reformateData(data, incident, groups, groupsEn) {
  let groupsPt = [];
  let groupsEn2 = [];
  groups.forEach(group => {
    let newGroup = {};
    newGroup = group.groupInfo
      ? {
          id: group.id,
          push_notification: group.groupInfo.push_notification,
          sms_notification: group.groupInfo.sms_notification,
          customer_id: group.customer_id,
        }
      : {};
    newGroup.contacts_id = group.users.map(item => {
      return item.id;
    });
    groupsPt.push(newGroup);
  });
  groupsEn.forEach(group => {
    let newGroup = {};
    newGroup = group.groupInfo
      ? {
          id: group.id,
          push_notification: group.groupInfo.push_notification,
          sms_notification: group.groupInfo.sms_notification,
          customer_id: group.customer_id,
        }
      : {};
    newGroup.contacts_id = group.users.map(item => {
      return item.id;
    });
    groupsEn2.push(newGroup);
  });

  const dataReformate = {
    token: data.token,
    message_pt: {
      description: data.description,
      push_notification: data.push_notification,
      sms_notification: data.sms_notification,
      incident: { ...incident, language_id: 1, criticality: data.criticality },
      groups: groupsPt,
    },
    message_en: {
      description: data.descriptionEn,
      push_notification: data.push_notificationEn,
      sms_notification: data.sms_notificationEn,
      incident: { ...incident, language_id: 2, criticality: data.criticality },
      groups: groupsEn2,
    },
  };

  return dataReformate;
}

function* createMessage(action) {
  yield put(startSubmit('MONIT_MESSAGE_CREATE_FORM'));
  const incident = yield select(
    state => state.MonitCreateMessage.messageIncidentBodyResquest
  );
  const groups = yield select(state => state.MonitGroupsCreateMessage.data);
  const groupsEn = yield select(state => state.MonitGroupsCreateMessage.dataEn);
  try {
    yield call(
      apiCreateMessage,
      reformateData(action.payload, incident, groups, groupsEn)
    );
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.CREATE_MESSAGE,
        variant: 'success',
      },
    });
    yield put(stopSubmit('MONIT_MESSAGE_CREATE_FORM'));
    yield put(setSubmitSucceeded('MONIT_MESSAGE_CREATE_FORM'));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 422) {
        yield put(
          stopSubmit(
            'MONIT_MESSAGE_CREATE_FORM',
            reformateErrors(error.response.data.errors)
          )
        );
        yield put({
          type: TypeMessage.MESSAGE,
          payload: {
            message: Messages.ERROR_422,
            variant: 'error',
          },
        });
      } else {
        yield put(stopSubmit('MONIT_MESSAGE_CREATE_FORM'));
        yield put({
          type: TypeMessage.MESSAGE,
          payload: {
            message: Messages.ERROR_500,
            variant: 'error',
          },
        });
      }
    } else {
      yield put(stopSubmit('MONIT_MESSAGE_CREATE_FORM'));
      yield put({
        type: TypeMessage.MESSAGE,
        payload: {
          message: Messages.ERROR_500,
          variant: 'error',
        },
      });
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(Types.REQUEST_MESSAGE_GET_INCIDENT, getIncident),
    takeLatest(Types.REQUEST_MESSAGE_CREATE_INCIDENT, createMessage),
  ]);
}
