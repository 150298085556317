import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

export const PaperContainer = styled(Paper)`
  margin: 50px;
`;

export const PersonalizeContainer = styled.div`
  > div {
    display: flex;
    flex-direction: row;
  }

  h2 {
    color: #333;
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
  }

  div {
    margin-top: 5px;
    margin-left: 5px;
  }

  a {
    cursor: pointer;

    h2 {
      font-size: 13px;
    }
  }
`;

export const DataContainer = styled(Grid)`
  border-right: 1px solid;
  border-bottom: 1px solid;
  padding: 4px;
  border-color: #ccc;
`;

export const GridRow = styled(Grid)`
  margin-top: 20px !important;
  margin-left: 20px !important;

  .MuiFormControlLabel-labelPlacementStart {
    justify-content: flex-end !important;
    margin-left: 0;

    > span {
      margin-left: 5px;
    }
  }

  h3 {
    color: #999999;
    font-size: 13px;
    font-weight: normal;
    padding-top: 10px;
  }

  .row {
    flex-direction: row;
    flex: 1;
    justify-content: stretch;
    align-items: stretch;

    > h4 {
      color: #999;
      font-size: 13px;
      font-weight: bold;
    }

    .MuiFormControl-root {
      label {
        color: #999;
        font-size: 13px;
        font-weight: bold;
      }

      h4 {
        font-size: 15px;
        font-weight: 400;
        padding-top: 30px;
      }

      div {
        display: flex;
        align-items: baseline;

        button {
          cursor: unset;
          color: #40ad44;

          span {
            position: absolute;
            top: -6px;
            left: 10px;
          }
        }
      }
    }
  }
`;

export const GroupTableContainer = styled(Grid)`
  border-bottom: 1px solid;
  padding: 4px;
  border-color: #ccc;
`;

export const FooterContainer = styled(Grid)`
  .MuiGrid-container {
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    button {
      margin-right: 50px;
      width: 120px;
      font-size: 15px;
    }
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px;
  height: 200px;

  .MuiCircularProgress-root {
    margin: ${props => (props.mainPage ? '100px' : '0')};
  }
`;

export const ListGroupContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: auto;
  max-height: 600px;

  h4 {
    font-weight: 400px;
    color: #333;
    opacity: 0.6;
    text-align: center;
    margin-top: 20px;
  }
`;

export const PersonalizeButton = styled(Button)`
  text-transform: none !important;

  &:hover {
    background-color: transparent !important;
  }
`;
