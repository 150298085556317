import React, { Component } from "react";
import Switch from "@material-ui/core/Switch";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class MaterialInputRedux extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.input.value || false
    };
  }
  change = event => {
    this.setState({ checked: !this.state.checked });
  };

  componentDidUpdate(oldProps) {
    if (oldProps.input.value !== this.props.input.value) {
      this.setState({ checked: this.props.input.value });
    }
  }

  renderInput() {
    const {
      meta: { error },
      inputLabel,
      input,
      ActiveLabel,
      DesibleLabel
    } = this.props;
    let label = null;
    let disabled = this.props.disabled || false;
    const hasError = (error || false) !== false;
    //console.log(error, "teste", hasError);
    if (!inputLabel) {
      this.state.checked ? (label = ActiveLabel) : (label = DesibleLabel);
    } else {
      label = inputLabel;
    }

    return (
      <FormControl margin="normal" fullWidth={true} error={hasError}>
        <FormControlLabel style={{
          display: 'flex',
          justifyContent: 'space-between' }}
          disabled={disabled}
          control={
            <Switch
              {...input}
              checked={this.state.checked || false}
              onClick={this.change}
              color="primary"
              inputProps={{ "aria-label": inputLabel }}
            />
          }
          labelPlacement="start"
          label={label}
        />
        <FormHelperText id="component-error-text">
          {Array.isArray(error) ? error.join(", ") : error}
        </FormHelperText>
      </FormControl>
    );
  }

  render() {
    return <div>{this.renderInput()}</div>;
  }
}

export default MaterialInputRedux;
