import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";

import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import * as ContactDeleteAction from "../../../../store/ducks/contact/deleteRestore";

import { GridContainer } from "./styles";

function Contact({ history, keyContact }) {
  const { contacts } = useSelector(state => state.ListContact);
  const dispatch = useDispatch();
  
  const contact = contacts[keyContact];
  const phoneQty = useMemo(() => contact.phones.length, [contact]);

  let status_active = true;
  let cellphone = contact.phones.find(phone => phone.type === 1);

  if (!cellphone) {
    cellphone = "";
  }

  if (contact.contact) {
    status_active = contact.contact.status_active;
  }

  return (
    <GridContainer
      token={contact.contact && contact.contact.notification_token ? 1 : 0}
    >
      <Grid item xs={9}>
        <Typography className="usuario">{contact.name}</Typography>
        {contact.contact && contact.contact.notification_token && (
          <Tooltip title="Logado no Aplicativo">
            <div>
              <IconButton className="online-icon">
                <FiberManualRecordIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </Grid>

      <Grid item xs={3}>
        <Tooltip title="Editar contato">
          <IconButton
            className="edit-button"
            key="edit"
            aria-label="Edit"
            color="inherit"
            onClick={() => history.push(`/contato/editar/${contact.id}`)}
          >
            <EditIcon className="edit" color="primary" />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid className="contact-phone" item xs={9}>
        <Typography>
          {phoneQty > 0 ? (
            <span>
              {contact.phones.map((phone, key) => (
                <span key={phone.id}>{phone.name}</span>
              ))}
            </span>
          ) : (
            "Sem Celular"
          )}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Tooltip title={status_active ? "Desativar contato" : "Ativar contato"}>
          <Switch
            checked={status_active}
            onChange={() => {
              dispatch(
                ContactDeleteAction.deleteRestoreContact(
                  contact.id,
                  status_active
                )
              );
            }}
            value={true}
            color="primary"
            inputProps={{ "aria-label": "status" }}
          />
        </Tooltip>
      </Grid>
    </GridContainer>
  );
}

Contact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  keyContact: PropTypes.number.isRequired
};

export default Contact;
