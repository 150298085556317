import React from 'react'

import Tooltip from "@material-ui/core/Tooltip";
import NotificationIcon from "@material-ui/icons/NotificationImportant";
import SmsIcon from "@material-ui/icons/Sms";
import MyLocationIcon from "@material-ui/icons/MyLocation";

import { ContainerIcons } from './styles';


export const receiveOptions = item => {
  const forceLocation =
    item.type_message === "CHECKIN" && item.force_location ? (
      <Tooltip
        title="Localização forçada"
        aria-label="force_location"
        fontSize="small"
      >
        <MyLocationIcon fontSize="small" />
      </Tooltip>
    ) : (
      ""
    );
  if (item.push_notification && item.sms_notification) {
    return (
      <ContainerIcons>
        <Tooltip
          title="Enviado por notificação"
          aria-label="receive notification"
          fontSize="small"
        >
          <NotificationIcon fontSize="small" />
        </Tooltip>
        <Tooltip
          title="Enviado por SMS"
          aria-label="receive sms"
          fontSize="small"
        >
          <SmsIcon fontSize="small" />
        </Tooltip>
        {forceLocation}
      </ContainerIcons>
    );
  } else if (item.sms_notification) {
    return (
      <>
        <Tooltip
          title="Enviado por SMS"
          aria-label="receive sms"
          fontSize="small"
        >
          <SmsIcon fontSize="small" />
        </Tooltip>
        {forceLocation}
      </>
    );
  } else {
    return (
      <>
        <Tooltip
          title="Enviado por notificação"
          aria-label="receive notification"
          fontSize="small"
        >
          <NotificationIcon fontSize="small" />
        </Tooltip>
        {forceLocation}
      </>
    );
  }
};