import React, { Component } from "react";
import Login from ".";

import { loginSSO } from "../../store/ducks/main";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class LoginSSO extends Component {

  componentDidMount = () => {
      
    const { location, history, loginSSO } = this.props;

    const query = new URLSearchParams(location.search);

    if (!query.has("sso")) {
      history.push("/login");
    } else if (query.has("sso") && query.has("access_token")) {
      loginSSO(query.get("sso"), query.get("access_token"));
    } else if (query.has("sso")) {
      window.location.href = `${
        process.env.REACT_APP_SSO_URL
      }/login?sso=${query.get("sso")}&redirect=${process.env.REACT_APP_URL_APP}/login/sso`;
    }
  };

  render() {
    return <Login sso={true} />;
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginSSO }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginSSO);

// const query = new URLSearchParams(window.location.search);

// if(!query.has('sso') && !query.has('access_token')) {

// } else if(query.has('sso') && !query.has('access_token')) {
//     window.open(`https://www.grupounicad.com.br/sso/login?sso=${query.get('sso')}&redirect=http://localhost:3001`, '_self');
// }
