import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';

import {
  getIncident,
  Types,
} from '../../../../store/ducks/monit/message/create';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalConfirmation from './modalConfirmation';
import DetailIncident from './detailIncident';
import SendMessageBody from './sendMessageBody';
import { reduxForm, formValueSelector, submit } from 'redux-form';
import { Divider } from '@material-ui/core';
import qs from 'qs';
import { Fab } from '@material-ui/core';
import {
  getCustomers,
  setCustumer,
} from '../../../../store/ducks/monit/message/create/customers';
import {
  getContacts,
  getContactsEn,
} from '../../../../store/ducks/monit/message/create/contacts';
import ModalConfirmationLocation from './modalConfirmationLocation';

import { getGroups } from '../../../../store/ducks/monit/message/create/groups';
let Token = null;
class MonitMessageCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onload: false,
      modalConfirmation: false,
    };
    Token = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).token;
  }

  handleSubmit = () => {
    this.props.dispatch(this.props.submitForm('MONIT_MESSAGE_CREATE_FORM'));
    this.setState({ modalConfirmation: false });
  };
  componentDidMount = async () => {
    await this.props.getIncident(
      this.props.match.params.incidentId,
      qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token
    );
    await this.props.getCustomers(
      qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token
    );
  };
  componentDidUpdate = async oldProps => {
    if (
      this.props.incident &&
      this.props.messageIncidentBodyResquest &&
      this.props.customers &&
      !this.state.onload
    ) {
      this.setState({ onload: true });

      let payload = null;
      payload = await {
        push_notification: this.props.push_notification,
        sms_notification: this.props.sms_notification,
        push_notificationEn: this.props.push_notificationEn,
        sms_notificationEn: this.props.sms_notificationEn,
        incident: this.props.incident,
        category_incident_id: this.props.messageIncidentBodyResquest,
        criticality: this.props.criticality,
        tokenAuthorization: qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).token,
      };
      await this.props.getGroups(payload);
      await this.props.getContacts([]);
      await this.props.getContactsEn([]);
    }

    if (
      this.state.onload &&
      (oldProps.push_notification !== this.props.push_notification ||
        oldProps.sms_notification !== this.props.sms_notification)
    ) {
      let payload = null;
      payload = await {
        push_notification: this.props.push_notification,
        sms_notification: this.props.sms_notification,
        push_notificationEn: this.props.push_notificationEn,
        sms_notificationEn: this.props.sms_notificationEn,
        incident: this.props.incident,
        category_incident_id: this.props.messageIncidentBodyResquest,
        criticality: this.props.criticality,
        tokenAuthorization: qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).token,
      };
      await this.props.getGroups(payload);
      this.props.setCustumer(null);
      await this.props.getContacts([]);
      await this.props.getContactsEn([]);
    }

    if (
      this.state.onload &&
      (oldProps.push_notificationEn !== this.props.push_notificationEn ||
        oldProps.sms_notificationEn !== this.props.sms_notificationEn)
    ) {
      let payload = null;
      payload = await {
        push_notification: this.props.push_notification,
        sms_notification: this.props.sms_notification,
        push_notificationEn: this.props.push_notificationEn,
        sms_notificationEn: this.props.sms_notificationEn,
        incident: this.props.incident,
        category_incident_id: this.props.messageIncidentBodyResquest,
        criticality: this.props.criticality,
        tokenAuthorization: qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).token,
      };
      await this.props.getGroups(payload);
      this.props.setCustumer(null);
      await this.props.getContacts([]);
      await this.props.getContactsEn([]);
    }

    if (
      this.state.onload &&
      (oldProps.criticality !== this.props.criticality ||
        oldProps.criticality !== this.props.criticality)
    ) {
      let payload = null;
      payload = await {
        push_notification: this.props.push_notification,
        sms_notification: this.props.sms_notification,
        push_notificationEn: this.props.push_notificationEn,
        sms_notificationEn: this.props.sms_notificationEn,
        incident: this.props.incident,
        category_incident_id: this.props.messageIncidentBodyResquest,
        criticality: this.props.criticality,
        tokenAuthorization: qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).token,
      };
      await this.props.getGroups(payload);
      this.props.setCustumer(null);
      await this.props.getContacts([]);
      await this.props.getContactsEn([]);
    }
  };
  renderLoading() {
    return (
      <Fragment>
        <div
          style={{
            minHeight: 400,
            minWidth: 1200,
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            display: 'flex',
          }}>
          <CircularProgress color="primary" size={50} />
        </div>
      </Fragment>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <Fragment>
        <div
          style={{
            minHeight: 400,
            minWidth: 1200,
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            display: 'flex',
          }}>
          <Typography
            variant="h3"
            component="h3"
            style={{
              fontWeight: 400,
              color: '#333333',
              opacity: 0.6,
              textAlign: 'center',
              marginTop: 20,
            }}>
            {error}
          </Typography>
        </div>
      </Fragment>
    );
  }

  render() {
    const { loading, error } = this.props;
    if (loading) {
      return this.renderLoading();
    } else {
      if (error) {
        return this.renderError();
      } else {
        return (
          <Fragment>
            {this.props.submitSucceeded && !this.props.submitting && (
              <ModalConfirmation
                customers={this.props.customers}
                groups={this.props.groups}
              />
            )}

            {this.state.modalConfirmation && (
              <ModalConfirmationLocation
                location={this.props.incident}
                closeModal={() => this.setState({ modalConfirmation: false })}
                confirmSend={() => this.handleSubmit()}
              />
            )}
            <form>
              <Paper
                elevation={5}
                style={{
                  margin: 20,
                  marginLeft: 25,
                  marginRight: 25,
                  minHeight: 200,
                }}>
                {!this.props.submitSucceeded && !this.props.submitting && (
                  <DetailIncident
                    incident={this.props.incident}
                    messageIncidentBodyResquest={
                      this.props.messageIncidentBodyResquest
                    }
                  />
                )}
              </Paper>

              <Paper
                elevation={5}
                style={{
                  margin: 20,
                  marginLeft: 25,
                  marginRight: 25,
                  minHeight: 400,
                  minWidth: 1200,
                }}>
                {!this.props.submitting &&
                  !this.props.loadingGroup &&
                  !this.props.submitSucceeded && (
                    <Fragment>
                      <SendMessageBody submitFailed={this.props.submitFailed} />
                      <Divider />
                      <div
                        style={{
                          backgroundColor: '#FFF',
                          marginTop: 50,
                          marginBottom: 50,
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}>
                        <Fab
                          variant="extended"
                          size="small"
                          onClick={() =>
                            this.setState({ modalConfirmation: true })
                          }
                          color="primary"
                          aria-label="Save"
                          disabled={
                            this.props.submitting || this.props.loadingGroup
                          }
                          style={{
                            width: 120,
                            fontSize: 15,
                            marginBottom: 20,
                            marginRight: 20,
                          }}>
                          Enviar
                        </Fab>
                      </div>
                    </Fragment>
                  )}
                {(this.props.submitting || this.props.loadingGroup) &&
                  this.renderLoading()}
              </Paper>
            </form>
          </Fragment>
        );
      }
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getIncident,
      getCustomers,
      getGroups,
      setCustumer,
      getContacts,
      getContactsEn,
      submitForm: submit,
    },
    dispatch
  );
const selector = formValueSelector('MONIT_MESSAGE_CREATE_FORM');

const mapStateToProps = state => {
  let initialValues = null;
  if (
    state.MonitCreateMessage.incident &&
    state.MonitCreateMessage.messageIncidentBodyResquest
  ) {
    let textEN = '';
    let textPT = '';

    if (state.MonitCreateMessage.messageIncidentBodyResquest.status_id === 2) {
      textPT = ' - Permanece';
      textEN = ' - Remains';
    }

    if (state.MonitCreateMessage.messageIncidentBodyResquest.status_id === 3) {
      textPT = ' - Normalizado';
      textEN = ' - Normalized';
    }
    initialValues = {
      descriptionEn: state.MonitCreateMessage.incident.descriptionEn + textEN,
      sms_notificationEn: true,
      push_notificationEn: true,
      description: state.MonitCreateMessage.incident.description + textPT,
      sms_notification: true,
      push_notification: true,
      criticality: 3,
    };
  }
  return initialValues
    ? {
        loading: state.MonitCreateMessage.loading,
        error: state.MonitCreateMessage.error,
        customers: state.MonitCustomersCreateMessage.data,
        groups: state.MonitGroupsCreateMessage.data,
        incident: state.MonitCreateMessage.incident,
        messageIncidentBodyResquest:
          state.MonitCreateMessage.messageIncidentBodyResquest,
        initialValues,
        criticality: selector(state, 'criticality'),
        sms_notificationEn: selector(state, 'sms_notificationEn'),
        push_notificationEn: selector(state, 'push_notificationEn'),
        sms_notification: selector(state, 'sms_notification'),
        push_notification: selector(state, 'push_notification'),
        loadingGroup: state.MonitGroupsCreateMessage.loading,
      }
    : {
        loadingGroup: state.MonitGroupsCreateMessage.loading,
        loading: state.MonitCreateMessage.loading,
        customers: state.MonitCustomersCreateMessage.data,
        groups: state.MonitGroupsCreateMessage.data,
        error: state.MonitCreateMessage.error,
        incident: state.MonitCreateMessage.incident,
        messageIncidentBodyResquest:
          state.MonitCreateMessage.messageIncidentBodyResquest,
      };
};

const onSubmit = (values, dispatch) => {
  dispatch({
    type: Types.REQUEST_MESSAGE_CREATE_INCIDENT,
    payload: {
      ...values,
      token: Token,
    },
  });
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'MONIT_MESSAGE_CREATE_FORM',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit,
  })(MonitMessageCreate)
);
