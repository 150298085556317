import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [open, setOpen] = React.useState(true);
  const [page, setPage] = React.useState(0);
  let contacts = 0;
  props.groups.forEach(group => {
    contacts += group.users.length;
  });

  function handleOk() {
    setPage(1);
  }

  function handleClose() {
    setOpen(false);
  }

  function textComponent(title, content, length = false) {
    return (
      <Fragment>
        <Typography
          variant="h2"
          component="h3"
          style={{
            fontWeight: "bold",
            color: "#000000",
            fontSize: 16
          }}
        >
          {title}
        </Typography>
        {!length ? (
          content.map(item => (
            <Typography
              variant="h2"
              component="h3"
              style={{
                fontWeight: "normal",
                color: "#000000",
                marginTop: 10,
                marginBottom: 10,
                fontSize: 14,
                textAlign: "center"
              }}
            >
              {item.name}
            </Typography>
          ))
        ) : (
          <Typography
            variant="h2"
            component="h3"
            style={{
              fontWeight: "normal",
              color: "#000000",
              marginTop: 10,
              marginBottom: 10,
              fontSize: 14,
              textAlign: "center"
            }}
          >
            {Array.isArray(content)
              ? content.length + " " + title.split(" ")[0]
              : content + " " + title.split(" ")[0]}
          </Typography>
        )}
      </Fragment>
    );
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {page === 0 ? "Mensagem" : "Detalhes da mensagem"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {page === 0 ? (
              "Mensagem enviada com sucesso !"
            ) : (
              <Fragment>
                {textComponent("Cliente(es) Remetent(es)", props.customers)}
                {textComponent("Grupo(os) Remetente(es)", props.groups, true)}
                {textComponent("Contat(os) Remetente(es)", contacts, true)}
              </Fragment>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk} color="primary">
            Detalhes
          </Button>
          <Button onClick={handleClose} color="primary">
            Concluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
