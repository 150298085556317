import React from "react";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

export default props => (
  <Paper style={{ padding: 30 }}>
    <Typography variant="h5">404 - Página não encontrada</Typography>
  </Paper>
);
