import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import axios from "../../../../../services/api";
import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";

import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import SearchIcon from "@material-ui/icons/Search";

import { 
  PaperContainer, 
  Input, 
  ListContainer, 
  ListItemWrapper, 
  Loading 
} from './styles';

function PlaceAutocomplete({ change, action }) {
  const [searchBox, setSearchBox] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const GroupAction =
    action === 'create'
      ? require('../../../../../store/ducks/group/create')
      : require('../../../../../store/ducks/group/edit');

  const coverageAreasSelected = useSelector(state => 
    action === 'create' 
      ? state.GroupCreate.coverageAreas : state.GroupEdit.coverageAreas
    );
    
  const dispatch = useDispatch();

  const checkValue = id => {
    let value = false;
    coverageAreasSelected.forEach(item => {
      if (item.id === id) value = true;
    });

    return value;
  };

  const onSearchBoxMounted = ref => {
    setSearchBox(ref);
  };

  const changeCoverage = item => event => {
    let data = coverageAreasSelected;
    if (event.target.checked) {
      data.push(item);

      dispatch(GroupAction.changeZoom(item.polygon.coordinates));
      dispatch(GroupAction.addCoverage(data));
    } else {
      const result = data.filter(obj => obj.id !== item.id);
      dispatch(GroupAction.removeCoverage(result));
    }
      change(item.name);    
  };

  const onPlacesChanged = async () => {
    if (searchBox.getPlaces()) {
      setLoading(true);
      const places = searchBox.getPlaces();
      let api = await axios.post(`/areas`, {
        types: ["cities", "countries", "states", "neighborhoods"],
        contains: {
          lng: places[0].geometry.location.lng(),
          lat: places[0].geometry.location.lat()
        }
      });
      if (api.data) {
        setLoading(false);
        setData(api.data)
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <StandaloneSearchBox
        ref={onSearchBoxMounted}
        onPlacesChanged={onPlacesChanged}
      >
        <PaperContainer>
          <IconButton disabled aria-label="Search">
            <SearchIcon />
          </IconButton>
          <Input
            placeholder="Pesquise a Localização desejada e pressione enter"
          />
        </PaperContainer>
      </StandaloneSearchBox>
      {!loading ? (
        <ListContainer>
          {data.map(item => (
            <ListItemWrapper key={item.id}>
              <ListItemText primary={item.name} />
              <Switch
                checked={checkValue(item.id)}
                onClick={changeCoverage(item)}
                color="primary"
              />
            </ListItemWrapper>
          ))}
        </ListContainer>
      ) : (
        <Loading>
          <CircularProgress color="primary" size={50} />
        </Loading>
      )}
    </>
  );
}

PlaceAutocomplete.defaultProps = {
  action: '',
};

PlaceAutocomplete.propTypes = {
  change: PropTypes.func.isRequired,
  action: PropTypes.string,
};

export default PlaceAutocomplete;
