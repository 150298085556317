import React from "react";
import ReactDOM from "react-dom";
import App from "./components";

import Store from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import theme from "./theme.js";

import { MuiThemeProvider } from "@material-ui/core/styles";

import { delayConfiguration } from "pusher-redux";

const options = {
  // options are... optional
  authEndpoint: process.env.REACT_APP_URL_API + "pusher/auth",
  cluster: "us2",
  useTLS: true
};

delayConfiguration(Store, process.env.REACT_APP_PUSHER_KEY, options);

ReactDOM.render(
  <Provider store={Store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
