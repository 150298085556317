import { all, fork } from "redux-saga/effects";

import sagaLogin from "./main/login";
import sagaMain from "./main/index";
import sagaMessage from "./main/message";
import sagaFormContact from "./contact/form";
import sagaCreateContact from "./contact/create";
import sagaCreateGroup from "./group/create";
import sagaUpdateContact from "./contact/update";
import sagaCustomers from "./customers";
import sagaListContact from "./contact/list";
import sagaDeleteRestoreContact from "./contact/deleteRestore";
import sagaAssociationGroup from "./contact/associationGroup";
import sagaListGroups from "./group/list";
import sagaListGroupsContacts from "./group/listContacts";
import sagaAssociationUsersGroup from "./group/associationUsers";
import sagaEditGroup from "./group/edit";
import sagaDeleteGroup from "./group/delete";
import sagaListMessages from "./message/list";
import sagaMessageCreateCustomer from "./message/create/customers";
import sagaMessageCreateGroup from "./message/create/groups";
import sagaMessageCreateContact from "./message/create/contacts";
import sagaMessageCreate from "./message/create/index";
import sagaCoverageArea from "./coverageArea/index";
import sagaMonitMessageCreate from "./monit/message/create";
import sagaCustomersMonitMessageCreate from "./monit/message/create/customers";
import sagaGroupsMonitMessageCreate from "./monit/message/create/groups";
import sagaCheckInCustomers from "./check-in/customers";
import sagaCheckInGroups from "./check-in/groups";
import sagaCheckInContacts from "./check-in/contacts";
import sagaCheckIn from "./check-in";
import sagaCheckInPublic from "./check-in/public";

export default function* rootSaga() {
  yield all([
    fork(sagaLogin),
    fork(sagaMain),
    fork(sagaFormContact),
    fork(sagaCreateContact),
    fork(sagaCustomers),
    fork(sagaListContact),
    fork(sagaDeleteRestoreContact),
    fork(sagaMessage),
    fork(sagaUpdateContact),
    fork(sagaCreateGroup),
    fork(sagaAssociationGroup),
    fork(sagaListGroups),
    fork(sagaListGroupsContacts),
    fork(sagaAssociationUsersGroup),
    fork(sagaEditGroup),
    fork(sagaDeleteGroup),
    fork(sagaListMessages),
    fork(sagaMessageCreateCustomer),
    fork(sagaMessageCreateGroup),
    fork(sagaMessageCreateContact),
    fork(sagaMessageCreate),
    fork(sagaCoverageArea),
    fork(sagaMonitMessageCreate),
    fork(sagaCustomersMonitMessageCreate),
    fork(sagaGroupsMonitMessageCreate),
    fork(sagaCheckInCustomers),
    fork(sagaCheckInGroups),
    fork(sagaCheckInContacts),
    fork(sagaCheckIn),
    fork(sagaCheckInPublic)
  ]);
}
