import styled from 'styled-components';

import Chip from '@material-ui/core/Chip';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

export const Loading = styled.tbody`
  th {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
`;

export const NoMessages = styled(TableCell)`
  font-size: 24px !important;
  height: 60px;
  text-align: center !important;
`;

export const Paginator = styled(TablePagination).attrs({
  rowsPerPageOptions: [25, 50, 100, 200],
})`
  display: flex !important;
`;

export const MessageIcon = styled(Chip)`
  font-weight: bold;
  background-color: #fff;

  svg {
    color: ${props => props.checkin && '#fe5722'};
  }
`;
