import React, { Component, Fragment } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import { Field } from "redux-form";
import MaterialInputSwitchRedux from "./switch";

class MaterialArraySwitchRedux extends Component {
  renderForm() {
    const {
      meta: { error, submitFailed },
      fields,
      disabled
    } = this.props;
    const fieldsRender = fields.map((field, index) => (
      <div
        key={field + index}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 10,
          justifyContent: "space-between"
        }}
      >
        <Typography
          style={{
            color: "#333333",
            fontSize: "13px",
            fontWeight: "normal",
            paddingTop: 25
          }}
          variant="h4"
          component="h3"
        >
          {fields.get(index).label}
        </Typography>
        <Field
          name={`${field}.value`}
          value={false}
          ActiveLabel=""
          DesibleLabel=""
          component={MaterialInputSwitchRedux}
          autoFocus
          props={{
            disabled
          }}
        />
      </div>
    ));
    return (
      <Fragment>
        {fieldsRender}
        {submitFailed && (
          <FormHelperText
            style={{
              color: "#840000",
              textAlign: "center",
              paddingBottom: 20
            }}
            id="component-error-text"
          >
            {Array.isArray(error) ? error.join(", ") : error}
          </FormHelperText>
        )}
      </Fragment>
    );
  }

  render() {
    return <div>{this.renderForm()}</div>;
  }
}

export default MaterialArraySwitchRedux;
