import { takeLatest, put, call, all, select } from "redux-saga/effects";
import { Types } from "../../../ducks/message/create/groups";
import axios from "../../../../services/api";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(payload, customer = null, type_message) {
  let customer_id = payload.id ? payload.id : null;

  if (!customer_id) customer_id = customer;
  let requestUrl = `/groupsSendMessage?type=${type_message}&push_notification=${payload.push_notification}&sms_notification=${payload.sms_notification}&status=1&customer_id=${customer_id}&application_id=${process.env.REACT_APP_APPLICATION_ID}`;

  return axios.get(requestUrl);
}

function* getGroups(action) {
  try {
    const customer_id = yield select(
      state => state.CreateMessageCustomer.customer
    );
    const type_message = yield select(state => state.MessageList.typeCreate);

    const resp = yield call(
      apiGet,
      action.payload,
      customer_id ? customer_id.id : null,
      type_message
    );

    yield put({
      type: Types.SUCCESS_MESSAGE_GET_GROUP,
      payload: resp.data
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_MESSAGE_GET_GROUP
    });
  }
}

export default function* root() {
  yield all([takeLatest(Types.REQUEST_MESSAGE_GET_GROUP, getGroups)]);
}
