import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

export const GridContainer = styled(Grid).attrs({
  container: true,
  spacing: 0,
})`

  font-size: 14px;

  .MuiGrid-grid-xs-9 {
    display: flex;
    align-items: baseline;
  } 

  .usuario {
    margin-left: 10px;
    margin-top: ${props => props.token ? '0' : '12px'};
    font-weight: bold;
    font-size: 14px;
  }

  .online-icon {
    color: #40ad44;
    cursor: unset !important;
  }

  .edit-button {
    bottom: 10px;
    margin-top: 10px;
  }
  
  .contact-phone {
    opacity: 0.5;
  }

  .contact-phone .MuiTypography-body1 {
    margin-left: 10px;
    font-size: 13px;
    margin-top: 8px;
  }
`;
