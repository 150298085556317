import { takeLatest, put, call, all, select } from "redux-saga/effects";
import { Types } from "../../ducks/check-in/contacts";
import axios from "../../../services/api";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(group) {
  return axios.get(`/group/${group}/contacts?status=true`);
}

function* getContacts(action) {
  try {
    const resp = yield call(apiGet, action.payload);
    resp.data.users.forEach(item => {
      item.label = item.name;
      //  item.value = false;
    });
    let group_select = yield select(state => state.CheckInGroups.data);
    group_select = group_select.filter(item => item.id === resp.data.id);
    yield put({
      type: Types.SUCCESS_CHECKIN_GET_CONTACT,
      payload: group_select[0]
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_CHECKIN_GET_CONTACT
    });
  }
}

function* changeContact(action) {
  try {
    const empty = Array.isArray(action.payload);
    if (empty)
      return yield put({
        type: Types.CHANGE_CHECKIN_CONTACT,
        payload: []
      });

    let contacts = yield select(state => state.CheckInContacts.contacts);

    let users =
      contacts[action.payload.group.id] &&
      contacts[action.payload.group.id].users
        ? contacts[action.payload.group.id].users
        : [];
    if (action.payload.setAll) {
      users = action.payload.checked ? action.payload.group.users : [];
    } else {
      if (action.payload.checked) users.push(action.payload.contact);
      else users = users.filter(item => item.id !== action.payload.contact.id);
    }
    contacts[action.payload.group.id] = {
      ...action.payload.group,
      users
    };
    yield put({ type: Types.CHANGE_CHECKIN_CONTACT, payload: contacts });
  } catch (error) {}
}

export default function* root() {
  yield all([
    takeLatest(Types.REQUEST_CHECKIN_GET_CONTACT, getContacts),
    takeLatest(Types.REQUEST_CHECKIN_CONTACT, changeContact)
  ]);
}
