import { takeLatest, put, call } from "redux-saga/effects";
import { Types } from "../../../../ducks/monit/message/create/customers";

import { getCustomers as getCustomersApi } from "../../../../../services/monit";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(token) {
  return getCustomersApi(token);
}

function* getCustomers(action) {
  try {
    const resp = yield call(apiGet, action.payload);

    yield put({
      type: Types.SUCCESS_MESSAGE_GET_CUSTOMERS_INCIDENT,
      payload: resp.data
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_MESSAGE_GET_CUSTOMERS_INCIDENT
    });
  }
}

export default function* root() {
  yield takeLatest(Types.REQUENST_MESSAGE_GET_CUSTOMERS_INCIDENT, getCustomers);
}
