import { takeLatest, put, call, select } from 'redux-saga/effects';
import { reset, startSubmit, stopSubmit } from 'redux-form';
import { Types, setPage, setModalCheckin } from '~/store/ducks/check-in';
import { Types as TypeMessage } from '~/store/ducks/main/message';

import { changeContactSelecteds } from '~/store/ducks/check-in/contacts';

import axios from '~/services/api';
import { Messages } from '~/components/alert/messages';

import { formatGroupsCorporate } from '~/utils/form';

const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
};

const apiCreate = (formData, groups) => {
  return axios.post(`/message/checkin`, {
    ...formData,
    groups: formatGroupsCorporate(groups).filter(distinct),
  });
};

function* createMessage(action) {
  try {
    yield put(startSubmit('CHECK_IN_FORM'));
    const contactSelecteds = yield select(
      state => state.CheckInContacts.contacts
    );

    yield call(apiCreate, action.payload, contactSelecteds);

    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: 'Mensagem enviada com sucesso.',
        variant: 'success',
      },
    });

    yield put(stopSubmit('CHECK_IN_FORM'));
    yield put(setPage(0));
    yield put(setModalCheckin(false));
    yield put(reset('CHECK_IN_FORM'));
    yield put(changeContactSelecteds([]));
  } catch (err) {
    const messageError = err.response;
    let messageAlert = null;

    if (
      messageError &&
      Object.prototype.hasOwnProperty.call(messageError.data, 'errors')
    ) {
      if (messageError.status === 422) {
        messageAlert = Messages.REQUIRED_FIELDS;
        if (messageError.data.errors) {
          const errorContacts = messageError.data.errors.contacts_id
            ? 'Algum contato precisa estar marcado'
            : '';
          yield put(
            stopSubmit('CHECK_IN_FORM', {
              ...messageError.data.errors,
              _error: errorContacts,
            })
          );
        }
      }
    }

    if (messageAlert) {
      yield put({
        type: TypeMessage.MESSAGE,
        payload: { message: messageAlert, variant: 'error' },
      });
    } else {
      yield put({
        type: TypeMessage.MESSAGE,
        payload: { message: Messages.ERROR_422, variant: 'error' },
      });
      yield put(stopSubmit('CHECK_IN_FORM'));
    }
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_CREATE_CHECKIN, createMessage);
}
