import styled from 'styled-components';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";

export const ListContainer = styled(List)`
  max-width: 100%;
  max-height: 400px;
  position: relative;
  overflow: auto;
  padding: 0px;
  margin: 0px;

  h4 {
    font-weight: 400;
    color: #333;
    opacity: 0.6;
    text-align: center;
    margin-top: 20px;
  }
`;

export const ListChild = styled(ListItem)`
  border-bottom: 1px solid #CCC;

  p {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    margin-right: 150px;
  }
`;

export const TitleButton = styled(Button)`
  background-color: #FFF;
  border: 0px;
  font-weight: bold !important;
  font-size: 20px !important;
`;

export const InputContainer = styled.div`
  margin: 0;
  width: 100%;
  
  > div {
    width: 80%;
  }
`;

export const Loading = styled.div`
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
`;