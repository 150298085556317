import React, { Component, Fragment } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../components/layout";

class PrivateRoute extends Component {
  render() {
    const { error, loading, ...rest } = this.props;

    if (!loading) {
      return error ? (
        <Redirect to="/login" />
      ) : (
        <Fragment>
          <Layout {...rest} />
          <Route
            {...rest}
            render={routeProps => <Component {...routeProps} />}
          />
        </Fragment>
      );
    }

    return null;
  }
}

const mapStatesToProps = state => ({
  error: state.Main.error,
  loading: state.Main.loading
});

export default withRouter(connect(mapStatesToProps)(PrivateRoute));
