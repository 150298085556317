import { makeStyles } from '@material-ui/core'

import pageBackground from "../../utils/imgs/unicad-bg.png";

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    padding: 20,
    boxSizing: 'border-box',

    '&::before': {
      content: '""',
      position: 'fixed',
      background: `scroll url(${pageBackground}) no-repeat center / cover`,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      filter: 'blur(16px)',
      margin: -36,
      zIndex: -1,
    }
  },

  contentContainer: {
    display: 'flex',
    flexShrink: 1,
    paddingTop: 32,
    paddingBottom: 32,
    maxWidth: 800,
    width: '100%',
    maxHeight: '100%',

    backgroundColor: '#eee',
    borderRadius: 8,
    boxSizing: 'border-box',
    boxShadow: '0 3px 12px #333'
  },

  content: {
    overflowY: 'auto',
    paddingLeft: 60,
    paddingRight: 60,
  },

  contentTitle: {
    textAlign: 'center',
    marginTop: 12,
  },

  capitalLettersList: {
    paddingLeft: 20,
    '& li': {
      listStyle: 'none',
    },
  }
})

export default useStyles