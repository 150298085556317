// types
export const Types = {
  CHANGE_PAGE_CREATE_CHECKIN: "CHANGE_PAGE_CREATE_CHECKIN",
  REQUEST_CREATE_CHECKIN: "REQUEST_CREATE_CHECKIN",
  CHANGE_MODAL_CHECKIN: "CHANGE_MODAL_CHECKIN"
};

const INITIAL_VALUES = {
  page: 0,
  modalCheckin: false
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.CHANGE_PAGE_CREATE_CHECKIN:
      return { ...state, page: action.payload };
    case Types.CHANGE_MODAL_CHECKIN:
      return { ...state, modalCheckin: action.payload };
    default:
      return state;
  }
}

export function createCheckin(payload) {
  return { type: Types.REQUEST_CREATE_CHECKIN, payload };
}

export function setModalCheckin(payload) {
  return { type: Types.CHANGE_MODAL_CHECKIN, payload };
}

export function setPage(payload) {
  return { type: Types.CHANGE_PAGE_CREATE_CHECKIN, payload };
}
