import { takeLatest, put, call, all, select } from "redux-saga/effects";
import { Types } from "../../ducks/check-in/groups";
import axios from "../../../services/api";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(payload, customer = null) {
  let customer_id = payload.id ? payload.id : null;
  if (!customer_id) customer_id = customer;
  let requestUrl = `/groupsSendMessage?type=CHECKIN&push_notification=${payload.push_notification}&sms_notification=${payload.sms_notification}&status=1&receive_message_internal=true&customer_id=${customer_id}&application_id=${process.env.REACT_APP_APPLICATION_ID}`;

  return axios.get(requestUrl);
}

function* getGroups(action) {
  try {
    const customer_id = yield select(state => state.CheckInCustomers.customer);

    const resp = yield call(
      apiGet,
      action.payload,
      customer_id ? customer_id.id : null
    );

    yield put({
      type: Types.SUCCESS_CHECKIN_GET_GROUPS,
      payload: resp.data
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_CHECKIN_GET_GROUPS
    });
  }
}

export default function* root() {
  yield all([takeLatest(Types.REQUEST_CHECKIN_GET_GROUPS, getGroups)]);
}
