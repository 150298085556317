import React, { Component } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class MaterialSelectRedux extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //selectValue: this.props.input.value
    };
  }
  /*changeSelect = event => {
    this.setState({ selectValue: event.target.value });
  };*/
  renderInput() {
    const {
      inputLabel,
      name,
      data,
      input,
      isActive,
      meta: { error }
    } = this.props;
    //console.log(this.props);
    const hasError = (error || false) !== false;
    let disabled = isActive || false;

    let menus = null;
    if (data) {
      menus = data.map(item => (
        <MenuItem value={item.id} key={item.id}>
          {item.name}
        </MenuItem>
      ));
    }
    return (
      <FormControl margin="normal" error={hasError} fullWidth>
        <InputLabel htmlFor={name}>{inputLabel}</InputLabel>
        <Select
          {...input}
          error={hasError}
          disabled={disabled}
          //value={this.state.selectValue ? this.state.selectValue : ""}
          // onChange={this.changeSelect}
          inputProps={{
            id: "id",
            name: "name"
          }}
        >
          {menus}
        </Select>
        <FormHelperText id="component-error-text">
          {hasError ? error.join(", ") : ""}
        </FormHelperText>
      </FormControl>
    );
  }

  render() {
    return <div>{this.renderInput()}</div>;
  }
}

export default MaterialSelectRedux;
