import styled from 'styled-components';

import Grid from "@material-ui/core/Grid";

export const GridContainer = styled(Grid).attrs({
  container: true,
  spacing: 0,
})`
  align-items: center;
  align-content: center;

  .MuiGrid-grid-xs-12 {
    margin: 0;
    flex-direction: row;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  form {
    flex: 1;
    display: inline-flex;
    align-items: center;

    > div {
      flex: 1 1 0%;
    }
  }
`;
