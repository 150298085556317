import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ListItemText from "@material-ui/core/ListItemText";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

import { setPage } from "../../../store/ducks/check-in";
import { changeContactSelecteds } from "../../../store/ducks/check-in/contacts";

import { ListContainer, ListChild, Loading, BackButton } from './styles';

function CheckInContacts() {
  const [search_input, setSearch_input] = useState('');
  const dispatch = useDispatch();

  const { 
    data: contacts, 
    loading: loadingContacts, 
    contacts: contactsSelected
  } = useSelector(state => state.CheckInContacts);

  const changeInputSearch = () => event => {
    setSearch_input(event.target.value);
  };

  const setAllContacts = group => event => {
    if ((event.target || false) !== false)
      dispatch(changeContactSelecteds({
        group,
        checked: event.target.checked,
        setAll: true
      }));
  };
  
  const setContact = (contact, group) => event => {
    if ((event.target || false) !== false) {
      const payload = {
        contact,
        group,
        checked: event.target.checked
      };

      dispatch(changeContactSelecteds(payload));
    }
  };
  
  const checkedContactInGroup = (id, group_id) => {
    let contact = [];
    if (contactsSelected[group_id] && contactsSelected[group_id].users) {
      contact = contactsSelected[group_id].users.filter(item => item.id === id);
    }
    return contact.length > 0 ? true : false;
  };

  const getAllContactGroup = group => {
    let count = 0;
    if (contactsSelected[group.id] && contactsSelected[group.id].users) {
      contactsSelected[group.id].users.forEach(item => {
        if (group.users.filter(user => user.id === item.id)) count++;
      });
    }
    return count;
  };

    let contactsData = [];
    const group = contacts;
    if ((group || false) !== false && (group.users || false) !== false) {
      group.users.forEach(item => {
        if (
          search_input === "" ||
          item.name
            .toUpperCase()
            .includes(search_input.toUpperCase())
        ) {
          contactsData.push(
            <ListChild button key={item.id}>
              <Checkbox
                checked={checkedContactInGroup(item.id, group.id) || false}
                onClick={setContact(item, group)}
                color="primary"
              />

              <ListItemText primary={item.name} />
            </ListChild>
          );
        }
      });
    }
    
    let contentContats = (
      <Loading>
        <CircularProgress color="primary" size={50} />
      </Loading>
    );

    if (
      !loadingContacts &&
      (contacts || false) !== false &&
      contacts.users.length > 0
    ) {
      contentContats = (
        <ListContainer>
          <div>
            <IconButton disabled={true} aria-label="Search" type="submit">
              <SearchIcon />
            </IconButton>
            <InputBase
              name="search_input"
              onChange={changeInputSearch()}
              placeholder="Procurar por nome do contato ..."
              id="search_input"
            />
          </div>
          <ListChild button key={0}>
            <Checkbox
              onClick={setAllContacts(group)}
              checked={
                (contacts.users.length ===
                  getAllContactGroup(contacts) &&
                  contacts.users.length !== 0) ||
                false
              }
              indeterminate={
                (getAllContactGroup(contacts) <
                  contacts.users.length &&
                  contacts.users.length !== 0 &&
                  getAllContactGroup(contacts) !== 0) ||
                false
              }
              color="primary"
            />

            <ListItemText primary={"Selecionar todos"} />
          </ListChild>
          {contactsData.length > 0 ? (
            contactsData
          ) : (
            <Typography  variant="h5" component="h4">
              Nenhum contato encontrado.
            </Typography>
          )}
        </ListContainer>
      );
    } else if (
      !loadingContacts &&
      (contacts || false) !== false &&
      contacts.users.length === 0
    ) {
      contentContats = (
        <Typography variant="h5" component="h4">
          Nenhum contato encontrado.
        </Typography>
      );
    }

    const Contacts = (
      <div>
        <BackButton
          variant="text"
          onClick={() => dispatch(setPage(1))}
          color="default"
        >
          <ArrowBackIcon />
          Contatos
        </BackButton>
        <Divider />

        {contentContats}
      </div>
    );

    return <div>{Contacts}</div>;
  }

export default CheckInContacts;
