// types
export const Types = {
  REQUEST_LIST_CONTACTS: "REQUEST_LIST_CONTACTS",
  SUCCESS_LIST_CONTACTS: "SUCCESS_LIST_CONTACTS",
  FAILURE_LIST_CONTACTS: "FAILURE_LIST_CONTACTS",
  UPDATE_CONTACT_LIST: "UPDATE_CONTACT_LIST",
  FILTER_CONTACT_LIST: "FILTER_CONTACT_LIST"
};

const INITIAL_VALUES = {
  loading: false,
  error: false,
  contacts: [],
  pages: 0,
  total: 0
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.REQUEST_LIST_CONTACTS:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_LIST_CONTACTS:
      return {
        ...state,
        loading: false,
        error: false,
        contacts: action.payload.contacts,
        pages: action.payload.pages,
        total: action.payload.total
      };
    case Types.FAILURE_LIST_CONTACTS:
      return { ...state, loading: false, error: true };
    case Types.UPDATE_CONTACT_LIST:
      let contacts = state.contacts.map(contact =>
        contact.id === action.payload.id ? action.payload : contact
      );

      return {
        ...state,
        contacts: contacts
      };
    default:
      return state;
  }
};

export function getContacts() {
  return { type: Types.REQUEST_LIST_CONTACTS };
}

export function filterContacts(formData) {
  let payload = {
    formData
  };
  return { type: Types.FILTER_CONTACT_LIST, payload };
}
