import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { useCompare } from '../../shared/CustomHooks';

import { Marker } from "react-google-maps";
import Map from "../../map";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Logo from "../../../utils/imgs/logo.png";

import moment from "moment";
import { getMessage } from "../../../store/ducks/message/list";

import {
  Container,
  ContainerInfos,
  ContainerImage,
  Loading ,
  ImageLogo,
  ImageSpacing,
  MapContainer,
  NoData,
  Root,
  Title
} from "./styles";

function MessageIncidentLocation() {
  const [onLoad, setOnload] = useState(false);
  const [address, setAddress] = useState(null);

  const {
    messageDetail: message,
    loadingMessageDetail
  } = useSelector(state => state.MessageList);

  const { google } = window;

  const { id, contact_id } = useParams();
  const dispatch = useDispatch();
  const hasOnloadChanged = useCompare(onLoad);

  const getGeocode = async latlng => {
    if (google) {
      return new Promise((resolve, reject) => {
        new google.maps.Geocoder().geocode(
          { location: latlng },
          (results, status) => {
            if (results && results.length > 0) {
              return resolve(results[0].formatted_address);
            }
            return reject(null);
          }
        );
      });
    }
    return Promise.reject(null);
  };

  const getContactActived = message => {
    if (!message || Object.keys(message).length === 0) return false;

    const contact = message.contacts.filter(
      item => parseInt(item.id) === parseInt(contact_id)
    );
    return contact[0];
  };

  const loadMap = async () => {
    if (!hasOnloadChanged) {
      const address = await getGeocode(
        new google.maps.LatLng(
          getContactActived(message).checkin[0].location.coordinates[1],
          getContactActived(message).checkin[0].location.coordinates[0]
        )
      );
      setOnload(true);
      setAddress(address);
    }
  }

  useEffect(() => {
    loadMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    dispatch(getMessage(id));
  }, [dispatch, id]);

  const renderInfo = () => {
    if (loadingMessageDetail && message) {
      return (
        <Loading>
          <CircularProgress />
        </Loading>
      );
    }

    if (!message) {
      return (
        <ContainerInfos>
          <NoData>
            Não foram encontradas informações
          </NoData>
        </ContainerInfos>
      );
    }

    return (
      <ContainerInfos>
        <div>
          <Title>
            <small>
              Nome contato:
            </small>
            <br />
            <span>
              {getContactActived(message)
                ? getContactActived(message).name
                : ""}
            </span>
          </Title>
          <Title>
            <small>
              Descrição da mensagem:
            </small>
            <br />
            <span className="message-description">
              {message.description}
            </span>
          </Title>
          <Title>
            <small>
              Sinalizado em:
            </small>
            <br />
            {moment(message.startedAt).format("DD/MM/YYYY [às] HH:mm")}
          </Title>

          <Title>
            <small>
              Endereço:
            </small>
            <br />
            {address}
          </Title>
        </div>
      </ContainerInfos>
    );
  };

  const getMessages = getContactActived(message);

  let center = null;
  if (getMessages && getMessages.checkin && getMessages.checkin.length > 0) {
    center = new google.maps.LatLng(
      getMessages.checkin[0].location.coordinates[1],
      getMessages.checkin[0].location.coordinates[0]
    );
  }

    return (
      <Root>
        <Container>
          <ContainerImage>
            <ImageSpacing>
              <ImageLogo
                src={Logo}
                alt="Logo UMessenger"
              />
            </ImageSpacing>
          </ContainerImage>
          {renderInfo()}
          <Divider />
          <MapContainer>
            <Map center={center} zoom={17}>
              <Marker position={center} />
            </Map>
          </MapContainer>
        </Container>
      </Root>
    );
  }

export default MessageIncidentLocation;
