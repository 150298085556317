import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Done from '@material-ui/icons/Done';
import Error from '@material-ui/icons/Error';
import Logo from '~/utils/imgs/logo.png';

import {
  Root,
  Container,
  ContainerInfos,
  PaperContainer,
  ContainerImage,
  ImageSpacing,
  ImageLogo,
  Loading,
  Button,
  ButtonText,
  Title,
  SubTitle,
  ResponseContainer,
} from './styles';

import { responseCheckIn } from '~/store/ducks/check-in/public';

function MessageCheckinPublic({ match }) {
  const [language, setLanguage] = useState('pt');
  const [response, setResponse] = useState({});
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.CheckInPublic);
  const { message, variant } = useSelector(state => state.Message);

  const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  };

  useEffect(() => {
    setLanguage(navigator.language || navigator.userLanguage);
    getGeoLocation();
  }, []);

  const checkinDone =
    response && response[match.params.external_access_code] === true;

  useEffect(() => {
    setResponse(JSON.parse(localStorage.getItem('@message/checkin')));
  }, []);

  const handleSubmit = () => {
    dispatch(
      responseCheckIn({
        external_access_code: match.params.external_access_code,
        status: 'Estou bem',
        lat,
        lng,
        language,
      })
    );
  };

  const renderInfo = () => {
    if (loading) {
      return (
        <Loading>
          <CircularProgress style={{ color: 'primary' }} />
        </Loading>
      );
    }

    return (
      <>
        <ContainerInfos>
          <PaperContainer>
            <Grid container>
              <Grid item xs>
                {!checkinDone && !message ? (
                  <>
                    <Title>
                      {language !== 'pt'
                        ? 'Check in, please!'
                        : 'Faça seu Check In, por favor!'}
                    </Title>

                    <SubTitle>
                      {language !== 'pt'
                        ? 'Notify that you are out of danger'
                        : 'Informe se está fora de perigo'}
                    </SubTitle>
                    <Divider />
                    <Button
                      variant="extended"
                      size="medium"
                      aria-label="Estou bem"
                      onClick={handleSubmit}>
                      <ButtonText>
                        {language !== 'pt' ? 'Check In' : 'Check In'}
                      </ButtonText>
                    </Button>
                  </>
                ) : (
                  <ResponseContainer>
                    <Title>
                      {message ||
                        (language !== 'pt'
                          ? 'Check-In Done!'
                          : 'Check-In Realizado')}
                    </Title>
                    {variant === 'success' || variant === 'black' ? (
                      <Done color="primary" fontSize="large" />
                    ) : (
                      <Error color="error" fontSize="large" />
                    )}
                  </ResponseContainer>
                )}
              </Grid>
            </Grid>
          </PaperContainer>
        </ContainerInfos>
      </>
    );
  };

  return (
    <Root>
      <Container>
        <ContainerImage>
          <ImageSpacing>
            <ImageLogo src={Logo} alt="Logo UMessenger" />
          </ImageSpacing>
        </ContainerImage>
        {renderInfo()}
      </Container>
    </Root>
  );
}

MessageCheckinPublic.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      external_access_code: PropTypes.string,
    }),
  }).isRequired,
};

export default MessageCheckinPublic;
