// types
export const Types = {
  REQUEST_GROUP_FORM: "REQUEST_GROUP_FORM",
  CHANGE_CREATE_GROUP_PAGE: "CHANGE_CREATE_GROUP_PAGE",
  CHANGE_CREATE_GROUP_DAYS_WEEK: "CHANGE_CREATE_GROUP_DAYS_WEEK",
  ADD_COVERAGE_AREA_CREATE_GROUP: "ADD_COVERAGE_AREA_CREATE_GROUP",
  REMOVE_COVERAGE_AREA_CREATE_GROUP: "REMOVE_COVERAGE_AREA_CREATE_GROUP",
  CHANGE_ZOOM_CREATE_GROUP: "CHANGE_ZOOM_CREATE_GROUP"
};

const INITIAL_VALUES = {
  loading: false,
  page: 0,
  daysWeek: [],
  coverageAreas: [],
  zoomArea: null,
  isFetching: false
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_GET_CUSTOMERS:
      return {
        ...state,
        loading: true
      };

    case Types.CHANGE_CREATE_GROUP_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case Types.CHANGE_CREATE_GROUP_DAYS_WEEK:
      return {
        ...state,
        daysWeek: action.payload
      };
    case Types.ADD_COVERAGE_AREA_CREATE_GROUP:
      return Object.assign({}, state, {
        isFetching: false,
        coverageAreas: action.payload
      });

    case Types.REMOVE_COVERAGE_AREA_CREATE_GROUP:
      return Object.assign({}, state, {
        isFetching: true,
        coverageAreas: action.payload
      });

    case Types.CHANGE_ZOOM_CREATE_GROUP:
      return {
        ...state,
        mapZoom: action.payload
      };
    default:
      return state;
  }
}

export function createGroup(parameters) {
  return { type: Types.REQUEST_GROUP_FORM, payload: parameters };
}

export function changePage(page) {
  return { type: Types.CHANGE_CREATE_GROUP_PAGE, payload: page };
}

export function changeDaysWeek(days) {
  return { type: Types.CHANGE_CREATE_GROUP_DAYS_WEEK, payload: days };
}

export function addCoverage(payload) {
  return { type: Types.ADD_COVERAGE_AREA_CREATE_GROUP, payload };
}

export function changeZoom(payload) {
  return { type: Types.CHANGE_ZOOM_CREATE_GROUP, payload };
}

export function removeCoverage(payload) {
  return { type: Types.REMOVE_COVERAGE_AREA_CREATE_GROUP, payload };
}
