import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Settings from "@material-ui/icons/Settings";
import Group from "@material-ui/icons/Group";
import DeleteOutline from "@material-ui/icons/DeleteOutline";

import * as GroupListAction from "../../../../store/ducks/group/list";

import Filter from "../Filter/index";
import DialogDelete from "../DialogDelete";

import {
  StyledTableCell,
  StyledTableRow,
  classes,
  ProgressCircular,
  MenuChild,
  Title,
  Pagination,
  TableWrapper
} from "./styles";

const ITEM_HEIGHT = 45;

function GroupTable({ history }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState([]);

  const { data: groups, loading } = useSelector(state => state.ListGroups);
  const { customer } = useSelector(state => state.Customers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GroupListAction.getGroups());
  }, [dispatch, customer]);

  const handleClick = (event, groupId) => {
    let open = [];
    open[groupId] = true;
    setAnchorEl(event.currentTarget);
    setOpen(open);
  };

  const handleClose = groupId => {
    let open = [];
    open[groupId] = false;
    setAnchorEl(null);
    setOpen(open);
  };

  const redirectAssociation = (custom_id, group, history) => {
    if (!group.groupInfo.custom) {
      history.push(`/grupo/associarusuarios/${custom_id}/${group.id}/`);
    }
  };

  function renderHead() {
    if (!loading && groups && groups.length > 0) {
      return (
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" width={1}>
              Código
            </StyledTableCell>
            <StyledTableCell>Descrição do grupo</StyledTableCell>
            <StyledTableCell>Alerta - PUMAIS</StyledTableCell>
            <StyledTableCell>Comunicado</StyledTableCell>
            <StyledTableCell>Mass Notification</StyledTableCell>
            <StyledTableCell>Check in</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>SMS</StyledTableCell>
            <StyledTableCell>Notificação via App</StyledTableCell>
            <StyledTableCell>Contatos</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
      );
    }
  }

  function renderBody() {
    if (loading) {
      return (
        <TableBody>
          <TableRow>
            <TableWrapper>
              <ProgressCircular />
            </TableWrapper>
          </TableRow>
        </TableBody>
      );
    } else {
      if (groups && groups.length > 0) {
        return (
          <TableBody>
            {groups
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((group, key) => (
                <StyledTableRow key={group.id}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {group.id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {group.name}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {group.groupInfo.receive_incident ? "Sim" : "Não"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {group.groupInfo.receive_message_internal ? "Sim" : "Não"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {group.groupInfo.receive_mass_notification ? "Sim" : "Não"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {group.groupInfo.receive_checkin ? "Sim" : "Não"}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {group.groupInfo.status_active ? "Ativo" : "Desativado"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {group.groupInfo.sms_notification ? "Sim" : "Não"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {group.groupInfo.push_notification ? "Sim" : "Não"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Chip
                      onClick={() =>
                        redirectAssociation(customer.id, group, history)
                      }
                      label={group.users.length + " Contatos"}
                      style={{ margin: 1 }}
                    />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <div>
                      <IconButton
                        aria-label="More"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={event => handleClick(event, group.id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={
                          open.length > 0 && open[group.id]
                            ? open[group.id]
                            : false
                        }
                        onClose={() => handleClose()}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200
                          }
                        }}
                      >
                        <MenuChild
                          key={0}
                          onClick={() =>
                            history.push(`/grupo/editar/${group.id}`)
                          }
                        >
                          <Settings /> Configurar Grupo
                        </MenuChild>
                        {!group.groupInfo.custom && (
                          <MenuChild
                            key={1}
                            onClick={() =>
                              redirectAssociation(customer.id, group, history)
                            }
                          >
                            <Group /> Contatos do Grupo
                          </MenuChild>
                        )}

                        <MenuChild
                          key={2}
                          onClick={() => {
                            handleClose(group.id);
                            dispatch(
                              GroupListAction.toogleDialogDelete(group.id)
                            );
                          }}
                        >
                          <DeleteOutline /> Excluir Grupo
                        </MenuChild>
                      </Menu>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        );
      } else {
        return (
          <TableBody>
            <TableRow>
              <TableWrapper>
                <div>
                  {customer && (
                    <Title variant="h5" component="h4">
                      Nenhum grupo encontrado.
                    </Title>
                  )}
                </div>
              </TableWrapper>
            </TableRow>
          </TableBody>
        );
      }
    }
  }

  const handleChangePage = (event, newPage) => {
    let page = newPage;
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    let rowsPerPage = parseInt(+event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
  };

  let total = 0;
  if (groups && groups.length > 0) {
    total = groups.length;
  }

  return (
    <Paper className={classes.root}>
      <Filter />
      <div className={classes.tableWrapper}>
        <DialogDelete />
        <Table className={classes.table}>
          {renderHead()}

          {renderBody()}

          <TableFooter>
            <TableRow>
              <Pagination
                rowsPerPageOptions={[25, 50, 100, 200]}
                labelRowsPerPage={"Itens por página"}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
}

GroupTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default GroupTable;
