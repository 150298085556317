import axios from "axios";
export const login = (username, password) => {
  return axios.request({
    url: process.env.REACT_APP_PASSPORT_URL + "/oauth/token",
    method: "POST",
    data: {
      username,
      password,
      client_id: process.env.REACT_APP_PASSPORT_CLIENT_ID || null,
      client_secret: process.env.REACT_APP_PASSPORT_CLIENT_SECRET || null,
      scope: "",
      grant_type: "password"
    }
  });
};
