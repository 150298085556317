// types
export const Types = {
  MESSAGE: 'MESSAGE',
  TOOGLE_MESSAGE: 'TOOGLE_MESSAGE',
  REQUEST_TOOGLE_MESSAGE: 'REQUEST_TOOGLE_MESSAGE',
};

// reducers

const INITIAL_VALUES = {
  message: '',
  showMessage: false,
  variant: 'black',
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.MESSAGE:
      return {
        ...state,
        message: action.payload.message,
        variant: action.payload.variant,
        showMessage: true,
      };

    case Types.TOOGLE_MESSAGE:
      return { ...state, showMessage: false };

    default:
      return state;
  }
}

export function toogleMessage() {
  return { type: Types.REQUEST_TOOGLE_MESSAGE };
}
