import React from 'react'

import useStyles from './styles'

const AppPrivacy = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.content}>
          <h2 className={classes.contentTitle}>
            Política Privacidade
          </h2>
          <p>
            Este aplicativo coleta os dados de localização em segundo plano para habilitar o recebimento de alertas
            e comunicados de ocorrências nas regiões próximas, mesmo quando estiver fechado ou não sendo utilizado.
          </p>
          <p>
            A sua privacidade é importante para nós. Toda e qualquer informação coletada tem como fim a melhoria do serviço e/ou funcionalidades específicas habilitadas no aplicativo e é nosso compromisso não utilizar os dados obtidos por meio deste aplicativo e outros sites e aplicativo que possuímos e operamos.
          </p>
          <p>
            Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Também informamos por que estamos coletando e como será usado.
          </p>
          <p>
            Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
          </p>
          <p>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
          <p>
            O nosso aplicativo pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.
          </p>
          <p>
            Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.
          </p>
          <p>
            O uso continuado de nosso aplicativo será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco, por meio do nosso email de suporte apps@unicadtec.com.br.
          </p>
          <h3>Compromisso do Usuário</h3>
          <p>
            O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o security mass notification oferece com caráter enunciativo, mas não limitativo:
          </p>
          <ul className={classes.capitalLettersList}>
            <li>
              A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;
            </li>
            <li>
              B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou casas de apostas online, jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;
            </li>
            <li>
              C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) da Portal UMAIS, provedora do app Security Mass Notification, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
            </li>
          </ul>
          <h3>Uso de dados</h3>
          <p>Para o devido funcionamento do aplicativo e todas as suas funcionalidades de tempos em tempos coletaremos os dados de sua localização, mesmo fechado, realizando esta coleta em segundo plano.</p> 
          <p>Todos os dados são considerados sensíveis e são tratados de maneira a garantir o sigilo e a segurança dos usuários e não serão compartilhados com terceiros.</p>
        </div>
      </div>
    </div>
  )
}

export default AppPrivacy