// types
export const Types = {
  REQUEST_GET_GROUPS: "REQUEST_GET_GROUPS",
  SUCCESS_GET_GROUPS: "SUCCESS_GET_GROUPS",
  FAILURE_GET_GROUPS: "FAILURE_GET_GROUPS",
  CHANGE_GROUP: "CHANGE_GROUP",
  TOOGLE_DIALOG_DELETE: "TOOGLE_DIALOG_DELETE",
  FILTER_GROUP_LIST: "FILTER_GROUP_LIST"
};

const INITIAL_VALUES = {
  loading: false,
  error: false,
  data: null,
  group: null,
  showDialogDelete: false,
  groupIdDelete: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_GET_GROUPS:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_GET_GROUPS:
      return {
        loading: false,
        error: false,
        data: action.payload
      };
    case Types.TOOGLE_DIALOG_DELETE:
      return {
        ...state,
        showDialogDelete: !state.showDialogDelete,
        groupIdDelete: action.payload
      };
    case Types.FAILURE_GET_GROUPS:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_GROUP:
      return { ...state, group: action.payload };
    default:
      return state;
  }
}

export function getGroups() {
  return { type: Types.REQUEST_GET_GROUPS };
}

export function toogleDialogDelete(groupId) {
  return { type: Types.TOOGLE_DIALOG_DELETE, payload: groupId };
}

export function setGroup(value) {
  return { type: Types.CHANGE_GROUP, payload: value };
}

export function filterGroups(value) {
  return { type: Types.FILTER_GROUP_LIST, payload: value };
}
