import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Link from "@material-ui/core/Link";
import Logo from "../../utils/imgs/logo.png";
import MenuDrawer from "./menu";
import { connect } from "react-redux";
import { changeMenu } from "../../store/ducks/main/index";
import { useSelector } from "react-redux";

import { bindActionCreators } from "redux";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  logo: {
    flexGrow: 1,
    marginTop: 5
  },
  logoImg: {
    flexGrow: 1,
    marginTop: 5,
    cursor: "pointer"
  }
}));

function HeaderAppBar(props) {
  const classes = useStyles();
  const current_user = useSelector(state => state.Main.current_user);

  const { headerSimple } = props;

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("@sso_integration");
    localStorage.removeItem("@sso_expires_at");

    window.location.replace(process.env.PUBLIC_URL + "/login");
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {!headerSimple && (
            <>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={() => props.changeMenu(!props.menuOpen)}
              >
                <MenuIcon />
              </IconButton>
              <MenuDrawer />
            </>
          )}
          <>
            {headerSimple ? (
              <Typography className={classes.logo}>
                <img
                  className={classes.logoImg}
                  src={Logo}
                  width="212px"
                  alt="Logo"
                />
              </Typography>
            ) : (
              <Typography className={classes.logo}>
                <img
                  onClick={() =>
                    window.location.replace(process.env.PUBLIC_URL + "/")
                  }
                  className={classes.logoImg}
                  src={Logo}
                  width="212px"
                  alt="Logo"
                />
              </Typography>
            )}
          </>

          {!headerSimple && (
            <>
              <div>
                <Typography
                  style={{ marginRight: 30 }}
                  className={classes.logo}
                  onClick={() =>
                    window.location.replace(process.env.PUBLIC_URL + "/")
                  }
                >
                  Olá, {current_user ? current_user.name.split(" ")[0] : ""}
                </Typography>
              </div>
              <div>
                <Link color="inherit" href={"#"} onClick={logout}>
                  sair
                </Link>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeMenu }, dispatch);

const mapStateToProps = state => {
  return {
    menuOpen: state.Main.menu_open
  };
};

// Decorator para Redux Form
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderAppBar);
