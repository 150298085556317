import { takeLatest, put, call, select } from "redux-saga/effects";
import { Types } from "../../ducks/contact/deleteRestore";
import { Types as TypeList } from "../../ducks/contact/list";
import { Types as TypeMessage } from "../../ducks/main/message";
import axios from "../../../services/api.js";
import { Messages } from "../../../components/alert/messages";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiDeleteRestore(payload) {
  if (payload.status === true) {
    return axios.delete(`/contact/${payload.contactId}`);
  } else {
    return axios.get(`/contact/restore/${payload.contactId}`);
  }
}

function updateContacts(contacts, contact_id, status) {
  let user = {};
  user = contacts.find(item => item.id === contact_id);
  if (user.contact) {
    user.contact.status_active = !status;
  } else {
    user.contact = { status_active: !status };
  }

  return user;
}

function* deleteContact(action) {
  try {
    const resp = yield call(apiDeleteRestore, action.payload);
    let contacts = yield select(state => state.ListContact.contacts);

    yield put({
      type: Types.SUCCESS_DELETE_RESTORE_CONTACT,
      payload: resp.data
    });

    let contact = yield call(
      updateContacts,
      contacts,
      action.payload.contactId,
      action.payload.status
    );

    yield put({
      type: TypeList.UPDATE_CONTACT_LIST,
      payload: contact
    });

    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.LIST_DESABLE_CONTACT,
        variant: "success"
      }
    });
  } catch (error) {
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.LIST_ERROR_DESABLE_CONTACT,
        variant: "error"
      }
    });

    yield put({
      type: Types.FAILURE_DELETE_RESTORE_CONTACT
    });
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_DELETE_RESTORE_CONTACT, deleteContact);
}
