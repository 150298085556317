export function reformateErrors(errors) {
  let endErrors = [];

  if ((errors || false) !== false) {
    errors.map(objerror => {
      if (endErrors[objerror.param] != null) {
        endErrors[objerror.param].push(objerror.msg);
      } else {
        endErrors[objerror.param] = [objerror.msg];
        endErrors[objerror.param]._error = objerror.msg;
      }
      return true;
    });
  }
  return endErrors;
}

export function formatArray(array) {
  if ((array || false) !== false) {
    let data = [];

    data = array.filter(function(x) {
      if ((x || false) !== false) return true;
      return false;
    });
    return data.length;
  }
  return 0;
}

export function formatGroupsCorporate(array) {
  if ((array || false) !== false) {
    let data = [];
    array.filter(item => {
      if ((item || false) !== false) {
        const users = item.users ? item.users.map(user => user.id) : [];
        if ((item.groupInfo || false) !== false)
          data.push({ ...item.groupInfo, contacts_id: users });
      }
      return false;
    });
    return data;
  }
  return [];
}

export function getIdsByArray(array) {
  if ((array || false) !== false) {
    let data = [];

    array.filter(item => {
      if ((item || false) !== false) {
        item.forEach(user => {
          data.push(user.id);
        });
      }
      return false;
    });
    return data;
  }
  return [];
}

export function reformateArrayReduxForm(array, empty = true) {
  let ret = [];

  if (array) {
    array.map((item, key) => {
      if (item) {
        ret.push(key);
      }
      return true;
    });
  }
  if (ret.length < 1 && !empty) return null;

  return ret;
}

export function reformateArrayFieldReduxForm(array, empty = true) {
  let ret = [];

  if (array) {
    array.map((item, key) => {
      if (item.value) {
        ret.push(key + 1);
      }
      return true;
    });
  }
  if (ret.length < 1 && !empty) return null;

  return ret;
}
