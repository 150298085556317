// types
export const Types = {
  REQUENST_MESSAGE_GET_CUSTOMERS_INCIDENT:
    "REQUENST_MESSAGE_GET_CUSTOMERS_INCIDENT",
  SUCCESS_MESSAGE_GET_CUSTOMERS_INCIDENT:
    "SUCCESS_MESSAGE_GET_CUSTOMERS_INCIDENT",
  FAILURE_MESSAGE_GET_CUSTOMERS_INCIDENT:
    "FAILURE_MESSAGE_GET_CUSTOMERS_INCIDENT",
  CHANGE_MESSAGE_INCIDENT_CUSTUMER: "CHANGE_MESSAGE_INCIDENT_CUSTUMER"
};

const INITIAL_VALUES = {
  loading: true,
  error: false,
  data: null,
  customer: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUENST_MESSAGE_GET_CUSTOMERS_INCIDENT:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_MESSAGE_GET_CUSTOMERS_INCIDENT:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case Types.FAILURE_MESSAGE_GET_CUSTOMERS_INCIDENT:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_MESSAGE_INCIDENT_CUSTUMER:
      return { ...state, customer: action.payload };
    default:
      return state;
  }
}

export function getCustomers(payload) {
  return { type: Types.REQUENST_MESSAGE_GET_CUSTOMERS_INCIDENT, payload };
}

export function setCustumer(value) {
  return { type: Types.CHANGE_MESSAGE_INCIDENT_CUSTUMER, payload: value };
}
