import { combineReducers } from "redux";
import Login from "./ducks/main/login";
import Main from "./ducks/main/index";
import Message from "./ducks/main/message";
import Customers from "./ducks/customers";
import { reducer as formReducer } from "redux-form";
import FormContact from "./ducks/contact/form";
import ListContact from "./ducks/contact/list";
import DeleteRestoreContact from "./ducks/contact/deleteRestore";
import AssociationGroup from "./ducks/contact/associationGroup";
import ListGroups from "./ducks/group/list";
import ListContactsGroups from "./ducks/group/listContacts";
import GroupCreate from "./ducks/group/create";
import GroupEdit from "./ducks/group/edit";
import GroupDelete from "./ducks/group/delete";
import MessageList from "./ducks/message/list";
import CreateMessageCustomer from "./ducks/message/create/customers";
import CreateMessageGroup from "./ducks/message/create/groups";
import CreateMessageContacts from "./ducks/message/create/contacts";
import CreateMessage from "./ducks/message/create/index";
import MonitCreateMessage from "./ducks/monit/message/create";
import CoverageArea from "./ducks/coverageArea/index";
import MonitCustomersCreateMessage from "./ducks/monit/message/create/customers";
import MonitGroupsCreateMessage from "./ducks/monit/message/create/groups";
import MonitContactsCreateMessage from "./ducks/monit/message/create/contacts";
import CheckInCustomers from "./ducks/check-in/customers";
import CheckInContacts from "./ducks/check-in/contacts";
import CheckInGroups from "./ducks/check-in/groups";
import CheckIn from "./ducks/check-in/index";
import CheckInPublic from "./ducks/check-in/public";

export default combineReducers({
  form: formReducer,
  Login,
  Main,
  FormContact,
  Customers,
  ListContact,
  DeleteRestoreContact,
  Message,
  ListGroups,
  ListContactsGroups,
  AssociationGroup,
  GroupCreate,
  GroupEdit,
  GroupDelete,
  MessageList,
  CreateMessageCustomer,
  CreateMessageGroup,
  CreateMessageContacts,
  CreateMessage,
  CoverageArea,
  MonitCreateMessage,
  MonitCustomersCreateMessage,
  MonitGroupsCreateMessage,
  MonitContactsCreateMessage,
  CheckInCustomers,
  CheckInGroups,
  CheckInContacts,
  CheckIn,
  CheckInPublic
});
