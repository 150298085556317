import axios from "axios";
axios.defaults.headers.common["Content-Type"] = "application/json";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_URL_API
});
const userToken = localStorage.getItem("token");

if (userToken) {
  Axios.defaults.headers.common["Authorization"] = userToken;
} else {
  delete Axios.defaults.headers.common["Authorization"];
}

Axios.interceptors.response.use(undefined, err => {
  let res = err.response;
  if (res.status === 401) {
    localStorage.removeItem("token");
    if (!localStorage.getItem("@sso_integration")) {
      localStorage.removeItem("refreshToken");
    }
  }
  if (
    res.status === 403 &&
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/403"
  ) {
    window.location.replace("/403");
  }
  return Promise.reject(err);
});

export const apiSSO = () =>
  axios.create({ baseURL: process.env.REACT_APP_SSO_URL });

export default Axios;
