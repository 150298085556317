import styled from 'styled-components';

import Grid from "@material-ui/core/Grid";

export const GridContainer = styled(Grid).attrs({
  container: true,
  spacing: 0
})`

  font-size: 14px; 
  background-color: ${props => props.color ? props.color : ''};
`;


export const GridUser = styled(Grid)`
    p:first-child {
      margin-left: 10px;
      margin-top: 10px;
      font-weight: bold;
      font-size: 14px;
    }
    p:last-child {
      margin-left: 10px;
      margin-bottom: 10px;
      font-size: 11px;
      color: ${props => props.textcolor ? props.textcolor : ''};
    }
`;

export const GridCheckBox = styled(Grid)`
    display: flex;
    justify-content: flex-end;

    > p {
      margin-left: 10px;
      margin-bottom: 10px;
      font-size: 13px
    }
`;

export const GridContacts = styled(Grid)`
  opacity: 0.5;

  > p {
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 13px
  }
`;

export const GridStatus = styled(Grid)`
  display: flex;
  justify-content: flex-end;

  > p {
    margin-right: 20px;
    font-weight: bold;
    font-size: 14px;
    color: ${props => props.activecolor ? props.activecolor : ''};
  }
`;