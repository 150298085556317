import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { reduxForm, formValueSelector } from "redux-form";

import * as listContactsActions from "../../../../store/ducks/group/listContacts";

import Filter from "../Filter/index";
import Contact from "../Contacts/index";

import { 
  classes, 
  Loading, 
  GridContainer, 
  NoContactFound,
  Pagination 
} from './styles';

function ContactTable({ history, onSubmit, submitting, showCancel }) {
  const { 
    contacts, 
    total,
    loading
  } = useSelector(state => state.ListContactsGroups);

  let { rowsPerPage, page } = useSelector(state => state.ListContactsGroups);

  const { group, loading: loadingGroup } = useSelector(state => state.ListGroups);
  const { customer, loading: loadingCustomer} = useSelector(state => state.Customers);
  const { users_id_in_group: users_id } = useSelector(state => state.ListContactsGroups);

  const initialValues = { users_id: users_id ? users_id : undefined };
  const state = useSelector(state => state);
  
  const dispatch = useDispatch();
  const selector = formValueSelector("FORM_ASSOCIATION_USERS_GROUP");

  useEffect(() => {
    dispatch(listContactsActions.getContacts());
  }, [dispatch, group])

  useEffect(() => {
    dispatch(listContactsActions.setRowsPerPage(25));
    dispatch(listContactsActions.setPage(0));
  }, [dispatch, total])

    const handleChangePage = (event, newPage) => {
      let page = newPage;
      dispatch(listContactsActions.setPage(page));
    };

    const handleChangeRowsPerPage = event => {
      let rowsPerPage = parseInt(+event.target.value, 10);
      dispatch(listContactsActions.setRowsPerPage(rowsPerPage));
      dispatch(listContactsActions.setPage(0));
    };

    if (!page) {
      page = 0;
    }

    if (!rowsPerPage) {
      rowsPerPage = 0;
    }

    return (
      <Paper className={classes.root}>
        <Filter />
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                {loading || loadingGroup || loadingCustomer || submitting ? (
                  <Loading>
                    <CircularProgress color="primary" size={50} />
                  </Loading>
                ) : (
                  <TableCell>
                    {contacts.length > 0 && initialValues ? (
                      <GridContainer>
                        <Grid container item xs={12} spacing={3}>
                          {contacts
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((contact, key) => (
                              <Grid
                                key={contact.id}
                                item
                                xs={3}
                              >
                                <Paper className={classes.paper}>
                                  <Contact
                                    selected={selector(
                                      state,
                                      `users_id.${contact.id}`
                                    )}
                                    showCancel={showCancel}
                                    contact={contact}
                                    history={history}
                                  />
                                </Paper>
                              </Grid>
                            ))}
                        </Grid>
                      </GridContainer>
                    ) : (
                      <NoContactFound>
                        {customer && (
                          <Typography variant="h5" component="h4">
                            Nenhum contato encontrado.
                          </Typography>
                        )}
                      </NoContactFound>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>

            <TableFooter>
              <TableRow>
                <Pagination
                  labelRowsPerPage={"Itens por página"}
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Rows per page" },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
  
  ContactTable.propTypes = {
    history: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    showCancel: PropTypes.bool.isRequired,
  };

const mapStateToProps = (state) => {
  return {
    initialValues: {
      users_id: state.ListContactsGroups.users_id_in_group
    }
  };
}

export default connect(mapStateToProps)(reduxForm(
  { form: 'FORM_ASSOCIATION_USERS_GROUP', enableReinitialize: true}
  )(ContactTable))
  