import { takeLatest, put, call, select } from "redux-saga/effects";
import { Types } from "../../ducks/customers";
import axios from "../../../services/api.js";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet() {
  return axios.get(`/customers`);
}

function* getCustomers() {
  try {
    const resp = yield call(apiGet);
    const customer = yield select(state => state.Customers.customer);

    yield put({
      type: Types.SUCCESS_GET_CUSTOMERS,
      payload: resp.data
    });
    if (customer) {
      yield put({
        type: Types.CHANGE_CUSTUMER,
        payload: customer
      });
    } else {
      if (resp.data && typeof resp.data != undefined && resp.data.length > 0)
        yield put({
          type: Types.CHANGE_CUSTUMER,
          payload: resp.data[0]
        });
    }
  } catch (error) {
    yield put({
      type: Types.FAILURE_GET_CUSTOMERS
    });
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_GET_CUSTOMERS, getCustomers);
}
