import { takeLatest, put, call, all } from 'redux-saga/effects';
import { Types } from '~/store/ducks/check-in/public';
import axios from '~/services/api';
import { Types as TypeMessage } from '~/store/ducks/main/message';
import { Messages } from '~/components/alert/messages';

function apiPost(payload) {
  const requestUrl = `messages/response/checkin`;
  return axios.post(requestUrl, payload);
}

function* responseCheckin(action) {
  let response_success_checkin_in_public = Messages.RESPONSE_CHECKIN_PT;
  let response_error_checkin_in_public = Messages.ERROR_RESPONSE_CHECKIN_PT;

  try {
    yield call(apiPost, action.payload);

    if (action.payload.language !== 'pt') {
      response_success_checkin_in_public = Messages.RESPONSE_CHECKIN_EN;
      response_error_checkin_in_public = Messages.ERROR_RESPONSE_CHECKIN_EN;
    }

    yield put({
      type: Types.SUCCESS_RESPONSE_CHECK_IN_PUBLIC,
    });
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: response_success_checkin_in_public,
        variant: 'success',
      },
    });

    let localKeyStorage = JSON.parse(
      localStorage.getItem('@message/checkin') || '{}'
    );

    if (!localKeyStorage[action.payload.external_access_code]) {
      localKeyStorage[action.payload.external_access_code] = true;
    }

    localKeyStorage = { ...localKeyStorage };

    localStorage.setItem('@message/checkin', JSON.stringify(localKeyStorage));
  } catch (error) {
    yield put({
      type: Types.FAILURE_RESPONSE_CHECK_IN_PUBLIC,
    });
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: response_error_checkin_in_public,
        variant: 'error',
      },
    });
  }
}

export default function* root() {
  yield all([takeLatest(Types.RESPONSE_CHECK_IN_PUBLIC, responseCheckin)]);
}
