import React, { Component, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {
  //changeContactSelecteds,
  getContacts,
  getContactsEn
} from "../../../../../store/ducks/monit/message/create/contacts";
import { setGroup } from "../../../../../store/ducks/monit/message/create/groups";
import Tooltip from "@material-ui/core/Tooltip";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import NotificationIcon from "@material-ui/icons/NotificationImportant";
import SmsIcon from "@material-ui/icons/Sms";

class MonitMessageCreateGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_input: ""
    };
  }
  ChangeGroup = item => {
    this.props.setGroup(item.id);
    if (this.props.language_id === 1) this.props.getContacts(item.users);
    else this.props.getContactsEn(item.users);
  };

  changeInputSearch = () => event => {
    this.setState({ search_input: event.target.value });
  };

  render() {
    const { groups, loadingGroup, currentCustumer } = this.props;
    let groupsFilter = groups.filter(
      item => parseInt(item.customer_id) === parseInt(currentCustumer)
    );

    let groupsRender = [];
    if ((groupsFilter || false) !== false) {
      groupsFilter.forEach(item => {
        if (
          this.state.search_input === "" ||
          item.name
            .toUpperCase()
            .includes(this.state.search_input.toUpperCase())
        ) {
          groupsRender.push(
            <ListItem
              button
              key={item.id}
              onClick={() => this.ChangeGroup(item)}
              style={{
                borderBottom: "1px solid #CCCCCCCC"
              }}
            >
              <ListItemText primary={item.name} />
              {item.groupInfo.push_notification && (
                <Tooltip
                  title="Recebe notificação"
                  aria-label="receive notification"
                  style={{ marginRight: 10 }}
                >
                  <NotificationIcon color={"primary"} />
                </Tooltip>
              )}
              {item.groupInfo.sms_notification && (
                <Tooltip
                  title="Recebe SMS"
                  aria-label="receive sms"
                  style={{ marginRight: 10 }}
                >
                  <SmsIcon color={"primary"} />
                </Tooltip>
              )}
            </ListItem>
          );
        }
      });
    }
    const Groups =
      !loadingGroup && (groups || false) !== false ? (
        <Fragment>
          <div style={{ margin: 0, width: "100%" }}>
            <IconButton disabled={true} aria-label="Search" type="submit">
              <SearchIcon />
            </IconButton>
            <InputBase
              style={{ width: "80%" }}
              name="search_input"
              onChange={this.changeInputSearch()}
              placeholder="Procurar por nome do grupo ..."
              id="search_input"
            />
          </div>
          {groupsFilter.length > 0 ? (
            <List
              style={{
                maxWidth: "100%",
                maxHeight: 400,
                position: "relative",
                overflow: "auto",
                padding: "0px",
                margin: "0px"
              }}
            >
              {groupsFilter.length > 0 ? (
                groupsRender
              ) : (
                <Typography
                  variant="h5"
                  component="h4"
                  style={{
                    fontWeight: 400,
                    color: "#333333",
                    opacity: 0.6,
                    textAlign: "center",
                    marginTop: 20
                  }}
                >
                  Nenhum grupo encontrado.
                </Typography>
              )}
            </List>
          ) : (
            <Typography
              variant="h5"
              component="h4"
              style={{
                fontWeight: 400,
                color: "#333333",
                opacity: 0.6,
                textAlign: "center",
                marginTop: 20
              }}
            >
              Nenhum grupo encontrado.
            </Typography>
          )}
        </Fragment>
      ) : (
        <div style={{ textAlign: "center", marginTop: 200, marginBottom: 200 }}>
          <CircularProgress color="primary" size={50} />
        </div>
      );

    return <div>{Groups}</div>;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //  changeContactSelecteds,
      setGroup,
      getContacts,
      getContactsEn
    },
    dispatch
  );
const mapStateToProps = state => {
  return {};
};

// Decorator para Redux Form
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitMessageCreateGroup);
