import React, { Component, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContactTable from "./Table/index";

class ContactList extends Component {
  render() {
    const { history } = this.props;

    return (
      <Fragment>
        <Paper style={{ margin: 20, marginLeft: 50, marginRight: 50 }}>
          <ContactTable history={history} />
        </Paper>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const mapStateToProps = state => {
  return {};
};

// Decorator para Redux Form
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactList);
