// types
export const Types = {
  REQUEST_MESSAGE_GET_INCIDENT: 'REQUEST_MESSAGE_GET_INCIDENT',
  SUCCESS_MESSAGE_GET_INCIDENT: 'SUCCESS_MESSAGE_GET_INCIDENT',
  FAILURE_MESSAGE_GET_INCIDENT: 'FAILURE_MESSAGE_GET_INCIDENT',
  REQUEST_SET_MESSAGE_INCIDENT_BODY: 'REQUEST_SET_MESSAGE_INCIDENT_BODY',
  SUCCESS_SET_MESSAGE_INCIDENT_BODY: 'SUCCESS_SET_MESSAGE_INCIDENT_BODY',
  FAILURE_SET_MESSAGE_INCIDENT_BODY: 'FAILURE_SET_MESSAGE_INCIDENT_BODY',
  REQUEST_MESSAGE_CREATE_INCIDENT: 'REQUEST_MESSAGE_CREATE_INCIDENT',
};

const INITIAL_VALUES = {
  loading: null,
  error: null,
  incident: null,
  messageIncidentBodyResquest: null,
  loadingMessageIncidentBodyResquest: null,
  errorMessageIncidentBodyResquest: null,
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_MESSAGE_GET_INCIDENT:
      return {
        ...state,
        loading: true,
      };
    case Types.SUCCESS_MESSAGE_GET_INCIDENT:
      return {
        ...state,
        loading: false,
        incident: action.payload,
      };
    case Types.FAILURE_MESSAGE_GET_INCIDENT:
      return { ...state, loading: false, error: action.payload };

    case Types.REQUEST_SET_MESSAGE_INCIDENT_BODY:
      return {
        ...state,
        loadingMessageIncidentBodyResquest: true,
      };
    case Types.SUCCESS_SET_MESSAGE_INCIDENT_BODY:
      return {
        ...state,
        loadingMessageIncidentBodyResquest: false,
        messageIncidentBodyResquest: action.payload,
      };
    case Types.FAILURE_SET_MESSAGE_INCIDENT_BODY:
      return {
        ...state,
        loadingMessageIncidentBodyResquest: false,
        errorMessageIncidentBodyResquest: action.payload,
      };

    default:
      return state;
  }
}

export function getIncident(id, token) {
  return {
    type: Types.REQUEST_MESSAGE_GET_INCIDENT,
    payload: { token, id },
  };
}

export function createMessage(formData, token) {
  let payload = {
    ...formData,
    token,
  };
  return { type: Types.REQUEST_MESSAGE_CREATE_INCIDENT, payload };
}
