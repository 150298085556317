import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import MaterialCheckboxRedux from "../../../material/Inputs/checkbox";

import { Field } from "redux-form";
import { setTotalContactsSelectedSum } from "../../../../store/ducks/group/listContacts";
import { showCancel } from "../../../../store/ducks/group/listContacts";

import { 
  GridContainer,
  GridUser,
  GridCheckBox,
  GridContacts,
  GridStatus
} from './styles';

function Contact({ history, contact, checkedAll, selected }) {
  const dispatch = useDispatch();
  const currentContact = useMemo(() => contact, [contact]);

  let initialInGroup = contact.initialInGroup;

  let cellphone = contact.phones.find(phone => phone.type === 1);

  if (!cellphone) {
    cellphone = "";
  }

  const onChange = event => {
    dispatch(showCancel());

    if (event.target.value === "true") {
      dispatch(setTotalContactsSelectedSum(-1));
    }
    if (event.target.value === "false") {
      dispatch(setTotalContactsSelectedSum(1));
    }
  };

  let { color, text, textColor } = "";

  if (initialInGroup && selected) {
    color = "#CFF6DB";
    text = "";
    textColor = "";
  }
  if (!initialInGroup && selected) {
    color = "#CFE6F6";
    text = "Novo no grupo";
    textColor = "#2F6838";
  }
  if (initialInGroup && !selected) {
    color = "#F6F0CF";
    text = "Removido do grupo";
    textColor = "#FF0303";
  }

  let status_active_text,
    status_active_color = "";
  let status_active;
  if (contact.contact) {
    status_active = contact.contact.status_active;

    if (status_active) {
      status_active_text = " Ativo";
      status_active_color = "#2F6838";
    } else {
      status_active_text = " Desativado";
      status_active_color = "#FF0303";
    }
  } else {
    status_active_text = " Ativo";
    status_active_color = "#2F6838";
  }

  return (
    <GridContainer color={color}>
      <GridUser textcolor={textColor} item xs={10}>
        <Typography>
          {currentContact.name}
        </Typography>

        <Typography>
          {text}
        </Typography>
      </GridUser>
      <GridCheckBox item xs={2}>
        <Field
          name={`users_id.${currentContact.id}`}
          onChange={onChange}
          component={MaterialCheckboxRedux}
          id={currentContact.id}
          autoFocus
        />
      </GridCheckBox>

      <GridContacts item xs={8}>
        <Typography>
          {currentContact.phones.length > 0 ? (
            <span>
              {currentContact.phones.map((phone, key) => (
                <span key={phone.id}>{phone.name}</span>
              ))}
            </span>
          ) : (
            "Sem Celular"
          )}
        </Typography>
      </GridContacts>
      <GridStatus activecolor={status_active_color} item xs={4}>
        <Typography>
          {status_active_text}
        </Typography>
      </GridStatus>
    </GridContainer>
  );
}

Contact.defaultProps = {
  selected: false,
  checkedAll: '',
};

Contact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  contact: PropTypes.object.isRequired,
  checkedAll: PropTypes.string,
  selected: PropTypes.bool,
};

export default Contact;
