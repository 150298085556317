import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Button from '@material-ui/core/Button';

import { deleteFile } from '~/store/ducks/message/create/index';
import { Container, FileList, TitleContainer, Error } from './styles';

function Input({ onChange, placeholder }) {
  const { files, error } = useSelector(state => state.CreateMessage);

  const ref = useRef();
  const dispatch = useDispatch();

  return (
    <>
      <Container>
        <input
          ref={ref}
          accept="
            application/pdf,
            application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            image/jpeg, image/png"
          id="contained-button-file"
          multiple
          type="file"
          onChange={onChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            {placeholder}
          </Button>
        </label>
      </Container>

      {files && (
        <FileList>
          {files.map(file => (
            <li key={file.name}>
              <TitleContainer>
                <ArrowRightAltIcon />
                <span>{file.name}</span>
              </TitleContainer>
              <button
                type="button"
                onClick={() => dispatch(deleteFile(file.id))}>
                <DeleteIcon />
              </button>
            </li>
          ))}
        </FileList>
      )}
      {error && (
        <Error>
          <span>
            Falha ao enviar o arquivo: verifique o tamanho, tipo ou se excedeu o
            limite de 10mb
          </span>
        </Error>
      )}
    </>
  );
}

Input.defaultProps = {
  placeholder: '',
};

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default Input;
