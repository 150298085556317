import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

export const GridContainer = styled(Grid).attrs({
  container: true,
  spacing: 0,
})`
  .MuiGrid-grid-xs-10 {
    margin: 0;
    flex-direction: row;
    display: flex;
    justify-content: start;
    align-items: center;

    > div {
      margin: 0; 
      width: 100%; 
    }
  }

  .MuiGrid-grid-xs-2 {
    margin: 0; 
    position: absolute; 
    right: 50px;

    h5 {
      font-weight: 600;
      color: #26408B;
      font-size: 13px;
    }

    .MuiChip-sizeSmall {
      background-color: #26408B;
      color: #fff;
    }
  }
`;

export const DrawerContent = styled.div`
  width: 300px;

  h5 {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
  }

  .icon-container {
    position: absolute;
    right: 0; 
    top: 10px;
  }

  .status-container {
    margin: 20px;
  }
`;

export const FilterButton = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 200px !important;
      font-size: 15px;
      background-color: #3C5CB9 !important;
      color: #fff !important;
      justify-content: center;
      display: flex;
    }
`;
