// types
export const Types = {
  REQUEST_CHECKIN_GET_CUSTOMERS: "REQUEST_CHECKIN_GET_CUSTOMERS",
  SUCCESS_CHECKIN_GET_CUSTOMERS: "SUCCESS_CHECKIN_GET_CUSTOMERS",
  FAILURE_CHECKIN_GET_CUSTOMERS: "FAILURE_CHECKIN_GET_CUSTOMERS",
  CHANGE_CHECKIN_CUSTUMER: "CHANGE_CHECKIN_CUSTUMER"
};

const INITIAL_VALUES = {
  loading: null,
  error: false,
  data: null,
  customer: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_CHECKIN_GET_CUSTOMERS:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_CHECKIN_GET_CUSTOMERS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case Types.FAILURE_CHECKIN_GET_CUSTOMERS:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_CHECKIN_CUSTUMER:
      return { ...state, customer: action.payload };

    default:
      return state;
  }
}

export function getCustomers() {
  return { type: Types.REQUEST_CHECKIN_GET_CUSTOMERS };
}

export function setCustumer(value) {
  return { type: Types.CHANGE_CHECKIN_CUSTUMER, payload: value };
}
