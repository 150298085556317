// types
export const Types = {
  REQUEST_LIST_GROUP: "REQUEST_LIST_GROUP",
  SUCCESS_LIST_GROUP: "SUCCESS_LIST_GROUP",
  FAILURE_LIST_GROUP: "FAILURE_LIST_GROUP"
};

const INITIAL_VALUES = {
  loading: false,
  error: false,
  groupsByContact: []
};

export default (state = INITIAL_VALUES, action) => {
  switch (action.type) {
    case Types.REQUEST_LIST_GROUP:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_LIST_GROUP:
      return {
        ...state,
        loading: false,
        error: false,
        groupsByContact: action.payload
      };
    case Types.FAILURE_LIST_GROUP:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export function getGroups(user_id, customer_id) {
  let payload = {
    user_id,
    customer_id
  };
  return { type: Types.REQUEST_LIST_GROUP, payload };
}
