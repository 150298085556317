import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import MaterialInputSelectRedux from "../../../material/Inputs/select";
import MaterialSearchRedux from "../../../material/Inputs/search";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import Fab from "@material-ui/core/Fab";

import { reduxForm, Field, formValueSelector } from "redux-form";
import { filterContacts } from "../../../../store/ducks/contact/list";

import { Search, FilterContainer, FilterModal, ButtonContainer } from './styles';

let countFilters = 0;

let ContactFilter = ({ handleSubmit, submitting, timeout }) => {
  const [open, setOpen] = useState(false);

  const selector = formValueSelector("FILTER_CONTACT_FORM");
  const dispatch = useDispatch();

  const status_active = useSelector(state => selector(state, "status_active"));
  const valuesForm = {
    search_input: useSelector(state => selector(state, "search_input")),
    status_active: useSelector(state => selector(state, "status_active")),
    recieve: useSelector(state => selector(state, "recieve")),
  }

  const submit = values => {
    dispatch(filterContacts(values));
  };

  const filter = () => {
    setOpen(false);
    if (countFilters !== 0) submit(valuesForm);
  };
  const changeInputSearch = () => event => {
    var searchText = event.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(filterContacts({ search_input: searchText }));
    }, 1000);
  };

  countFilters = 0;
  if (status_active && status_active !== 3)
    countFilters++;

    return (
      <>
        <Paper>
          <form
            onSubmit={handleSubmit(values => submit(values))}
          >
            <Grid
              container
              alignContent={"center"}
              alignItems={"center"}
              spacing={0}
            >
              <Search item xs={10}>
                <IconButton aria-label="Search" type="submit">
                  <SearchIcon />
                </IconButton>
                <div>
                  <Field
                    name="search_input"
                    onChange={changeInputSearch()}
                    placeholder="Procurar por nome ou número do telefone celular ..."
                    component={MaterialSearchRedux}
                    id="search_input"
                  />
                </div>
              </Search>
              <FilterContainer item xs={2}>
                <div>
                  <Typography variant="h6" component="h5">
                    Filtros
                    <IconButton
                      onClick={() => setOpen(!open)}
                      aria-label="Filter List"
                    >
                      <FilterListIcon />
                    </IconButton>
                    {countFilters > 0 && (
                      <Chip label={"+" + countFilters} />
                    )}
                  </Typography>
                </div>
              </FilterContainer>
            </Grid>

            <Drawer
              anchor="right"
              open={open}
              onClose={() => {
                setOpen(!open);
                submit(valuesForm);
              }}
            >
              <FilterModal>
                <Typography variant="h6" component="h5">
                  Filtros
                </Typography>
                <div className="close-icon">
                  <IconButton
                    onClick={() => setOpen(!open)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <Divider />
                <div className="status-input">
                  <Field
                    name="status_active"
                    inputLabel="Status"
                    data={[
                      {
                        id: 3,
                        name: "Todos"
                      },
                      {
                        id: 1,
                        name: "Ativo"
                      },
                      {
                        id: 2,
                        name: "Desativado"
                      }
                    ]}
                    component={MaterialInputSelectRedux}
                    id="status_active"
                  />
                </div>
              </FilterModal>
              <ButtonContainer>
                <Fab
                  variant="extended"
                  size="small"
                  disabled={submitting}
                  aria-label="Save"
                  onClick={() => filter()}
                >
                  Filtrar
                </Fab>
              </ButtonContainer>
            </Drawer>
          </form>
        </Paper>
      </>
    );
  }

ContactFilter.defaultProps = {
  timeout: 1000,
};

ContactFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  timeout: PropTypes.number
};
  
ContactFilter = reduxForm({
  form: "FILTER_CONTACT_FORM",
  asyncBlurFields: ["search_input"]
})(ContactFilter);

export default ContactFilter;
