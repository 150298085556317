// types
export const Types = {
  REQUEST_MESSAGE_GET_CUSTOMERS: "REQUEST_MESSAGE_GET_CUSTOMERS",
  SUCCESS_MESSAGE_GET_CUSTOMERS: "SUCCESS_MESSAGE_GET_CUSTOMERS",
  FAILURE_MESSAGE_GET_CUSTOMERS: "FAILURE_MESSAGE_GET_CUSTOMERS",
  CHANGE_MESSAGE_CUSTUMER: "CHANGE_MESSAGE_CUSTUMER",

  REQUEST_VERIFY_CAN_SEND_MESSAGE: "REQUEST_VERIFY_CAN_SEND_MESSAGE"
};

const INITIAL_VALUES = {
  loading: null,
  error: false,
  data: null,
  customer: null,
  canSendSms: undefined,
  canSendPushNotification: undefined
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_MESSAGE_GET_CUSTOMERS:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_MESSAGE_GET_CUSTOMERS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case Types.FAILURE_MESSAGE_GET_CUSTOMERS:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_MESSAGE_CUSTUMER:
      return { ...state, customer: action.payload };
    case Types.REQUEST_VERIFY_CAN_SEND_MESSAGE:
      return {
        ...state,
        canSendSms: action.payload.sms_notification,
        canSendPushNotification: action.payload.push_notification
      };
    default:
      return state;
  }
}

export function getCustomers() {
  return { type: Types.REQUEST_MESSAGE_GET_CUSTOMERS };
}

export function setCustumer(value) {
  return { type: Types.CHANGE_MESSAGE_CUSTUMER, payload: value };
}
