import React, { Component, Fragment } from "react";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
//import { changeContactSelecteds } from "../../../../../store/ducks/monit/message/create/contacts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
class MonitMessageCreateContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_input: ""
    };
  }
  changeInputSearch = () => event => {
    this.setState({ search_input: event.target.value });
  };

  render() {
    let contacts = [];

    if ((this.props.contacts || false) !== false) {
      this.props.contacts.forEach(item => {
        if (
          this.state.search_input === "" ||
          item.name
            .toUpperCase()
            .includes(this.state.search_input.toUpperCase())
        ) {
          contacts.push(
            <ListItem
              button
              key={item.id}
              style={{
                borderBottom: "1px solid #CCCCCCCC"
              }}
            >
              <ListItemText primary={item.name} />
            </ListItem>
          );
        }
      });
    }
    let contentContats = (
      <div style={{ textAlign: "center", marginTop: 200, marginBottom: 200 }}>
        <CircularProgress color="primary" size={50} />
      </div>
    );
    if (this.props.contacts.length > 0) {
      contentContats = (
        <List
          style={{
            maxWidth: "100%",
            maxHeight: 400,
            position: "relative",
            overflow: "auto",
            padding: "0px",
            margin: "0px"
          }}
        >
          <div style={{ margin: 0, width: "100%" }}>
            <IconButton disabled={true} aria-label="Search" type="submit">
              <SearchIcon />
            </IconButton>
            <InputBase
              style={{ width: "80%" }}
              name="search_input"
              onChange={this.changeInputSearch()}
              placeholder="Procurar por nome do contato ..."
              id="search_input"
            />
          </div>
          {contacts.length > 0 ? (
            contacts
          ) : (
            <Typography
              variant="h5"
              component="h4"
              style={{
                fontWeight: 400,
                color: "#333333",
                opacity: 0.6,
                textAlign: "center",
                marginTop: 20
              }}
            >
              Nenhum contato encontrado.
            </Typography>
          )}
        </List>
      );
    } else {
      contentContats = (
        <Fragment>
          <Typography
            variant="h5"
            component="h4"
            style={{
              fontWeight: 400,
              color: "#333333",
              opacity: 0.6,
              textAlign: "center",
              marginTop: 20
            }}
          >
            Nenhum contato encontrado.
          </Typography>
        </Fragment>
      );
    }

    return <Fragment>{contentContats}</Fragment>;
  }
}

// Decorator para Redux Form
export default MonitMessageCreateContact;
