import React from 'react';
import Person from '@material-ui/icons/Person';
import Public from '@material-ui/icons/Public';
import CheckInIcon from '@material-ui/icons/PersonPinCircle';

import {
  Error,
  SignalCellular1Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
} from '@material-ui/icons';

import { CategoryIcon } from './styles';

export function category(categorys, critical) {
  switch (categorys) {
    case 1:
      return (
        <CategoryIcon
          icon={<Error />}
          label="Segurança"
          variant="outlined"
          security="true"
        />
      );
    case 2:
      return (
        <CategoryIcon
          icon={
            (critical >= 3 && <SignalCellular4Bar />) ||
            (critical <= 1 && <SignalCellular1Bar />) || <SignalCellular3Bar />
          }
          label="Trânsito"
          variant="outlined"
          traffic="true"
        />
      );
    case 3:
      return (
        <CategoryIcon
          icon={<Error />}
          label="Contigência"
          variant="outlined"
          contigencia="true"
        />
      );
    case 'Mass Notification':
      return (
        <CategoryIcon
          icon={<Public />}
          label="Mass Notification"
          variant="outlined"
          mass="true"
        />
      );
    case 'Checkin':
      return (
        <CategoryIcon
          checkin="true"
          icon={<CheckInIcon />}
          label="Check In"
          variant="outlined"
        />
      );
    default:
      break;
  }

  return (
    <CategoryIcon icon={<Person />} label="Comunicado" variant="outlined" />
  );
}

export function criticality(value) {
  switch (value) {
    case 1:
      return 'Criticidade Baixa';
    case 2:
      return 'Criticidade Média';
    case 3:
      return 'Criticidade Alta';
    default:
      break;
  }
  return null;
}
