import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap"
  },
  paper: {
    padding: theme.spacing(1, 2)
  }
}));

export default function CustomSeparator(props) {
  const classes = useStyles();
  let items = props.items.map(item => {
    if (item.url) {
      return (
        <Link color="inherit" href={item.url} key={item.name}>
          {item.name}
        </Link>
      );
    } else {
      return (
        <Typography color="textPrimary" key={item.name}>
          {item.name}
        </Typography>
      );
    }
  });
  return (
    <div className={classes.root}>
      <Paper
        style={{ backgroundColor: "rgba(204,204,204, 0.5)" }}
        elevation={0}
        className={classes.paper}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="Breadcrumb"
        >
          {items}
        </Breadcrumbs>
      </Paper>
    </div>
  );
}
