// types
export const Types = {
  RESPONSE_CHECK_IN_PUBLIC: "RESPONSE_CHECK_IN_PUBLIC",
  SUCCESS_RESPONSE_CHECK_IN_PUBLIC: "SUCCESS_RESPONSE_CHECK_IN_PUBLIC",
  FAILURE_RESPONSE_CHECK_IN_PUBLIC: "FAILURE_RESPONSE_CHECK_IN_PUBLIC"
};

const INITIAL_VALUES = {
  loading: false,
  error: false
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.RESPONSE_CHECK_IN_PUBLIC:
      return { ...state, loading: false };
    case Types.SUCCESS_RESPONSE_CHECK_IN_PUBLIC:
      return { ...state, loading: false };
    case Types.FAILURE_RESPONSE_CHECK_IN_PUBLIC:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

export function responseCheckIn(payload) {
  return { type: Types.RESPONSE_CHECK_IN_PUBLIC, payload };
}
