import React from "react";

import LoadingScreen from "react-loading-screen";

import Logo from "../../utils/imgs/logo.png";
const loadingScreen = () => {
  return (
    <div>
      <LoadingScreen
        loading={true}
        bgColor="#202020"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        logoSrc={Logo}
      >
        <div>Loadable content</div>
      </LoadingScreen>
    </div>
  );
};

export default loadingScreen;
