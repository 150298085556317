import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from "prop-types"

import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";

import MaterialSearchRedux from "../../../material/Inputs/search";

import {
  filtersMessages,
  getMessages
} from "../../../../store/ducks/message/list";

import { reduxForm, Field, formValueSelector } from "redux-form";

import { GridContainer } from './styles';

let MessageFilter = ({ destroy, timeout, handleSubmit, clearFields }) => {

  const selector = formValueSelector("FILTER_MESSAGES_FORM");
  const search_input = useSelector(state => selector(state, "search_input"));

  const dispatch = useDispatch();

  const submit = values => {
    search_input
      ? dispatch(filtersMessages(values))
      : dispatch(getMessages());
  };

  const changeInputSearch = () => event => {
    var searchText = event.target.value;
    if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        dispatch(filtersMessages({ search_input: searchText }));
    }, 1000);
  };

    return (
      <>
        <Paper>
          <GridContainer>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(submit)}>
                <IconButton aria-label="Search" type="submit">
                  <SearchIcon />
                </IconButton>
                <div>
                  <Field
                    name="search_input"
                    onChange={changeInputSearch()}
                    placeholder="Procurar por descrição ou remetente"
                    component={MaterialSearchRedux}
                    id="search_input"
                    style={{
                      flex: 1
                    }}
                  />
                </div>
              </form>
            </Grid>
          </GridContainer>
        </Paper>
      </>
    );
  }

MessageFilter.defaultProps = {
  timeout: 1000,
  destroy: () => {},
  clearFields: () => {},
} 

MessageFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  destroy: PropTypes.func,
  clearFields: PropTypes.func,

};

MessageFilter = reduxForm({
  form: "FILTER_MESSAGES_FORM",
  asyncBlurFields: ["search_input"]
})(MessageFilter);

export default MessageFilter;
