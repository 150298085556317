import { applyMiddleware, createStore, compose } from "redux";

import createSagaMiddleware from "redux-saga";

import rootReducers from "./reducers";

import allSagas from "./sagas";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = null;

const sagaMiddleware = createSagaMiddleware();

if (process.env.NODE_ENV === "development") {
  store = createStore(
    rootReducers,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
} else {
  store = createStore(rootReducers, applyMiddleware(sagaMiddleware));
}

sagaMiddleware.run(allSagas);
export default store;
