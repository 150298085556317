// types
export const Types = {
  REQUEST_DELETE_GROUP: "REQUEST_DELETE_GROUP",
  FINISH_DELETE_GROUP: "FINISH_DELETE_GROUP"
};

const INITIAL_VALUES = {
  loading: false
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_DELETE_GROUP:
      return {
        ...state,
        loading: true
      };
    case Types.FINISH_DELETE_GROUP:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export function deleteGroup(groupId) {
  return { type: Types.REQUEST_DELETE_GROUP, payload: groupId };
}
