import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

export const TitleButton = styled(IconButton)`
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
`;

export const Files = styled(Button).attrs({
  style: {
    backgroundColor: '#26408b',
    marginRight: 10,
    minWidth: 80,
    minHeight: 50,
    fontSize: 12,
  },
})`
  a {
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
`;

export const GridContainer = styled(Grid).attrs({
  container: true,
  direction: 'row',
})`
  min-height: 600px;
  min-width: 600px;
  overflow-x: hidden;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const GridContent = styled(Grid).attrs({
  container: true,
  direction: 'row',
})`
  min-height: 600px;
  min-width: 1200px;
  overflow-x: hidden;

  .map-container {
    height: 300px;
    width: 100%;
  }

  .footer-row-1 {
    border-top: 1px solid #ccc;
  }

  .footer-subtitle {
    font-weight: 500;
    color: #4d4f5c;
    font-size: 15px;
  }

  .footer-subtitle-content {
    padding-bottom: 10px;
    font-weight: 300;
    color: #333;
    font-size: 20px;
  }

  .MuiSvgIcon-fontSizeSmall {
    display: inline-flex;
    color: #5c5c5c;
    opacity: 0.6;
  }

  .MuiGrid-spacing-xs-5 {
    height: 100%;
  }
`;

export const Informations = styled(Grid)`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 20px 20px 0 20px !important;
  border-top: ${props => (props.border ? '1px solid #ccc' : '')};
  height: ${props => (props.header ? '100px' : '')};

  .description {
    min-height: 150px;
    display: flex;
    align-items: center;
    word-break: break-word;
  }

  .criticality {
    background-color: #5c5c5c;
    color: #fff;
    margin-right: 0.5vw;
  }

  .language {
    background-color: #fff;
    color: #5c5c5c;
  }
`;

export const ContactsContainer = styled(Grid)`
  overflow: auto;
  float: right;
  border-left: 1px solid #ccc;
`;
