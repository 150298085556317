import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialInputTextAreaRedux from '../../material/Inputs/textFieldArea';
import MaterialCheckboxRedux from '../../material/Inputs/checkbox';
import Customers from './customers';
import Contacts from './contacts';

import Groups from './groups';

import { changeContactSelecteds } from '../../../store/ducks/message/create/contacts';

import {
  createMessage,
  setPage,
  uploadFile,
} from '../../../store/ducks/message/create/index';
import { getGroups } from '../../../store/ducks/message/create/groups';
import { setOpenCreate } from '../../../store/ducks/message/list';
import UploadComponent from './uploadInput';

class MessageCreate extends Component {
  state = {
    onLoad: false,
  };

  handleOpen = () => {
    this.props.setOpenCreate(true);
  };

  handleClose = () => {
    this.props.setOpenCreate(false);
  };

  componentWillMount() {
    if (this.props.current_user) {
      this.props.change(
        'sms_notification',
        this.props.current_user.verifyCustomerCanSendMessage.sms_notification
      );
      this.props.change(
        'push_notification',
        this.props.current_user.verifyCustomerCanSendMessage.push_notification
      );
    }
  }

  componentDidUpdate = async oldProps => {
    if (
      (oldProps.push_notification !== this.props.push_notification &&
        this.props.pageModal > 0) ||
      (oldProps.sms_notification !== this.props.sms_notification &&
        this.props.pageModal > 0)
    ) {
      if (this.props.customersData && this.props.customersData.length > 1)
        this.props.setPage(0);
      else {
        this.props.getGroups({
          sms_notification: this.props.sms_notification,
          push_notification: this.props.push_notification,
        });
        this.props.setPage(1);
      }
      this.props.changeContactSelecteds([]);
    }
  };

  render() {
    let limitNumber = 142;

    if (this.props.push_notification) {
      limitNumber = 2000;
    }

    if (this.props.sms_notification) {
      limitNumber = 142;
    }

    const type =
      this.props.typeCreate === 'CORPORATE'
        ? 'Corporativa'
        : 'Mass Notification';

    return (
      <>
        <Dialog
          open={this.props.openCreate}
          //  onClose={this.handleClose}
          maxWidth="xl"
          scroll="body"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          {!this.props.submitting ? (
            <>
              <DialogTitle id="alert-dialog-title">
                {`Enviar mensagem - ${type}`}
              </DialogTitle>
              <IconButton
                onClick={() => this.props.setOpenCreate(false)}
                style={{ position: 'absolute', right: 0, top: 0 }}>
                <CloseIcon />
              </IconButton>
              <Divider />

              <DialogContent style={{ width: '88vw', overflowX: 'hidden' }}>
                <form
                  onSubmit={this.props.handleSubmit(this.props.createMessage)}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={6}>
                      <div
                        style={{
                          padding: 40,
                        }}>
                        <Field
                          name="description"
                          type="description"
                          label="Descrição da mensagem"
                          placeholder="Digite a descrição da mensagem"
                          variant="outlined"
                          multiline
                          limitNumber={limitNumber}
                          limit={
                            this.props.sms_notification ||
                            this.props.push_notification
                          }
                          rows={10}
                          component={MaterialInputTextAreaRedux}
                          id="description"
                        />
                      </div>
                    </Grid>
                    <div
                      style={{
                        position: 'absolute',
                        height: 510,
                        float: 'right',
                        borderLeft: '1px solid #CCCCCCCC',
                      }}
                    />
                    <Grid item xs={6}>
                      {this.props.submitFailed && this.props.error && (
                        <FormHelperText
                          id="component-error-text"
                          style={{
                            color: '#840000',
                            textAlign: 'left',
                            paddingBottom: 20,
                          }}>
                          {this.props.error}
                        </FormHelperText>
                      )}
                      {this.props.pageModal === 0 ? (
                        <Customers
                          push_notification={this.props.push_notification}
                          sms_notification={this.props.sms_notification}
                        />
                      ) : (
                        ''
                      )}
                      {this.props.pageModal === 1 ? <Groups /> : ''}
                      {this.props.pageModal === 2 ? <Contacts /> : ''}
                    </Grid>
                  </Grid>

                  <UploadComponent
                    placeholder="Upload"
                    onChange={e => this.props.uploadFile(e.target.files)}
                  />

                  <Field
                    name="sms_notification"
                    type="sms_notification"
                    disabled={
                      !this.props.current_user.verifyCustomerCanSendMessage
                        .sms_notification
                    }
                    inputLabel="Enviar por SMS"
                    component={MaterialCheckboxRedux}
                    id="sms_notification"
                  />

                  <Field
                    name="push_notification"
                    type="push_notification"
                    disabled={
                      !this.props.current_user.verifyCustomerCanSendMessage
                        .push_notification
                    }
                    inputLabel="Enviar para o App"
                    component={MaterialCheckboxRedux}
                    id="push_notification"
                  />

                  {this.props.submitFailed &&
                    !this.props.push_notification &&
                    !this.props.sms_notification && (
                      <FormHelperText
                        id="component-error-text"
                        style={{
                          color: '#840000',
                          textAlign: 'left',
                          paddingBottom: 20,
                        }}>
                        Pelo menos um tipo de mensagem é necessário.
                      </FormHelperText>
                    )}
                  <Divider />

                  <div
                    style={{
                      backgroundColor: '#FFF',
                      marginTop: 80,
                    }}>
                    <Fab
                      variant="extended"
                      size="small"
                      type="submit"
                      color="primary"
                      aria-label="Save"
                      disabled={this.props.submitting}
                      style={{
                        width: 120,
                        fontSize: 15,
                        position: 'absolute',
                        right: 50,
                        bottom: 20,
                      }}>
                      Enviar
                    </Fab>
                  </div>
                </form>
              </DialogContent>
            </>
          ) : (
            <div
              style={{
                minHeight: 400,
                minWidth: 1200,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                display: 'flex',
              }}>
              <CircularProgress color="primary" size={50} />
            </div>
          )}
        </Dialog>
      </>
    );
  }
}

const selector = formValueSelector('MESSAGE_CREATE_FORM');

MessageCreate = reduxForm({
  form: 'MESSAGE_CREATE_FORM',
  // <------ unregister fields on unmount
})(MessageCreate);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeContactSelecteds,
      createMessage,
      setOpenCreate,
      setPage,
      getGroups,
      change,
      uploadFile,
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    contactSelecteds: state.CreateMessageContacts.contacts,
    customersData: state.CreateMessageCustomer.data,
    customersDataLoading: state.CreateMessageCustomer.loading,
    customersDataSelected: state.CreateMessageCustomer.customer,
    pageModal: state.CreateMessage.page,
    files: state.CreateMessage.files,
    openCreate: state.MessageList.openCreate,
    typeCreate: state.MessageList.typeCreate,
    sms_notification: selector(state, 'sms_notification'),
    push_notification: selector(state, 'push_notification'),
    canSendPushNotification:
      state.CreateMessageCustomer.canSendPushNotification,
    current_user: state.Main.current_user,
    initialValues: {
      type_message: state.MessageList.typeCreate,
    },
  };
};

// Decorator para Redux Form
export default connect(mapStateToProps, mapDispatchToProps)(MessageCreate);
