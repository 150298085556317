import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { getCustomers, setCustumer } from '../../store/ducks/customers';
import { getCustomer } from '../../services/customer';

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  async componentWillMount() {
    const customer = getCustomer();
    if (customer) {
      this.props.setCustumer({ id: customer });
    }
    this.props.getCustomers();
  }
  handleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange = event => {
    let payload = {
      id: event.target.value,
      name: event.target.name,
    };
    this.props.setCustumer({ ...payload });
  };

  renderSelect() {
    const { customers, loading, customer } = this.props;
    if (loading) {
      return <CircularProgress color="primary" size={20} />;
    }
    let customersItem =
      customers && typeof customers != undefined
        ? customers.map(item => (
            <MenuItem value={item.id} key={item.id}>
              <b>{item.name}</b>
            </MenuItem>
          ))
        : '';
    return (
      <Fragment>
        <span style={{ fontWeight: '600' }}> Empresa - </span>
        <Select
          open={this.state.open}
          onClose={this.handleOpen}
          onOpen={this.handleOpen}
          value={customer && typeof customer != undefined ? customer.id : ''}
          onChange={this.handleChange}
          disableUnderline
          disabled={this.props.disabled}
          inputProps={{
            name: 'name',
            id: 'value',
          }}>
          {customersItem}
        </Select>
      </Fragment>
    );
  }

  render() {
    return (
      <div style={{ marginTop: 20, marginLeft: 45 }}>{this.renderSelect()}</div>
    );
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCustomers, setCustumer }, dispatch);

const mapStateToProps = state => {
  return {
    customers: state.Customers.data,
    loading: state.Customers.loading,
    customer: state.Customers.customer,
  };
};

// Decorator para Redux Form
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
