import { takeLatest, put, call } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { Types } from '../../ducks/contact/update';
import axios from '~/services/api';
import { Types as TypeMessage } from '../../ducks/main/message';
import { reformateArrayReduxForm } from '~/utils/form/index';
import { Messages } from '~/components/alert/messages';

function apiUpdateContact(parameters) {
  return axios.put(`/contact`, parameters);
}

function apiAssociationGroups(user_id, groups_id) {
  const data = {
    user_id,
    groups_id,
  };
  return axios.post(`/contact/association/groups`, data);
}

function* updateContact(action) {
  yield put(startSubmit('CONTACT_FORM_EDIT'));
  try {
    const parameters = action.payload;
    yield call(apiUpdateContact, parameters);
    const groups_id = reformateArrayReduxForm(parameters.groups_id);

    yield call(apiAssociationGroups, parameters.user_id, groups_id);

    yield put(stopSubmit('CONTACT_FORM_EDIT'));
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.UPDATE_SUCCESS_CONTACT,
        variant: 'success',
      },
    });

    window.location.replace(`${process.env.PUBLIC_URL}/contatos`);
  } catch ({ response }) {
    if (response && response.data) {
      yield put(stopSubmit('CONTACT_FORM_EDIT', response.data.errors));
    }
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.UPDATE_ERROR_CONTACT,
        variant: 'error',
      },
    });
  }
}

export default function* root() {
  yield takeLatest(Types.REQUEST_CONTACT_FORM_UPDATE, updateContact);
}
