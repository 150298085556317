import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getContacts } from "../../../../store/ducks/contact/list";

import Filter from "../Filter/index";
import Contact from "../Contact/index";

import { 
  Loading, 
  GridContainer, 
  GridItem, 
  NoContact,
  Paginator, 
  classes 
} from './styles';

function ContactTable({ history }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(24);

  const { customer } = useSelector(state => state.Customers);
  let { contacts, loading, total } = useSelector(
    state => state.ListContact
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContacts());
  }, [customer, dispatch]);

    const handleChangePage = (event, newPage) => {
      let page = newPage;
      setPage(page);
    };

    const handleChangeRowsPerPage = event => {
      let rowsPerPage = parseInt(+event.target.value, 10);
      setRowsPerPage(rowsPerPage);
      setPage(0);
    };

    if (!total && contacts) {
      total = contacts.length ;
    } else {
      total = 0;
    };

    return (
      <Paper className={classes.root}>
        <Filter />
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                {loading ? (
                  <Loading>
                    <CircularProgress color="primary" size={50} />
                  </Loading>
                ) : (
                  <TableCell>
                    {contacts.length > 0 ? (
                      <GridContainer>
                        <Grid container item xs={12} spacing={3}>
                          {contacts
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((contact, key) => (
                              <GridItem
                                key={contact.id}
                                item
                                xs={3}
                              >
                                <Paper className={classes.paper}>
                                  <Contact
                                    keyContact={key + page * rowsPerPage}
                                    history={history}
                                  />
                                </Paper>
                              </GridItem>
                            ))}
                        </Grid>
                      </GridContainer>
                    ) : (
                      <NoContact>
                        {customer && (
                          <Typography variant="h5" component="h4">
                            Nenhum contato encontrado.
                          </Typography>
                        )}
                      </NoContact>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>

            <TableFooter>
              <TableRow>
                <Paginator
                  labelRowsPerPage={"Itens por página"}
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Rows per page" },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }

  ContactTable.propTypes = {
    history: PropTypes.object.isRequired
  };

  export default ContactTable;
