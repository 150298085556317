// types
export const Types = {
  REQUEST_CHECKIN_GET_GROUPS: "REQUEST_CHECKIN_GET_GROUPS",
  SUCCESS_CHECKIN_GET_GROUPS: "SUCCESS_CHECKIN_GET_GROUPS",
  FAILURE_CHECKIN_GET_GROUPS: "FAILURE_CHECKIN_GET_GROUPS",
  CHANGE_CHECKIN_GROUPS: "CHANGE_CHECKIN_GROUPS"
};

const INITIAL_VALUES = {
  loading: null,
  error: false,
  data: null,
  group: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_CHECKIN_GET_GROUPS:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_CHECKIN_GET_GROUPS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case Types.FAILURE_CHECKIN_GET_GROUPS:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_CHECKIN_GROUPS:
      return { ...state, group: action.payload };

    default:
      return state;
  }
}

export function getGroups(payload) {
  return { type: Types.REQUEST_CHECKIN_GET_GROUPS, payload };
}

export function setGroup(payload) {
  return { type: Types.CHANGE_CHECKIN_GROUPS, payload };
}
