import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { toogleDialogDelete } from "../../../../store/ducks/group/list";
import { deleteGroup } from "../../../../store/ducks/group/delete";

function DialogDelete() {
  const { showDialogDelete, groupIdDelete } = useSelector(
    state => state.ListGroups
  );

  const dispatch = useDispatch();

  return (
    <Dialog
      open={showDialogDelete ? showDialogDelete : false}
      onClose={() => dispatch(toogleDialogDelete(null))}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Exclusão de grupo"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deseja excluir o grupo de código {groupIdDelete}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(toogleDialogDelete(null))}
          color="primary"
        >
          Fechar
        </Button>
        <Button
          onClick={() => {
            dispatch(toogleDialogDelete(null));
            dispatch(deleteGroup(groupIdDelete));
          }}
          color="primary"
          autoFocus
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogDelete;
