import styled from 'styled-components';
import Typography from "@material-ui/core/Typography";

export const Root = styled.div`
  flex: 1;
  display: flex;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;

  @media (max-width: 959.95px) {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

export const Container = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 9999;
  background: #FFF;
  
  @media (max-width: 959.95px) {
    flex: 8;
    display: flex;
    flex-direction: column;
    background: #FFF;
  }
`;

export const ContainerInfos = styled.div`
  padding: 20px;
  padding-top: 0;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media (min-width: 600px) {
    margin-top: 10px;
  }
  @media (max-width: 959.95px) {
    text-align: left;
  }
`;

export const ContainerImage = styled.div`
  background: #26408b;
  box-shadow: 0 1px 2px #CCC !important;
`;

export const ImageSpacing = styled.div`
  padding: 20px;
  text-align: center;

  @media (max-width: 959.95px) {
    align-self: center;
    padding: 10px;
    padding-top: 12px;
  }
`;

export const ImageLogo = styled.img`
  max-width: 100%;
  width: 210px;

  @media (max-width: 959.95px) {
    width: 180px;
  }
`;

export const Loading = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  svg {
    color: #fff;
  }

  @media (min-width: 600px) {
    margin-top: 10px;
  }
`;

export const Title = styled(Typography)`
  margin-top: 10px !important;
  margin-left: 50px !important;
  font-size: 14px !important;

  .message-description {
    word-break: break-word;
  }

  small {
    color: #26408b;
    text-transform: uppercase;
  }
`;

export const NoData = styled(Typography)`
  color: #EFEFEF !important; 
  margin-top: 20px;
`;

export const MapContainer = styled.div`
  width: 50%;
  height: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;