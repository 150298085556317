import React, { Component } from "react";
import LoginImg from "../../utils/imgs/Log_tela.jpg";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import LoginForm from "./form";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outerWidth: window.outerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ outerWidth: window.outerWidth });
  };

  render() {
    let img = this.state.outerWidth < 800 ? style.none : style.block;
    return (
      <div style={style.root}>
        <Grid container spacing={0}>
          <Grid style={img} item xs={12} sm={6} >
            <img style={style.img} src={LoginImg} alt="Login" />
          </Grid>
          <Grid item xs={12} sm={6} direction="column" alignItems="center" justify="center" style={{ flex: 1, display: 'flex' }} >
            {this.props.sso ? (
              <div style={{ flex: 1, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color="primary" size={34} style={{ marginRight: 20  }} />
                <Typography variant="button" style={{ textAlign: 'center' }}>
                  Validando suas credenciais                
                </Typography>
              </div>
            ) : (
              <LoginForm />
            )}
            
          </Grid>
        </Grid>
      </div>
    );
  }
}

const style = {
  root: {
    flexGrow: 1,
    padding: 0,
    margin: 0,
    backgroundColor: "white"    
  },
  img: {
    height: "100vh",
    width: "100%"
  },
  none: {
    display: "none"
  },
  block: {
    display: "block"
  }
};

export default Login;
