import styled from 'styled-components';

import List from '@material-ui/core/List';

import {
  Cancel,
  CheckCircle,
  SendRounded,
  PauseCircleFilled,
  CancelScheduleSendRounded,
} from '@material-ui/icons'

export const ListContainer = styled(List)`
  max-width: 100%;
  max-height: 200px;

  li {
    border-bottom: 1px solid #ccc;

    .MuiInputBase-root {
      width: 100%;
    }

    .MuiListItemText-primary {
      > p {
        font-weight: bold;
        font-size: 17px;
        color: #333;
      }
    }

    .MuiListItemText-secondary {
      .MuiTypography-body1 {
        display: block;
        font-weight: 600;
        font-size: 15px;
        color: #333;
        max-width: 110%;
        width: max-content;
        > span {
          display: inline-flex;
        }
      }
      .group-contacts {
        display: flex;
        flex-direction: column;
        font-weight: 200;

        margin-left: 16px;

        .contact-phones {
          margin-left: 16px;
          display: flex;
          flex-direction: column;

          .contact-phone {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
`;

export const SuccessIcon = styled(CheckCircle)`
  color: #009e00 !important;
  margin-left: 5px !important;
`;

export const PendingIcon = styled(PauseCircleFilled)`
  color: #333 !important;
  margin-left: 5px !important;
`;

export const SentIcon = styled(SendRounded)`
  color: #333 !important;
  margin-left: 5px !important;
`;

export const FailedToSendIcon = styled(CancelScheduleSendRounded)`
  color: #bd0f0f !important;
  margin-left: 5px !important;
`;

export const FailedToBeDeliveredIcon = styled(Cancel)`
  color: #bd0f0f !important;
  margin-left: 5px !important;
`;
