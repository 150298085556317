// types
export const Types = {
  REQUEST_MESSAGE_GET_GROUP: "REQUEST_MESSAGE_GET_GROUP",
  SUCCESS_MESSAGE_GET_GROUP: "SUCCESS_MESSAGE_GET_GROUP",
  FAILURE_MESSAGE_GET_GROUP: "FAILURE_MESSAGE_GET_GROUP",
  CHANGE_MESSAGE_GROUP: "CHANGE_MESSAGE_GROUP"
};

const INITIAL_VALUES = {
  loading: null,
  error: false,
  data: null,
  group: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_MESSAGE_GET_GROUP:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_MESSAGE_GET_GROUP:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case Types.FAILURE_MESSAGE_GET_GROUP:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_MESSAGE_GROUP:
      return { ...state, group: action.payload };
    default:
      return state;
  }
}

export function getGroups(payload) {
  return { type: Types.REQUEST_MESSAGE_GET_GROUP, payload };
}

export function setGroup(payload) {
  return { type: Types.CHANGE_MESSAGE_GROUP, payload };
}
