import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ListItemText from "@material-ui/core/ListItemText";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import NotificationIcon from "@material-ui/icons/NotificationImportant";
import SmsIcon from "@material-ui/icons/Sms";

import { setPage } from "../../../store/ducks/check-in";
import { setGroup } from "../../../store/ducks/check-in/groups";
import {
  changeContactSelecteds,
  getContacts
} from "../../../store/ducks/check-in/contacts";

import { 
  ListContainer, 
  ListChild, 
  CursorTitle, 
  NoData, 
  InputContainer, 
  TitleButton,
  Loading
} from './styles';

function CheckInGroups() {
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  
  const { 
    data: groups, 
    loading: loadingGroup 
  } = useSelector(state => state.CheckInGroups);

  const { data: customers } = useSelector(state => state.CheckInCustomers);
  const { contacts: contactsSelected } = useSelector(state => state.CheckInContacts);

  const changeGroup = id => {
    dispatch(setGroup(id));
    dispatch(getContacts(id));
    dispatch(setPage(2));
  };

  const getAllContactGroup = group => {
    let count = 0;
    if (
      (contactsSelected[group.id] || false) &&
      contactsSelected[group.id].users
    ) {
      contactsSelected[group.id].users.forEach(item => {
        if (group.users.filter(user => user.id === item.id)) count++;
      });
    }
    return count;
  };

  const setAllContacts = group => event => {
    if ((event.target || false) !== false)
      dispatch(changeContactSelecteds({
        group,
        checked: event.target.checked,
        setAll: true
      }));
  };

  const changeInputSearch = () => event => {
    setSearchInput(event.target.value);
  };

    let ArrowBack = (
      <TitleButton variant="text" color="default">
        Grupos
      </TitleButton>
    );
    if (customers && customers.length > 1)
      ArrowBack = (
        <TitleButton
          variant="text"
          onClick={() => dispatch(setPage(0))}
          color="default"
        >
          <ArrowBackIcon style={{ position: 50 }} />
          Grupos
        </TitleButton>
      );
    let groupsRender = [];
    if ((groups || false) !== false) {
      groups.forEach(item => {
        if (
          searchInput === "" ||
          item.name
            .toUpperCase()
            .includes(searchInput.toUpperCase())
        ) {
          groupsRender.push(
            <ListChild button key={item.id}>
              <Checkbox
                onClick={setAllContacts(item)}
                checked={
                  (item.users.length === getAllContactGroup(item) &&
                    item.users.length !== 0) ||
                  false
                }
                indeterminate={
                  (getAllContactGroup(item) < item.users.length &&
                    item.users.length !== 0 &&
                    getAllContactGroup(item) !== 0) ||
                  false
                }
                color="primary"
              />
              <ListItemText primary={item.name} />
              {item.groupInfo.push_notification && (
                <CursorTitle
                  title="Recebe notificação"
                  aria-label="receive notification"
                >
                  <NotificationIcon color={"primary"} />
                </CursorTitle>
              )}
              {item.groupInfo.sms_notification && (
                <CursorTitle
                  title="Recebe SMS"
                  aria-label="receive sms"
                >
                  <SmsIcon color={"primary"} />
                </CursorTitle>
              )}
              <Link onClick={() => changeGroup(item.id)}>
                {getAllContactGroup(item) + " "}
                selecionados
              </Link>
            </ListChild>
          );
        }
      });
    }
    const Groups =
      !loadingGroup && (groups || false) !== false ? (
        <div>
          {ArrowBack}
          <InputContainer>
            <IconButton disabled={true} aria-label="Search" type="submit">
              <SearchIcon />
            </IconButton>
            <InputBase
              name="search_input"
              onChange={changeInputSearch()}
              placeholder="Procurar por nome do grupo ..."
              id="search_input"
            />
          </InputContainer>
          {groups.length > 0 ? (
            <ListContainer>
              {groupsRender.length > 0 ? (
                groupsRender
              ) : (
                <NoData variant="h5" component="h4">
                  Nenhum grupo encontrado.
                </NoData>
              )}
            </ListContainer>
          ) : (
            <NoData variant="h5" component="h4">
              Nenhum grupo encontrado.
            </NoData>
          )}
        </div>
      ) : (
        <Loading>
          <CircularProgress color="primary" size={50} />
        </Loading>
      );

    return <div>{Groups}</div>;
  }

export default CheckInGroups;
