import { takeLatest, put, call, select, all } from "redux-saga/effects";
import { Types } from "../../ducks/group/list";
import axios from "../../../services/api.js";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(customer, dataForm = null) {
  let requestUrl = `/groups?customer_id=${customer.id}`;

  if (dataForm) {
    if (dataForm.search_input) {
      requestUrl = requestUrl + "&name=" + dataForm.search_input;
    }

    if (dataForm.status_active && dataForm.status_active !== 3) {
      const status = dataForm.status_active === 1 ? true : false;
      requestUrl = requestUrl + "&status=" + status;
    }

    if (dataForm.message_pumais && dataForm.message_pumais !== 3) {
      const message_pumais = dataForm.message_pumais === 1 ? true : false;

      requestUrl = requestUrl + "&receive_incident=" + message_pumais;
    }

    if (dataForm.message_checkin && dataForm.message_checkin !== 3) {
      const message_checkin = dataForm.message_checkin === 1 ? true : false;

      requestUrl = requestUrl + "&receive_checkin=" + message_checkin;
    }

    if (
      dataForm.type_notification_sms &&
      dataForm.type_notification_sms !== 3
    ) {
      const type_notification_sms =
        dataForm.type_notification_sms === 1 ? true : false;

      requestUrl = requestUrl + "&sms_notification=" + type_notification_sms;
    }

    if (
      dataForm.type_notification_app &&
      dataForm.type_notification_app !== 3
    ) {
      const type_notification_app =
        dataForm.type_notification_app === 1 ? true : false;

      requestUrl = requestUrl + "&push_notification=" + type_notification_app;
    }

    if (dataForm.message_corporation && dataForm.message_corporation !== 3) {
      const message_corporation =
        dataForm.message_corporation === 1 ? true : false;

      requestUrl =
        requestUrl + "&receive_message_internal=" + message_corporation;
    }
  }
  return axios.get(requestUrl);
}

function* filterGroups(action) {
  yield put({
    type: Types.REQUEST_GET_GROUPS,
    payload: action.payload
  });
}

function* getGroups(action) {
  // console.log(action.payload);
  try {
    const customer = yield select(state => state.Customers.customer);
    const group = yield select(state => state.ListGroups.group);
    let resp = null;
    if (action.payload) {
      resp = yield call(apiGet, customer, action.payload);
    } else {
      resp = yield call(apiGet, customer);
    }

    yield put({
      type: Types.SUCCESS_GET_GROUPS,
      payload: resp.data
    });
    if (group) {
      yield put({
        type: Types.CHANGE_GROUP,
        payload: group
      });
    } else {
      if (resp.data && typeof resp.data != undefined && resp.data.length > 0) {
        yield put({
          type: Types.CHANGE_GROUP,
          payload: resp.data[0]
        });
      }
    }
  } catch (error) {
    yield put({
      type: Types.FAILURE_GET_GROUPS
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(Types.REQUEST_GET_GROUPS, getGroups),
    takeLatest(Types.FILTER_GROUP_LIST, filterGroups)
  ]);
}
