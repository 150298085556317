import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import SearchIcon from "@material-ui/icons/Search";

import * as CoverageAreasAction from '../../../../../store/ducks/coverageArea';

import { 
  ListContainer, 
  ListItemWrapper, 
  PaperContainer, 
  Input, 
  Loading 
} from './styles';

function CustomList({ change, action }) {
  const [searchInput, setSearchInput] = useState('');

  const GroupAction =
    action === 'create'
      ? require('../../../../../store/ducks/group/create')
      : require('../../../../../store/ducks/group/edit');

  const coverageAreasSelected = useSelector(state => 
    action === 'create' 
    ? state.GroupCreate.coverageAreas : state.GroupEdit.coverageAreas
  );
  
  const { loadingCustom: loading, dataCustom: coverageAreas } = useSelector(
    state => state.CoverageArea
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CoverageAreasAction.getCustomCoverageAreas());    
  }, [dispatch]);

  const changeInputSearch = () => event => {
    setSearchInput(event.target.value);
  };

  const checkValue = id => {
    let value = false;
      coverageAreasSelected.forEach(item => {
      if (item.id === id) value = true;
    });

    return value;
  };
  
  const changeCoverage = item => event => {
    let data = coverageAreasSelected;
    if (event.target.checked) {
      data.push(item);

      dispatch(GroupAction.changeZoom(item.polygon.coordinates));
      dispatch(GroupAction.addCoverage(data));
      change(item.name);
    } else {
      const result = data.filter(obj => obj.id !== item.id);
      dispatch(GroupAction.removeCoverage(result));
      change(item.name);    
    }
  };
  
  return (
    <>
      {!loading ? (
        <>
          <PaperContainer>
            <IconButton disabled={true} aria-label="Search" type="submit">
              <SearchIcon />
            </IconButton>
            <Input
              name="search_input_coverage_area"
              onChange={changeInputSearch()}
              placeholder="Procurar por área de abrangência personalizada ..."
              id="search_input_coverage_area"
            />
          </PaperContainer>
          <ListContainer>
            {coverageAreas &&
              coverageAreas.map(item => {
                if (
                  searchInput === "" ||
                  item.name
                    .toUpperCase()
                    .includes(
                      searchInput.toUpperCase()
                    )
                ) {
                  return (
                    <ListItemWrapper key={item.id}>
                      <ListItemText primary={item.name} />
                      <Switch
                        checked={checkValue(item.id)}
                        onClick={changeCoverage(item)}
                        color="primary"
                      />
                    </ListItemWrapper>
                  );
                }
                return false;
              })}
          </ListContainer>
        </>
      ) : (
        <Loading>
          <CircularProgress color="primary" size={50} />
        </Loading>
      )}
    </>
  );
}

CustomList.defaultProps = {
  action: '',
};

CustomList.propTypes = {
  change: PropTypes.func.isRequired,
  action: PropTypes.string,
};

export default CustomList;
