import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

export const Root = styled.div`
  @media (min-width: 600px) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  @media (max-width: 599px) {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

export const Container = styled.div`
  background: #efefef;

  @media (min-width: 600px) {
    top: 0;
    left: 0;
    width: 300px;
    bottom: 0;
    z-index: 9999;
    position: absolute;
  }

  @media (max-width: 599px) {
    flex: 8;
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerInfos = styled.div`
  padding: 20px;

  @media (max-width: 599px) {
    text-align: left;
  }

  @media (min-width: 600px) {
    margin-top: 10px;
  }
`;

export const ImageContainer = styled.div`
  background-color: #26408b;
  background-image: none;
  box-shadow: #ccc 0px 1px 2px;
`;

export const ImageSpacing = styled.div`
  padding: 20px;
  text-align: center;

  @media (max-width: 959.95px) {
    align-self: center;
    padding: 10px;
  }
`;

export const ImageLogo = styled.img`
  max-width: 100%;
  width: 210px;

  @media (max-width: 959.95px) {
    width: 180px;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 5;

  @media (min-width: 600px) {
    margin-left: 300px;
  }
  @media (max-width: 959.95px) {
    display: flex;
  }
`;

export const Loading = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  @media (min-width: 600px) {
    height: 300px;
  }
`;

export const Paragraphy = styled(Typography)`
  margin-top: 10px !important;
  font-size: 14px !important;

  small {
    color: #26408b;
    text-transform: uppercase;
  }

  span {
    word-break: ${props => (props.breakword ? 'break-word' : '')};
  }
`;
