import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import Fab from "@material-ui/core/Fab";
import MaterialInputSelectRedux from "../../../material/Inputs/select";
import MaterialSearchRedux from "../../../material/Inputs/search";

import { reduxForm, Field, formValueSelector } from "redux-form";

import * as GroupListAction from '../../../../store/ducks/group/list';

import { 
  SubmitContainer, 
  TitleContainer, 
  Container,
  Content,
  StatusContainer, 
  GridContent,
  GridContainer, 
  CloseButtonContainer 
} from './styles';

let countFilters = 0;

let ContactFilter = ({ timeout, handleSubmit }) => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const selector = formValueSelector("FILTER_GROUP_FORM");
  
  const search_input = useSelector(state => selector(state, "search_input"));
  const status_active = useSelector(state => selector(state, "status_active"));
  const message_pumais = useSelector(state => selector(state, "message_pumais"));
  const message_corporation = useSelector(state => selector(state, "message_corporation"));
  const message_checkin = useSelector(state => selector(state, "message_checkin"));
  const type_notification_app = useSelector(state => selector(state, "type_notification_app"));
  const type_notification_sms = useSelector(state => selector(state, "type_notification_sms"));

  const valuesForm = {
    search_input,
    status_active,
    message_pumais,
    message_corporation,
    message_checkin,
    type_notification_app,
    type_notification_sms
  }

  const submit = values => {
    dispatch(GroupListAction.filterGroups(values));
  };

  const changeInputSearch = () => event => {
    var searchText = event.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(GroupListAction.filterGroups({ search_input: searchText }));
    }, 1000);
  };
  
  const filter = () => {
    setOpen(false);
    if (countFilters !== 0) submit(valuesForm);
  };

  countFilters = 0;
  if (status_active && status_active !== 3)
    countFilters++;
  if (message_pumais && message_pumais !== 3)
    countFilters++;
  if (message_corporation && message_corporation !== 3)
    countFilters++;
  if (message_checkin && message_checkin !== 3)
    countFilters++;
  if (
    type_notification_app &&
    type_notification_app !== 3
  )
    countFilters++;
  if (
    type_notification_sms &&
    type_notification_sms !== 3
  )
    countFilters++;

  return (
    <Container>
      <Paper>
        <form
          onSubmit={handleSubmit(values => submit(values))}
        >
          <GridContainer>
            <Grid
              container
              alignContent={"center"}
              alignItems={"center"}
              spacing={0}
            >
              <Grid
                item
                xs={10}
              >
                <IconButton aria-label="Search" type="submit">
                  <SearchIcon />
                </IconButton>
                <div className="search">
                  <Field
                    name="search_input"
                    onChange={changeInputSearch()}
                    placeholder="Procurar por descrição do grupo ..."
                    component={MaterialSearchRedux}
                    id="search_input"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <GridContent>
                  <Typography
                    variant="h6"
                    component="h5"
                  >
                    Filtros
                    <IconButton
                      onClick={() => setOpen(!open)}
                      aria-label="Filter List"
                    >
                      <FilterListIcon />
                    </IconButton>
                    {countFilters > 0 && (
                      <Chip
                        label={"+" + countFilters}
                        size="small"
                      />
                    )}
                  </Typography>
                </GridContent>
              </Grid>
            </Grid>
          </GridContainer>

          <Drawer
            anchor="right"
            open={open}
            onClose={() => {
              setOpen(!open);
              submit(valuesForm);
            }}
          >
            <Content>
              <Typography
                variant="h6"
                component="h5"
              >
                Filtros
              </Typography>
              <CloseButtonContainer>
                <IconButton
                  onClick={() => setOpen(!open)}
                >
                  <CloseIcon />
                </IconButton>
              </CloseButtonContainer>
              <Divider />
              <StatusContainer>
                <Field
                  name="status_active"
                  inputLabel="Status"
                  data={[
                    {
                      id: 3,
                      name: "Todos"
                    },
                    {
                      id: 1,
                      name: "Ativo"
                    },
                    {
                      id: 2,
                      name: "Desativado"
                    }
                  ]}
                  component={MaterialInputSelectRedux}
                  id="status_active"
                />
              </StatusContainer>

              <TitleContainer>
                <Typography
                  variant="h6"
                  component="h5"
                >
                  Mensagem
                </Typography>
                <Field
                  name="message_pumais"
                  inputLabel="Alerta - PUMAIS"
                  data={[
                    {
                      id: 3,
                      name: "Todos"
                    },
                    {
                      id: 1,
                      name: "Sim"
                    },
                    {
                      id: 2,
                      name: "Não"
                    }
                  ]}
                  component={MaterialInputSelectRedux}
                  id="message_pumais"
                />

                <Field
                  name="message_corporation"
                  inputLabel="Mass Notifications"
                  data={[
                    {
                      id: 3,
                      name: "Todos"
                    },
                    {
                      id: 1,
                      name: "Sim"
                    },
                    {
                      id: 2,
                      name: "Não"
                    }
                  ]}
                  component={MaterialInputSelectRedux}
                  id="message_corporation"
                />

                <Field
                  name="message_checkin"
                  inputLabel="Check in"
                  data={[
                    {
                      id: 3,
                      name: "Todos"
                    },
                    {
                      id: 1,
                      name: "Sim"
                    },
                    {
                      id: 2,
                      name: "Não"
                    }
                  ]}
                  component={MaterialInputSelectRedux}
                  id="message_checkin"
                />
              </TitleContainer>

              <TitleContainer>
                <Typography
                  variant="h6"
                  component="h5"
                >
                  Tipo de notificação
                </Typography>

                <Field
                  name="type_notification_sms"
                  inputLabel="SMS"
                  data={[
                    {
                      id: 3,
                      name: "Todos"
                    },
                    {
                      id: 1,
                      name: "Sim"
                    },
                    {
                      id: 2,
                      name: "Não"
                    }
                  ]}
                  component={MaterialInputSelectRedux}
                  id="type_notification_sms"
                />

                <Field
                  name="type_notification_app"
                  inputLabel="Notificação via App"
                  data={[
                    {
                      id: 3,
                      name: "Todos"
                    },
                    {
                      id: 1,
                      name: "Sim"
                    },
                    {
                      id: 2,
                      name: "Não"
                    }
                  ]}
                  component={MaterialInputSelectRedux}
                  id="type_notification_app"
                />
              </TitleContainer>
            </Content>
            <SubmitContainer>
              <Fab
                variant="extended"
                size="small"
                aria-label="Save"
                onClick={() => filter()}
              >
                Filtrar
              </Fab>
            </SubmitContainer>
          </Drawer>
        </form>
      </Paper>
    </Container>
  );
}

ContactFilter.defaultProps = {
  timeout: 1000
};

ContactFilter.propTypes = {
  timeout: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

ContactFilter = reduxForm({
  form: "FILTER_GROUP_FORM",
  asyncBlurFields: ["search_input"]
})(ContactFilter);

export default ContactFilter;
