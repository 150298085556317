import styled from 'styled-components';

import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";

export const classes = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  }
}));

export const GridContainer = styled(Grid).attrs({
  container: true,
  spacing: 1,
})`
  padding: 20px;
`;

export const GridItem = styled(Grid)`
  padding-top: 30px !important;
  padding-bottom: 30px !important;
`;

export const Loading = styled(TableCell)`
  display: flex !important;
  justify-content: center;
  
  div {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

export const NoContact = styled.div`
  h4 {
    color: #333;
    opacity: 0.6;
    text-align: center;
    margin-top: 20px;
  }
`;

export const Paginator = styled(TablePagination).attrs({
  rowsPerPageOptions: [25, 50, 100, 200],  
})`
  display: inline-flex !important;
`;