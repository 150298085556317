// types
import { setCustomer as setCustomerLocal } from "../../../services/customer";
export const Types = {
  REQUEST_GET_CUSTOMERS: "REQUEST_GET_CUSTOMERS",
  SUCCESS_GET_CUSTOMERS: "SUCCESS_GET_CUSTOMERS",
  FAILURE_GET_CUSTOMERS: "FAILURE_GET_CUSTOMERS",
  CHANGE_CUSTUMER: "CHANGE_CUSTUMER"
};

const INITIAL_VALUES = {
  loading: false,
  error: false,
  data: null,
  customer: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_GET_CUSTOMERS:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_GET_CUSTOMERS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case Types.FAILURE_GET_CUSTOMERS:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_CUSTUMER:
      return { ...state, customer: action.payload };
    default:
      return state;
  }
}

export function getCustomers() {
  return { type: Types.REQUEST_GET_CUSTOMERS };
}

export function setCustumer(value) {
  if (value && value.id) {
    setCustomerLocal(value.id);
  }
  return { type: Types.CHANGE_CUSTUMER, payload: value };
}
