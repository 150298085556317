import { takeLatest, put, call, all, select } from "redux-saga/effects";
import { Types } from "../../ducks/group/associationUsers";
import axios from "../../../services/api.js";
import { startSubmit, stopSubmit } from "redux-form";
import { Types as TypeMessage } from "../../ducks/main/message";
import { reformateArrayReduxForm } from "../../../utils/form/index";
import { Messages } from "../../../components/alert/messages";

function apiAssociationGroups(group_id, users_id) {
  const data = {
    group_id,
    users_id
  };
  return axios.post(`/group/association/users`, data);
}

function* associationUserCustomGroup(action) {
  try {
    let parameters = action.payload;
    yield call(apiAssociationGroups, parameters.group_id, [parameters.user_id]);

    /* yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.SUCCESS_FORM_ASSOCIATION_USERS_GROUP,
        variant: "success"
      }
    });*/

    window.location.replace(`/contato/editar/${parameters.user_id}`);
  } catch ({ response }) {
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.ERROR_FORM_ASSOCIATION_USERS_GROUP,
        variant: "error"
      }
    });
  }
}
function* associationUsersGroup(action) {
  yield put(startSubmit("FORM_ASSOCIATION_USERS_GROUP"));
  try {
    let parameters = action.payload;
    const group = yield select(state => state.ListGroups.group);

    let users_id = reformateArrayReduxForm(parameters.users_id);

    yield call(apiAssociationGroups, group.id, users_id);

    yield put(stopSubmit("FORM_ASSOCIATION_USERS_GROUP"));
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.SUCCESS_FORM_ASSOCIATION_USERS_GROUP,
        variant: "success"
      }
    });

    action.payload.history.push(`/grupos`);
  } catch ({ response }) {
    if (response && response.data) {
      yield put(
        stopSubmit("FORM_ASSOCIATION_USERS_GROUP", response.data.errors)
      );
    }

    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.ERROR_FORM_ASSOCIATION_USERS_GROUP,
        variant: "error"
      }
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      Types.REQUEST_FORM_ASSOCIATION_USERS_GROUP,
      associationUsersGroup
    ),
    takeLatest(
      Types.REQUEST_ASSOCIATION_USER_CUSTOM_GROUP,
      associationUserCustomGroup
    )
  ]);
}
