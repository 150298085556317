export const Messages = {
  CREDENTIALS_INVALID: "Credenciais inválidas.",
  REQUIRED_FIELDS: "Por favor, preencha os campos obrigatórios.",
  CREATE_SUCCESS_CONTACT: "Contato criado com sucesso.",
  CREATE_SUCCESS_GROUP: "Grupo criado com sucesso.",
  CREATE_ERROR_CONTACT: "Falha na tentativa de criação do contato.",
  CREATE_ERROR_CONTACT_FIELDS: "Preencha todos os campos corretamente.",
  UPDATE_SUCCESS_CONTACT: "Contato editado com sucesso.",
  UPDATE_ERROR_CONTACT_FIELDS: "Preencha todos os campos corretamente.",
  UPDATE_ERROR_CONTACT: "Falha na tentativa de edição do contato",
  LIST_DESABLE_CONTACT: "Alteração realizada com sucesso.",
  LIST_ERROR_DESABLE_CONTACT:
    "Ocorreu um erro ao tentar editar contato, tente novamente mais tarde.",
  CREATE_ERROR_GROUP: "Falha na tentativa de criação do grupo",
  SUCCESS_FORM_ASSOCIATION_USERS_GROUP:
    "Associação de usuários feita com sucesso.",
  ERROR_FORM_ASSOCIATION_USERS_GROUP:
    "Ocorreu uma falha na associação de usuários.",
  ERROR_EDIT_GROUP: "Ocorreu um erro ao tentar editar grupo.",
  SUCCESS_EDIT_GROUP: "Grupo editado com sucesso.",
  ERROR_GET_GROUP:
    "Ocorreu um erro ao tentar recuperar grupo ou grupo inexistente.",
  DELETE_ERROR_GROUP: "Falha ao excluir o grupo.",
  DELETE_SUCCESS_GROUP: "Sucesso ao deletar o grupo.",
  ERROR_500: "Ocorreu um erro inesperado, tente novamente mais tarde.",
  ERROR_422: "Por favor, preencha todos os campos.",
  CREATE_MESSAGE: "Mensagem criada com sucesso.",
  RESPONSE_CHECKIN_PT: "Check in feito com sucesso",
  ERROR_RESPONSE_CHECKIN_PT: "Falha ao realizar check in",
  RESPONSE_CHECKIN_EN: "Check in done",
  ERROR_RESPONSE_CHECKIN_EN: "Failed to check in"
};
