import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export const TableHeadContainer = styled(TableHead)`
  .MuiTableCell-head {
    background-color: #e8e9ec;
    color: #999;
    text-align: center;
    margin-top: 20px;
  }

  button {
    text-transform: none;
    font-weight: normal;
  }

  svg {
    position: absolute;
    margin-top: 4px;
  }
`;

export const StyledTableRow = styled(TableRow)`
  .MuiTableCell-body {
    font-size: 13px;
    text-align: center;
  }
  &:nth-of-type(odd) {
    background-color: #fff;
  }
`;

export const IconContainer = styled(IconButton)`
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
`;

export const Loading = styled(Grid).attrs({
  container: true,
  direction: 'row',
})`
  min-height: 600px;
  min-width: 600px;
  overflow-x: hidden;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const Search = styled.div`
  display: flex;
  flex-direction: row;

  .MuiInputBase-root {
    width: 80%;
  }
`;

export const GridContainer = styled(Grid).attrs({
  container: true,
  direction: 'row',
})`
  min-height: 600px;
  min-width: 1200px;
  overflow-x: hidden;

  .MuiGrid-spacing-xs-5 {
    max-height: 600px;
  }

  table {
    margin-top: 10px;
  }
`;

export const LocateIcon = styled(LocationOnIcon)`
  font-size: 2.1875rem;
  color: #808080;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin: auto;

  button {
    text-transform: none;
  }

  & button[aria-pressed='true'] {
    background-color: #26408b;
    color: white;
  }
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  h2 {
    margin-top: 50px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
`;
