import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -50px;
  margin-left: 30px;
  margin-bottom: 20px;

  input {
    display: none;
  }
`;

export const FileList = styled.ul`
  max-width: 470px;
  margin: 0px 45px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }

  button {
    background: none;
    border: none;
  }

  svg {
    color: #26408b;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -20px;

  span {
    font-size: 14px;
    margin-left: 10px;
  }
`;

export const Error = styled.div`
  span {
    color: #ab0202c4;
    font-size: 12px;
    margin-left: 4px;
  }
`;
