import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Polygon, InfoWindow } from "react-google-maps";
import Typography from "@material-ui/core/Typography";

class AreasPolygon extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      positionInfoWindow: null,
      fillColor: "#26408B"
    };

    this._startBlinkRiskLayer();
  }

  _startBlinkRiskLayer = () => {
    const fillColors = { "#990000": "#FF0000", "#FF0000": "#990000" };

    this.setState({ fillColor: fillColors[this.state.fillColor] });
  };

  showWindow = event => {
    this.setState({
      positionInfoWindow: { lat: event.latLng.lat(), lng: event.latLng.lng() }
    });
  };

  render() {
    let area = this.props.area.map(item => {
      return { lat: item.lat(), lng: item.lng() };
    });
    const opts = {
      strokeColor: this.state.fillColor,
      strokeOpacity: 0.9,
      strokeWeight: 2,
      fillColor: "#0095ff",
      fillOpacity: 0.3
    };
    return (
      <Fragment>
        <div>
          {(this.state.positionInfoWindow || false) !== false && (
            <InfoWindow
              position={this.state.positionInfoWindow}
              onCloseClick={() =>
                this.setState({
                  positionInfoWindow: null
                })
              }
              options={{ disableAutoPan: false }}
            >
              <div>
                <Typography
                  variant="h6"
                  component="h5"
                  style={{
                    fontWeight: 400,
                    color: "#333333",
                    opacity: 0.6,
                    textAlign: "center"
                  }}
                >
                  {this.props.name}
                </Typography>
              </div>
            </InfoWindow>
          )}
        </div>
        <Polygon paths={area} onMouseDown={this.showWindow} options={opts} />
      </Fragment>
    );
  }
}
AreasPolygon.propTypes = {
  name: PropTypes.string,
  area: PropTypes.array
};

export default AreasPolygon;
