import React, { Component } from "react";
import Map from "../../../map/index";
import Areas from "../../../map/areas";
import Marker from "react-google-maps/lib/components/Marker";

class CoverageAreas extends Component {

  renderArea(area) {
    if (area.point && area.polygon === null) {
      const [lng, lat] = area.point.coordinates;
      const position = new window.google.maps.LatLng(lat, lng);
      return <Marker position={position}></Marker>
    }

    if (area.polygon !== null) {
      return area.polygon.coordinates
        .map((coordinate, index) => {
          let coordinateToDecode = null;

          if (area.polygon.coordinates.length === 1) {
            coordinateToDecode = Array.isArray(coordinate) ? coordinate[0] : coordinate
          } else {
            coordinateToDecode = coordinate[0];
          }

          return (
            <Areas
              key={area.id + index}
              name={area.name}
              area={window.google.maps.geometry.encoding.decodePath(coordinateToDecode)}
            />
          )
        });
    }

    return null;
  }

  render() {
    return (
      <Map zoomArea={this.props.zoomArea}>
        {
          this.props.areas &&
          this.props.areas.length > 0 &&
          this.props.areas.map(area => this.renderArea(area))
        }
      </Map>
    );
  }
}

export default CoverageAreas;
