import { takeLatest, put, call, all, select } from "redux-saga/effects";
import { Types } from "../../ducks/group/edit";
import axios from "../../../services/api.js";
import {
  reformateErrors,
  reformateArrayFieldReduxForm
} from "../../../utils/form";

import { startSubmit, stopSubmit } from "redux-form";
import { Types as TypeMessage } from "../../ducks/main/message";
import { Messages } from "../../../components/alert/messages";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(id_group) {
  return axios.get(`/group/${id_group}`);
}

function apiSave(dataForm, customer, days, areas) {
  const parameters = dataForm.parameters;
  let schedule_start = "00:00";
  let schedule_end = "23:59";
  let data = null;

  let critical_to_traffic = reformateArrayFieldReduxForm(
    parameters.category_incidents,
    false
  )
    ? reformateArrayFieldReduxForm(
        parameters.category_incidents,
        false
      ).includes(2)
    : false;

  // if (receives_incident) {
  if (
    parameters.schedules &&
    parameters.schedules.length > 0 &&
    parameters.schedules[1].value === true
  ) {
    schedule_start = parameters.schedule_start;
    schedule_end = parameters.schedule_end;
  }
  data = {
    name: parameters.name,
    coverageAreas: areas,
    status_active: parameters.status_active,
    languages: reformateArrayFieldReduxForm(parameters.languages, false),
    receives: reformateArrayFieldReduxForm(parameters.receives, false),
    days,
    category_incidents: reformateArrayFieldReduxForm(
      parameters.category_incidents,
      false
    ),
    notification_types: reformateArrayFieldReduxForm(
      parameters.notification_types,
      false
    ),
    schedule_start,
    schedule_end,
    customer_id: customer.id
  };
  if (critical_to_traffic)
    data.critical_to_traffic = parameters.critical_to_traffic;
  /*} else {
    data = {
      name: parameters.name,
      status_active: parameters.status_active,
      receives: reformateArrayFieldReduxForm(parameters.receives, false),
      notification_types: reformateArrayFieldReduxForm(
        parameters.notification_types,
        false
      ),
      customer_id: customer.id,
      application_id: process.env.REACT_APP_APPLICATION_ID
    };
  }*/

  return axios.post(`/group/update/${dataForm.id}`, {
    ...data
  });
}
function* getGroup(action) {
  try {
    const resp = yield call(apiGet, action.payload);
    yield put({
      type: Types.SUCCESS_GET_GROUP,
      payload: resp.data
    });
  } catch (error) {
    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.ERROR_GET_GROUP,
        variant: "error"
      }
    });
  }
}

function* saveGroup(action) {
  yield put(startSubmit("GROUP_EDIT_FORM"));
  try {
    const customer = yield select(state => state.Customers.customer);
    let areas = yield select(state => state.GroupEdit.coverageAreas);
    const days = yield select(state => state.GroupEdit.daysWeek);
    areas = areas.map(item => {
      return item.id;
    });
    yield call(
      apiSave,
      action.payload,
      customer,
      days,
      areas.length > 0 ? areas : null
    );
    yield put(stopSubmit("GROUP_EDIT_FORM"));

    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.SUCCESS_EDIT_GROUP,
        variant: "success"
      }
    });

    window.location.replace(process.env.PUBLIC_URL + "/grupos");
  } catch (error) {
    if (error.response.data.errors) {
      yield put(
        stopSubmit(
          "GROUP_EDIT_FORM",
          reformateErrors(error.response.data.errors)
        )
      );
    }

    yield put({
      type: TypeMessage.MESSAGE,
      payload: {
        message: Messages.ERROR_EDIT_GROUP,
        variant: "error"
      }
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(Types.REQUEST_GET_GROUP, getGroup),
    takeLatest(Types.REQUEST_GROUP_EDIT_FORM, saveGroup)
  ]);
}
