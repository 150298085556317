import styled from 'styled-components';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const Timeline = styled(VerticalTimeline)``;

export const TimelineItem = styled(VerticalTimelineElement)`
  & span[class='vertical-timeline-element-date'] {
    font-size: 13px !important;
    padding: 10px;
  }

  p {
    font-size: 11px;
  }
`;

export const LinkButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;

  strong {
    margin-bottom: 10px;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 242px;

    div + div {
      margin-left: 10px;
    }
  }
`;