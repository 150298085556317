import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";

class MaterialSearchRedux extends Component {
  renderInput() {
    const {
      placeholder,
      input,
      type,
      meta: { error }
    } = this.props;
    const hasError = (error || false) !== false;
    return (
      <FormControl margin="none" fullWidth>
        <InputBase
          {...input}
          type={type}
          error={hasError}
          placeholder={placeholder}
        />
      </FormControl>
    );
  }

  render() {
    return <div>{this.renderInput()}</div>;
  }
}

export default MaterialSearchRedux;
