import styled from 'styled-components';

import List from '@material-ui/core/List';

export const NumberContainer = styled.div`
  font-weight: 400;
  color: #333333;
  font-size: 14;
  margin-left: 5px;

  span {
    display: inline-block;
    background-color: ${props => props.color};
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
`;

export const ListContainer = styled(List)`
  max-width: 100%;
  max-height: 200px;

  li {
    border-bottom: 1px solid #ccc;

    .MuiInputBase-root {
      width: 100%;
    }

    .MuiListItemText-primary {
      .MuiTypography-body1 {
        font-weight: bold;
        font-size: 17px;
        color: #333;
      }

      span {
        font-weight: 400;
        color: #333;
        font-size: 14px;

        span {
          display: inline-block;
          background-color: #1519d6;
          border-radius: 50%;
          width: 15px;
          height: 15px;
        }
      }
    }

    .MuiListItemText-secondary {
      .status-color {
        display: flex;

        > div {
          display: inline-flex;
        }
      }

      .MuiTypography-body1 {
        display: block;
        font-weight: 600;
        font-size: 15px;
        color: #333;

        .MuiSvgIcon-root {
          color: black;
          font-size: 1.25rem;
        }

        .group-name {
          font-weight: 200;
        }

        span {
          font-weight: 400;
          color: #333;
          font-size: 14px;
          margin-left: 5px;

          span {
            display: inline-block;
            background-color: #eb130c;
            border-radius: 50%;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
`;
