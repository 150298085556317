// types
export const Types = {
  CHANGE_PAGE_CREATE_MESSAGE: 'CHANGE_PAGE_CREATE_MESSAGE',
  REQUEST_CREATE_MESSAGE: 'REQUEST_CREATE_MESSAGE',
  CLOSE_MODAL: 'CLOSE_MODAL',
  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE: 'DELETE_FILE',
  FILE_ERROR: 'FILE_ERROR',
  RESET_FILE_STATE: 'RESET_FILE_STATE',
};

const INITIAL_VALUES = {
  page: 0,
  files: [],
  error: false,
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.CHANGE_PAGE_CREATE_MESSAGE:
      return { ...state, page: action.payload, error: false };
    case Types.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        files: [...state.files, ...action.payload.files],
        error: false,
      };
    case Types.DELETE_FILE_SUCCESS:
      return { ...state, files: action.payload.files, error: false };
    case Types.FILE_ERROR:
      return { ...state, error: true };
    case Types.RESET_FILE_STATE:
      return { ...INITIAL_VALUES };
    default:
      return state;
  }
}

export function createMessage(payload) {
  return { type: Types.REQUEST_CREATE_MESSAGE, payload };
}

export function setPage(payload) {
  return { type: Types.CHANGE_PAGE_CREATE_MESSAGE, payload };
}

export function uploadFile(payload) {
  return { type: Types.UPLOAD_FILE_REQUEST, payload };
}

export function deleteFile(payload) {
  return { type: Types.DELETE_FILE, payload };
}
