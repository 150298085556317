// types
export const Types = {
  REQUEST_CHECKIN_GET_CONTACT: "REQUEST_CHECKIN_GET_CONTACT",
  SUCCESS_CHECKIN_GET_CONTACT: "SUCCESS_CHECKIN_GET_CONTACT",
  FAILURE_CHECKIN_GET_CONTACT: "FAILURE_CHECKIN_GET_CONTACT",
  CHANGE_CHECKIN_CONTACT: "CHANGE_CHECKIN_CONTACT",
  REQUEST_CHECKIN_CONTACT: "REQUEST_CHECKIN_CONTACT"
};
const INITIAL_VALUES = {
  loading: null,
  error: false,
  data: null,
  contacts: []
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_CHECKIN_GET_CONTACT:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_CHECKIN_GET_CONTACT:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case Types.FAILURE_CHECKIN_GET_CONTACT:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_CHECKIN_CONTACT:
      const contacts = [...new Array([...action.payload])][0];
      return {
        ...state,
        contacts
      };

    default:
      return { ...state };
  }
}

export function getContacts(payload) {
  return { type: Types.REQUEST_CHECKIN_GET_CONTACT, payload };
}

export function changeContactSelecteds(payload) {
  return { type: Types.REQUEST_CHECKIN_CONTACT, payload };
}
