import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function MaterialCheckboxRedux({
  error,
  errorText,
  inputLabel,
  input,
  selected,
  style,
  disabled = false,
  styleCheckbox
}) {
  if (!input.value) {
    input.value = false;
  }
  return (
    <FormControl error={error}>
      <FormControlLabel
        {...input}
        labelPlacement="end"
        label={inputLabel}
        disabled={disabled}
        control={
          <Checkbox
            style={styleCheckbox}
            checked={input.value}
            color="primary"
          />
        }
      />
      {error && (
        <FormHelperText id="component-error-text">{errorText}</FormHelperText>
      )}
    </FormControl>
  );
}
