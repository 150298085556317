// types
export const Types = {
  REQUEST_FORM_ASSOCIATION_USERS_GROUP: "REQUEST_FORM_ASSOCIATION_USERS_GROUP",
  REQUEST_ASSOCIATION_USER_CUSTOM_GROUP: "REQUEST_ASSOCIATION_USER_CUSTOM_GROUP"
};

export function associationUsersGroup(parameters, history) {
  return {
    type: Types.REQUEST_FORM_ASSOCIATION_USERS_GROUP,
    payload: { ...parameters, history }
  };
}

export function associationUserCustomGroup(user_id, group_id) {
  return {
    type: Types.REQUEST_ASSOCIATION_USER_CUSTOM_GROUP,
    payload: { user_id, group_id }
  };
}
