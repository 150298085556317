import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import NotificationIcon from '@material-ui/icons/NotificationImportant';
import SmsIcon from '@material-ui/icons/Sms';

import {
  changeContactSelecteds,
  getContacts,
} from '~/store/ducks/message/create/contacts';
import { setPage } from '~/store/ducks/message/create/index';
import { setGroup } from '~/store/ducks/message/create/groups';

import {
  ListContainer,
  ListChild,
  SelectedButton,
  SearchContainer,
  TitleButton,
  NoDataTitle,
  Loading,
} from './styles';

function MessageCreateGroup() {
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();

  const { data: groups, loading: loadingGroup } = useSelector(
    state => state.CreateMessageGroup
  );
  const { data: customers } = useSelector(state => state.CreateMessageCustomer);
  const { contacts: contactsSelected } = useSelector(
    state => state.CreateMessageContacts
  );

  const changeGroup = id => {
    dispatch(setGroup(id));
    dispatch(getContacts(id));
    dispatch(setPage(2));
  };

  const getAllContactGroup = group => {
    let count = 0;
    if (
      (contactsSelected[group.id] || false) &&
      contactsSelected[group.id].users
    ) {
      contactsSelected[group.id].users.forEach(item => {
        if (group.users.filter(user => user.id === item.id)) count += 1;
      });
    }
    return count;
  };

  const setAllContacts = group => event => {
    if ((event.target || false) !== false)
      dispatch(
        changeContactSelecteds({
          group,
          checked: event.target.checked,
          setAll: true,
        })
      );
  };

  const changeInputSearch = () => event => {
    setSearchInput(event.target.value);
  };

  let ArrowBack = <TitleButton color="default">Grupos</TitleButton>;
  if (customers && customers.length > 1)
    ArrowBack = (
      <TitleButton
        variant="text"
        onClick={() => dispatch(setPage(0))}
        color="default">
        <ArrowBackIcon style={{ position: 50 }} />
        Grupos
      </TitleButton>
    );
  const groupsRender = [];
  if ((groups || false) !== false) {
    groups.forEach(item => {
      if (
        searchInput === '' ||
        item.name.toUpperCase().includes(searchInput.toUpperCase())
      ) {
        groupsRender.push(
          <ListChild button key={item.id}>
            <Checkbox
              onClick={setAllContacts(item)}
              checked={
                (item.users.length === getAllContactGroup(item) &&
                  item.users.length !== 0) ||
                false
              }
              indeterminate={
                (getAllContactGroup(item) < item.users.length &&
                  item.users.length !== 0 &&
                  getAllContactGroup(item) !== 0) ||
                false
              }
              color="primary"
            />
            <ListItemText primary={item.name} />
            {item.groupInfo.push_notification && (
              <Tooltip
                title="Recebe notificação"
                aria-label="receive notification">
                <NotificationIcon color="primary" />
              </Tooltip>
            )}
            {item.groupInfo.sms_notification && (
              <Tooltip title="Recebe SMS" aria-label="receive sms">
                <SmsIcon color="primary" />
              </Tooltip>
            )}
            <SelectedButton onClick={() => changeGroup(item.id)}>
              {`${getAllContactGroup(item)} `}
              selecionados
            </SelectedButton>
          </ListChild>
        );
      }
    });
  }
  const Groups =
    !loadingGroup && (groups || false) !== false ? (
      <div>
        {ArrowBack}
        <SearchContainer>
          <IconButton disabled aria-label="Search" type="submit">
            <SearchIcon />
          </IconButton>
          <InputBase
            name="search_input"
            onChange={changeInputSearch()}
            placeholder="Procurar por nome do grupo ..."
            id="search_input"
          />
        </SearchContainer>
        {groups.length > 0 ? (
          <ListContainer>
            {groupsRender.length > 0 ? (
              groupsRender
            ) : (
              <NoDataTitle variant="h5" component="h4">
                Nenhum grupo encontrado.
              </NoDataTitle>
            )}
          </ListContainer>
        ) : (
          <NoDataTitle variant="h5" component="h4">
            Nenhum grupo encontrado.
          </NoDataTitle>
        )}
      </div>
    ) : (
      <Loading>
        <CircularProgress color="primary" size={50} />
      </Loading>
    );

  return <div>{Groups}</div>;
}

export default MessageCreateGroup;
