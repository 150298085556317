import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import 'moment/min/locales';

import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FiAlertCircle, FiCheckCircle, FiSlash } from 'react-icons/fi';
import * as GroundAction from '~/store/ducks/message/list';
import { Timeline, TimelineItem, LinkButton } from './styles';

moment.locale('pt');

function TimeLineIncident() {
  let linkGroundCounter = 1;

  const {
    groundsLoaded,
    messageDetail: messagesIncident,
    grounds: groundsIncident,
  } = useSelector(state => state.MessageList);

  const dispatch = useDispatch();

  useEffect(() => {
    const data = messagesIncident;
    dispatch(GroundAction.getGroundMessage(data.message_id || data.id));
  }, [dispatch, messagesIncident]);

  function regexActions(link = null) {
    const linkGround = link => {
      const twitter = link.match(/https:\/\/twitter.com\/.*/);
      const facebook = link.match(/https:\/\/facebook.com\/.*/);

      if (twitter) {
        return 'Twitter';
      }
      if (facebook) {
        return 'Facebook';
      }
      return 'Link';
    };
    return linkGround(link);
  }

  function groundStatusStyle(slug, index) {
    if (index !== 0) {
      return { color: '#9E9E9E', icon: <FiSlash /> };
    }

    if (slug === 'going-on') {
      return {
        color: '#D42A2A',
        icon: <FiAlertCircle />,
      };
    }
    if (slug === 'normalized') {
      return {
        color: '#2288FF',
        icon: <FiCheckCircle />,
      };
    }
    if (slug === 'remains') {
      return {
        color: '#FFBB22',
        icon: <FiAlertCircle />,
      };
    }
  }

  const dataIncident = groundsIncident.map((d, index) => ({
    ...d,
    colorStyle: groundStatusStyle(d.status.slug, index),
    dateFormated: moment(d.date).format('DD [de] MMMM [de] YYYY [às] LT'),
  }));

  return (
    <>
      {!groundsLoaded && !messagesIncident.message_incident ? (
        <CircularProgress color="primary" size={50} />
      ) : (
        <Timeline>
          {dataIncident.map((grounds, index) => {
            return (
              <TimelineItem
                key={index}
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#eee', color: '#000' }}
                contentArrowStyle={{
                  borderRight: `7px solid  ${grounds.colorStyle.color}`,
                }}
                date={grounds.dateFormated}
                iconStyle={{
                  background: grounds.colorStyle.color,
                  color: '#fff',
                }}
                icon={grounds.colorStyle.icon}>
                <h4 className="vertical-timeline-element-title">
                  {grounds.status.name}
                </h4>
                <p className="vertical-timeline-element-subtitle">
                  {grounds.text}
                </p>
                {grounds.links.length > 0 && (
                  <LinkButton>
                    <strong>Links</strong>
                    <div>
                      {grounds.links.map((incident, index) => (
                        <Chip
                          key={index}
                          label={
                            regexActions(incident.description) === 'Link'
                              ? `Link ${linkGroundCounter++}`
                              : '' || regexActions(incident.description)
                          }
                          color="primary"
                          clickable
                          onClick={() => {
                            window.open(incident.description, '_blank');
                          }}
                        />
                      ))}
                    </div>
                  </LinkButton>
                )}
              </TimelineItem>
            );
          })}
        </Timeline>
      )}
    </>
  );
}

export default TimeLineIncident;
