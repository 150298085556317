import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Rating from '@material-ui/lab/Rating';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationIcon from '@material-ui/icons/NotificationImportant';
import SmsIcon from '@material-ui/icons/Sms';
import CloseIcon from '@material-ui/icons/Close';

import { Marker } from 'react-google-maps';
import { change } from 'redux-form';
import moment from 'moment';
import Reply from '@material-ui/icons/Reply';
import Map from '~/components/map';

import TimeLine from '../Timeline';
import DetailContacts from '../DetailContacts';
import DetailGroups from '../DetailGroups';

import { setOpenCreate } from '~/store/ducks/message/list';

import { category, criticality } from '../Categorys';

import {
  TitleButton,
  GridContainer,
  GridContent,
  Informations,
  ContactsContainer,
  Files,
} from './styles';

function MessageDetail({ open, handleClose }) {
  const dispatch = useDispatch();

  const { messageDetail: message, loadingMessageDetail } = useSelector(
    state => state.MessageList
  );

  const dateFormated =
    message && moment(message.createdAt).format('DD/MM/YYYY - HH:mm');

  const renderLoading = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Detalhes da mensagem'}
        </DialogTitle>
        <TitleButton onClick={handleClose}>
          <CloseIcon />
        </TitleButton>
        <Divider />

        <DialogContent>
          <GridContainer>
            <CircularProgress color="primary" size={50} />
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  if (loadingMessageDetail) {
    return renderLoading();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      scroll="body"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {'Detalhes da mensagem'}
      </DialogTitle>
      <TitleButton onClick={handleClose}>
        <CloseIcon />
      </TitleButton>
      <Divider />
      <DialogContent>
        <GridContent>
          <Grid item xs={7}>
            <Grid container>
              <Informations
                header={message.message_incident ? 1 : 0}
                item
                xs={4}>
                {!message.message_incident && (
                  <>
                    {message.type_message === 'CORPORATE'
                      ? category('Comunicado')
                      : category('Mass Notification')}
                  </>
                )}
                {message.message_incident &&
                  category(
                    message.message_incident.category.id,
                    message.message_incident.criticality
                  )}
                {message.sms_notification || message.push_notification ? (
                  <>
                    {message.push_notification && (
                      <Tooltip
                        title="Enviado por notificação"
                        aria-label="receive notification">
                        <NotificationIcon fontSize="small" />
                      </Tooltip>
                    )}

                    {message.sms_notification && (
                      <Tooltip title="Enviado por SMS" aria-label="receive sms">
                        <SmsIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </>
                ) : null}
              </Informations>

              <Informations
                header={message.message_incident ? 1 : 0}
                item
                xs={
                  message.type_message === 'CORPORATE' ||
                  message.type_message === 'MASS_NOTIFICATION'
                    ? 7
                    : 4
                }>
                {message.files?.map((file, index) => (
                  <Files key={file.path}>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      {`Anexo ${index + 1}`}
                    </a>
                  </Files>
                ))}
                {message.message_incident ? (
                  <Rating
                    value={message.message_incident.credibility}
                    readOnly
                    size="small"
                  />
                ) : null}
              </Informations>

              <Informations
                header={message.message_incident ? 1 : 0}
                item
                xs={4}>
                {message.message_incident ? (
                  <Chip
                    className="criticality"
                    label={criticality(message.message_incident.criticality)}
                    size="small"
                  />
                ) : null}

                {message.message_incident ? (
                  <Chip
                    className="language"
                    label={
                      message.message_incident.language.id === 1 ? 'PT' : 'EN'
                    }
                    size="small"
                    variant="outlined"
                  />
                ) : null}
              </Informations>

              <Informations border="true" item xs={12}>
                <div className="description">
                  <Typography variant="h6" component="h4" align="center">
                    {message.description}
                  </Typography>
                </div>
              </Informations>
              {message.incident && (
                <Informations border="true" item xs={12}>
                  <div className="map-container">
                    <Map
                      zoom={15}
                      center={{
                        lat: message.incident.location.lat,
                        lng: message.incident.location.lng,
                      }}>
                      <Marker
                        position={{
                          lat: message.incident.location.lat,
                          lng: message.incident.location.lng,
                        }}
                      />
                    </Map>
                  </div>
                </Informations>
              )}

              {message.message_incident && <TimeLine />}

              <Grid className="footer-row-1" item xs={6}>
                <Typography className="footer-subtitle">Data</Typography>
                <Typography className="footer-subtitle-content">
                  {dateFormated}
                </Typography>
              </Grid>

              <Grid className="footer-row-1" item xs={6}>
                <Typography className="footer-subtitle">Tipo</Typography>
                <Typography className="footer-subtitle-content">
                  {message.message_incident && <> {'Alerta PUMAIS'}</>}
                  {!message.message_incident && (
                    <>
                      {message.type_message === 'CORPORATE'
                        ? ' Comunicado'
                        : ' Mass Notification'}
                    </>
                  )}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography className="footer-subtitle">Enviado Por</Typography>
                <Typography className="footer-subtitle-content">
                  {message.created_by_contact_name}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Chip
                  label="Encaminhar mensagem"
                  color="primary"
                  icon={<Reply />}
                  clickable
                  onClick={() => {
                    setTimeout(() => {
                      handleClose();

                      change(
                        'MESSAGE_CREATE_FORM',
                        `description`,
                        message.description
                      );
                    }, 400);

                    dispatch(setOpenCreate(true));
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <ContactsContainer item xs={5}>
            {message.groups ? (
              <DetailGroups groups={message.groups} />
            ) : (
              <DetailContacts message={message} contacts={message.contacts} />
            )}
          </ContactsContainer>
        </GridContent>
      </DialogContent>
    </Dialog>
  );
}

MessageDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MessageDetail;
