// types
export const Types = {
  REQUEST_MESSAGE_GET_GROUP_INCEDENT: "REQUEST_MESSAGE_GET_GROUP_INCEDENT",
  SUCCESS_MESSAGE_GET_GROUP_INCIDENT: "SUCCESS_MESSAGE_GET_GROUP_INCIDENT",
  FAILURE_MESSAGE_GET_GROUP_INCIDENT: "FAILURE_MESSAGE_GET_GROUP_INCIDENT",
  CHANGE_MESSAGE_GROUP_INCIDENT: "CHANGE_MESSAGE_GROUP_INCIDENT"
};

const INITIAL_VALUES = {
  loading: true,
  error: false,
  data: [],
  dataEn: [],
  group: null
};

export default function reducer(state = INITIAL_VALUES, action) {
  switch (action.type) {
    case Types.REQUEST_MESSAGE_GET_GROUP_INCEDENT:
      return {
        ...state,
        loading: true
      };
    case Types.SUCCESS_MESSAGE_GET_GROUP_INCIDENT:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
        dataEn: action.payload.dataEn
      };
    case Types.FAILURE_MESSAGE_GET_GROUP_INCIDENT:
      return { ...state, loading: false, error: true };
    case Types.CHANGE_MESSAGE_GROUP_INCIDENT:
      return { ...state, group: action.payload };
    default:
      return state;
  }
}

export function getGroups(payload) {
  return { type: Types.REQUEST_MESSAGE_GET_GROUP_INCEDENT, payload };
}

export function setGroup(payload) {
  return { type: Types.CHANGE_MESSAGE_GROUP_INCIDENT, payload };
}
