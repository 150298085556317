import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

export const CategoryIcon = styled(Chip)`
  font-weight: bold;

  svg {
    color: ${props =>
      (props.security && '#bd0f0f') ||
      (props.contigencia && '#FFBB21') ||
      (props.traffic && '#217F4E') ||
      (props.checkin && '#fe5722') ||
      (props.mass && '#26408B')};
  }
`;
