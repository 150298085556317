import React, { Component, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GroupTable from "./Table/index";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

class GroupList extends Component {
  render() {
    const { history } = this.props;
    const buttons = (
      <Fragment>
        <Tooltip
          title="Novo grupo"
          aria-label="Novo grupo"
          fontSize="small"
          style={{ display: "inline-flex" }}
        >
          <Fab
            aria-label={"Criar novo grupo"}
            color={"primary"}
            size={"medium"}
            style={{ position: "fixed", right: 15, bottom: 20 }}
            onClick={() => history.push("/grupo/novo")}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Fragment>
    );
    return (
      <Fragment>
        {buttons}
        <Paper style={{ margin: 20, marginLeft: 20, marginRight: 80 }}>
          <GroupTable history={history} />
        </Paper>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const mapStateToProps = state => {
  return {};
};

// Decorator para Redux Form
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupList);
