import { takeLatest, put, call, all, select } from "redux-saga/effects";
import { Types } from "../../../../ducks/monit/message/create/groups";
import { getGroups as getGroupsApi } from "../../../../../services/monit";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGet(payload, customers, language_id) {
  return getGroupsApi(
    payload.tokenAuthorization,
    language_id === 1
      ? {
          push_notification: payload.push_notification,
          sms_notification: payload.sms_notification,
          status: 1,
          customer_id: customers,
          application_id: process.env.REACT_APP_APPLICATION_ID,
          language_id,
          category_incident_id:
            payload.category_incident_id.category_incident_id,
          criticality: payload.criticality,
          lat: payload.incident.location.lat,
          lng: payload.incident.location.lng
        }
      : {
          push_notification: payload.push_notificationEn,
          sms_notification: payload.sms_notificationEn,
          status: 1,
          customer_id: customers,
          application_id: process.env.REACT_APP_APPLICATION_ID,
          language_id,
          category_incident_id:
            payload.category_incident_id.category_incident_id,
          criticality: payload.criticality,
          lat: payload.incident.location.lat,
          lng: payload.incident.location.lng
        }
  );
}

function* getGroups(action) {
  try {
    let customers = yield select(
      state => state.MonitCustomersCreateMessage.data
    );

    customers = customers.map(item => item.id);
    const responsePt = yield call(apiGet, action.payload, customers, 1);

    const responseEn = yield call(apiGet, action.payload, customers, 2);
    yield put({
      type: Types.SUCCESS_MESSAGE_GET_GROUP_INCIDENT,
      payload: { data: responsePt.data, dataEn: responseEn.data }
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_MESSAGE_GET_GROUP_INCIDENT
    });
  }
}

export default function* root() {
  yield all([takeLatest(Types.REQUEST_MESSAGE_GET_GROUP_INCEDENT, getGroups)]);
}
