import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

export const Root = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 959.95px) {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`;

export const Container = styled.div`
  background: #efefef;

  @media (min-width: 600px) {
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: 9999;
  }

  @media (max-width: 599px) {
    flex: 8;
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerInfos = styled.div`
  padding: 20px;
`;

export const PaperContainer = styled(Paper)`
  width: 100%;
  height: 100vh;
  margin-top: 25px;

  .MuiGrid-container {
    flex-wrap: nowrap;
  }
  .MuiGrid-item {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }
`;

export const ContainerImage = styled.div`
  background: #26408b;
  box-shadow: 0px 1px 2px #ccc;
`;

export const ImageSpacing = styled.div`
  padding: 30px;
  text-align: center;

  @media (max-width: 959.95px) {
    align-self: center;
  }
`;

export const ImageLogo = styled.img`
  max-width: 100%;
  width: 210px;
`;

export const Loading = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export const Button = styled(Fab)`
  margin-top: 25px !important;
  margin-left: 30px;
  margin-right: 30px;
  background-color: #26408b !important;
`;

export const ButtonText = styled(Typography)`
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  text-transform: none;
`;

export const Title = styled(Typography)`
  color: #000;
  margin-bottom: 20px !important;
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubTitle = styled(Typography)`
  color: #333;
  margin-bottom: 20px !important;
  font-size: 18px !important;
  text-align: center;
  font-weight: 500 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;
