import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Public from "@material-ui/icons/Public";
import Person from "@material-ui/icons/Person";
import CheckInIcon from "@material-ui/icons/PersonPinCircle";

import Tooltip from "@material-ui/core/Tooltip";

import Filter from "./Filter/index";
import List from "./Table/index";
// import MessageCreate from "../create";
import MessageCreateCheckIn from "../../check-in/index";
import { setModalCheckin } from "../../../store/ducks/check-in";

import { getMessages, setOpenCreate } from "../../../store/ducks/message/list";
import MessageCreate from "../create/index";

import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import { Directions } from "@material-ui/icons";

function Message({
  getMessages,
  setOpenCreate,
  setModalCheckin,
  messages,
  openCreate,
  openCheckin
}) {
  useEffect(() => {
    getMessages();
  }, []);

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    setOpen(false);
  };

  const buttons = (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          bottom: "1vh",
          right: "1vw"
        }}
      >
        <Tooltip
          title="Novo de Check-In"
          aria-label="Novo de Check-In"
          placement="left"
          arrow
          fontSize="small"
          style={{
            backgroundColor: "#26408B",
            color: "#fff",
            display: "inline-flex"
          }}
        >
          <Fab
            aria-label={"Criar mensagem de Check-In"}
            color={"primary"}
            size={"medium"}
            style={{ marginBottom: "1vh" }}
            // onClick={() => history.push("/mensagem/nova")}
            onClick={() => setModalCheckin(true)}
          >
            <CheckInIcon />
          </Fab>
        </Tooltip>
        <Tooltip
          title="Nova Mass Notification"
          aria-label="Nova Mass Notification"
          placement="left"
          arrow
          fontSize="small"
          style={{
            backgroundColor: "#26408B",
            color: "#fff",
            display: "inline-flex"
          }}
        >
          <Fab
            aria-label={"Criar nova mensagem"}
            color={"primary"}
            size={"medium"}
            style={{ marginBottom: "1vh" }}
            // onClick={() => history.push("/mensagem/nova")}
            onClick={() => setOpenCreate(true, "MASS_NOTIFICATION")}
          >
            <Public />
          </Fab>
        </Tooltip>
        <Tooltip
          title="Novo Comunicado"
          aria-label="Novo Comunicado"
          placement="left"
          arrow
          fontSize="small"
          style={{
            backgroundColor: "#26408B",
            color: "#fff",
            display: "inline-flex"
          }}
        >
          <Fab
            aria-label={"Criar mensagem de Check-In"}
            color={"primary"}
            size={"medium"}
            style={{ marginBottom: "1vh" }}
            // onClick={() => history.push("/mensagem/nova")}
            onClick={() => setOpenCreate(true, "CORPORATE")}
          >
            <Person />
          </Fab>
        </Tooltip>
      </div>
    </Fragment>
  );

  const messateType = <Fragment></Fragment>;

  return (
    <div style={style.root}>
      <Fragment>
        {buttons}
        {open && messateType}
        {openCreate && <MessageCreate />}
        {openCheckin && <MessageCreateCheckIn />}

        <Paper style={{ margin: 20, marginLeft: 20, marginRight: 80 }}>
          <Filter />

          <List />
        </Paper>
      </Fragment>
    </div>
  );
}

const style = {
  root: {
    flexGrow: 1,
    padding: 0,
    margin: 0
  }
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getMessages, setOpenCreate, setModalCheckin }, dispatch);

const mapStateToProps = state => {
  return {
    messages: state.MessageList.message,
    openCreate: state.MessageList.openCreate,
    openCheckin: state.CheckIn.modalCheckin
  };
};

// Decorator para Redux Form
export default connect(mapStateToProps, mapDispatchToProps)(Message);
