import React, { Component } from "react";
import { connect } from "react-redux";
//import "./LoginView.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";

import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
//import Link from "@material-ui/core/Link";
import MaterialInputTextRedux from "../material/Inputs/text";
import MaterialInputCheckboxRedux from "../material/Inputs/checkbox";

import { login } from "../../store/ducks/main/login";
import { reduxForm, Field, change } from "redux-form";

import { bindActionCreators } from "redux";

class LoginForm extends Component {
  loginEvent = values => {
    const { login } = this.props;

    login(values);
  };

  componentDidMount = () => {
    if (
      localStorage.getItem("rememberLogin") != null &&
      typeof localStorage.getItem("rememberLogin") != undefined
    ) {
      let payload = JSON.parse(localStorage.getItem("rememberLogin"));
      if (payload.remember) {
        this.props.change("email", payload.email);
      }
    }
  };

  render() {
    let loadingCircle = this.props.loginLoading ? (
      <CircularProgress color="primary" size={50} />
    ) : (
      <Fab
        style={{ marginTop: 50 }}
        variant="extended"
        size="medium"
        type="submit"
        color="primary"
        aria-label="Entrar"
        //  onClick={this.loginEvent}
      >
        <span style={{ paddingLeft: 20, paddingRight: 20 }}>Entrar</span>
      </Fab>
    );
    return (
      <div
        style={{
          textAlign: "center"
        }}
      >
        <div>
          <Typography style={style.textTitle}> Bem-vindo </Typography>
          <Typography style={style.textSubTitle}>
            Por favor entre com suas credenciais.
          </Typography>
        </div>

        <form onSubmit={this.props.handleSubmit(this.loginEvent)}>
          <div>
            <div
              style={{
                textAlign: "left"
              }}
            >
              <Field
                name="email"
                type="email"
                inputLabel="Email"
                placeholder="Digite seu e-mail"
                //validate={[required]}
                component={MaterialInputTextRedux}
                id="email"
                autoFocus
              />
              {/*<TextField
                required
                style={{ width: "100%" }}
                id="email"
                label="Email"
                value={this.props.fieldEmail || ""}
                error={this.props.errorEmail ? true : false}
                helperText={this.props.errorEmail || ""}
                ref="edtUsername"
                margin="normal"
                onChange={e => this.props.changeEmail(e.target.value)}
              />
              */}
            </div>
            <div
              style={{
                textAlign: "left"
              }}
            >
              <Field
                name="password"
                type="password"
                inputLabel="Senha"
                placeholder="Digite o senha "
                //validate={[required]}
                component={MaterialInputTextRedux}
                id="password"
                autoFocus
              />
              {/* <TextField
                required
                style={{ width: "100%" }}
                id="standard-required"
                label="Senha"
                value={this.props.fieldPassword || ""}
                ref="edtPassword"
                margin="normal"
                error={this.props.errorPassword ? true : false}
                helperText={this.props.errorPassword || ""}
                onChange={e => this.props.changePassword(e.target.value)}
                type="password"
             /> */}
            </div>

            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <Field
                name="remember"
                type="checkbox"
                inputLabel="Lembre-me"
                component={MaterialInputCheckboxRedux}
                id="remember"
                autoFocus
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#26408B" }}
                    checked={this.props.remember || false}
                    // onChange={e => this.props.changeRemember(e.target.checked)}
                  />
                }
                label="Lembre-me"
              />*/}
              <Link
                target="_black"
                href="https://customer.grupounicad.com.br/password/forgot"
                style={{ cursor: "pointer", marginTop: 10 }}
              >
                Esqueceu a senha ?
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              {loadingCircle}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const style = {
  textTitle: {
    fontSize: 40,
    fontWeight: 600,
    color: "#333333"
  },
  textSubTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: "#999999",
    marginBottom: 50
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      change
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    loginLoading: state.Login.loading
  };
};

LoginForm = reduxForm({ form: "LOGIN_FORM" })(LoginForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
