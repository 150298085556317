import { takeLatest, put, call, select, all } from "redux-saga/effects";
import { Types } from "../../ducks/group/listContacts";
import axios from "../../../services/api.js";
import { startSubmit, stopSubmit } from "redux-form";

/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGetAssociationGroup(group) {
  return axios.get(`/group/${group.id}/contacts`);
}

function apiGet(customer) {
  return axios.get(`/contacts?customer_id=${customer.id}`);
}

function filterStatus(contacts, status) {
  let status_active = status === 1 ? true : false;
  return contacts.filter(item => {
    if (item.contact) return item.contact.status_active === status_active;
    else if (status_active) return item;
    return false;
  });
}

function filterNameOrCel(contacts, search) {
  return contacts.filter(item => {
    if (
      item.name
        .toUpperCase()
        .trim()
        .includes(search.toUpperCase().trim())
    ) {
      return item;
    }
    if (item.phones[0] && item.phones[0].name.includes(search)) return item;
    return false;
  });
}

function filterReceive(contacts, search) {
  return contacts.filter(item => {
    if (item.contact && item.contact.recieve_and_send_option === search)
      return item;
    else if (!item.contact && search === 5) return item;

    return false;
  });
}
function* Contacts() {
  try {
    const customer = yield select(state => state.Customers.customer);
    const group = yield select(state => state.ListGroups.group);

    let totalContactsSelected = 0;

    const resp = yield call(apiGet, customer);
    const respAssociationGroup = yield call(apiGetAssociationGroup, group);

    let users_id = [];
    let contactsInGroup = [];
    let contactsNotInGroup = [];
    let contactInGroupInative = [];
    let contactInGroupAtive = [];
    let contactNotInGroupInative = [];
    let contactNotInGroupAtive = [];

    resp.data.contacts.map((contact, index) => {
      let contactInGroup = respAssociationGroup.data.users.find(
        item => item.id === contact.id
      );

      if (contactInGroup) {
        totalContactsSelected++;
        resp.data.contacts[index].initialInGroup = true;
        users_id[contact.id] = true;
        contactsInGroup.push(resp.data.contacts[index]);
      } else {
        resp.data.contacts[index].initialInGroup = false;
        contactsNotInGroup.push(resp.data.contacts[index]);
      }

      return true;
    });

    contactsInGroup.map((contactInGroup, index) => {
      if (contactInGroup.contact) {
        if (contactInGroup.contact.status_active === true) {
          contactInGroupAtive.push(contactInGroup);
        } else {
          contactInGroupInative.push(contactInGroup);
        }
      } else {
        contactInGroupAtive.push(contactInGroup);
      }

      return true;
    });

    contactsNotInGroup.map((contactNotInGroup, index) => {
      if (contactNotInGroup.contact) {
        if (contactNotInGroup.contact.status_active === true) {
          contactNotInGroupAtive.push(contactNotInGroup);
        } else {
          contactNotInGroupInative.push(contactNotInGroup);
        }
      } else {
        contactNotInGroupAtive.push(contactNotInGroup);
      }

      return true;
    });

    let contacts = contactInGroupAtive
      .concat(contactInGroupInative)
      .concat(contactNotInGroupAtive.concat(contactNotInGroupInative));

    yield put({
      type: Types.SUCCESS_LIST_CONTACTS_GROUPS,
      payload: { ...resp.data, contacts }
    });

    yield put({
      type: Types.TOTAL_CONTACTS_GROUP,
      payload: totalContactsSelected
    });

    yield put({
      type: Types.INITIALIZE_FORM_ASSOCIATION_USERS_GROUP,
      payload: users_id
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_LIST_CONTACTS_GROUPS
    });
  }
}

function* FilterContacts(action) {
  yield put(startSubmit("FILTER_CONTACT_GROUPS_FORM"));
  yield put(stopSubmit("FILTER_CONTACT_GROUPS_FORM"));
  try {
    let contacts = {};
    const customer = yield select(state => state.Customers.customer);

    const group = yield select(state => state.ListGroups.group);

    const resp = yield call(apiGet, customer);

    let contactsFilter = resp.data.contacts;
    let dataForm = action.payload.formData;

    if (dataForm.search_input)
      contactsFilter = yield call(
        filterNameOrCel,
        contactsFilter,
        dataForm.search_input
      );

    if (dataForm.search_input)
      contactsFilter = yield call(
        filterNameOrCel,
        contactsFilter,
        dataForm.search_input
      );

    if (dataForm.status_active && dataForm.status_active !== 3)
      contactsFilter = yield call(
        filterStatus,
        contactsFilter,
        dataForm.status_active
      );

    if (dataForm.recieve && dataForm.recieve !== 4) {
      contactsFilter = yield call(
        filterReceive,
        contactsFilter,
        dataForm.recieve
      );
    }

    const respAssociationGroup = yield call(apiGetAssociationGroup, group);

    if (respAssociationGroup.status === 200) {
      let contactsInGroup = [];
      let contactsNotInGroup = [];
      let contactInGroupInative = [];
      let contactInGroupAtive = [];
      let contactNotInGroupInative = [];
      let contactNotInGroupAtive = [];

      contactsFilter.map((contact, index) => {
        let contactInGroup = respAssociationGroup.data.users.find(
          item => item.id === contact.id
        );

        if (contactInGroup) {
          contactsFilter[index].initialInGroup = true;
          contactsInGroup.push(contactsFilter[index]);
        } else {
          contactsFilter[index].initialInGroup = false;
          contactsNotInGroup.push(contactsFilter[index]);
        }
        return true;
      });

      contactsInGroup.map((contactInGroup, index) => {
        if (contactInGroup.contact) {
          if (contactInGroup.contact.status_active === true) {
            contactInGroupAtive.push(contactInGroup);
          } else {
            contactInGroupInative.push(contactInGroup);
          }
        } else {
          contactInGroupAtive.push(contactInGroup);
        }

        return true;
      });

      contactsNotInGroup.map((contactNotInGroup, index) => {
        if (contactNotInGroup.contact) {
          if (contactNotInGroup.contact.status_active === true) {
            contactNotInGroupAtive.push(contactNotInGroup);
          } else {
            contactNotInGroupInative.push(contactNotInGroup);
          }
        } else {
          contactNotInGroupAtive.push(contactNotInGroup);
        }

        return true;
      });

      let contactsMerge = contactInGroupAtive
        .concat(contactInGroupInative)
        .concat(contactNotInGroupAtive.concat(contactNotInGroupInative));

      contacts = {
        contacts: contactsMerge,
        total: contactsMerge.length
      };
    }

    yield put({
      type: Types.SUCCESS_LIST_CONTACTS_GROUPS,
      payload: contacts
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_LIST_CONTACTS_GROUPS
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(Types.REQUEST_LIST_CONTACTS_GROUPS, Contacts),
    takeLatest(Types.FILTER_CONTACT_LIST_GROUPS, FilterContacts)
  ]);
}
