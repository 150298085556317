import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { toogleMessage } from "../../../src/store/ducks/main/message";

import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class Alert extends PureComponent {
  render() {
    MySnackbarContentWrapper.propTypes = {
      className: PropTypes.string,
      message: PropTypes.node,
      onClose: PropTypes.func,
      variant: PropTypes.oneOf(["success", "warning", "error", "info"])
        .isRequired
    };

    const { toogleMessage, variant, message, showMessage } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={showMessage}
          autoHideDuration={2000}
          onClose={() => toogleMessage()}
        >
          <div>
            {message && (
              <MySnackbarContentWrapper
                onClose={() => toogleMessage()}
                variant={variant}
                message={message}
              />
            )}
          </div>
        </Snackbar>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toogleMessage }, dispatch);

const mapStateToProps = state => {
  return {
    showMessage: state.Message.showMessage,
    message: state.Message.message,
    variant: state.Message.variant
  };
};

// Decorator para Redux Form
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);
