import { takeLatest, put, call } from "redux-saga/effects";
import { Types } from "../../ducks/coverageArea";
import axios from "../../../services/api.js";
/*

takeEvery - adiciona um apos o outro
takeLatest - adiciona somente o ultimo
put - lançar uma  action
select - pegar dados da store
yield select (state => state.markInfoBox.isOpen);

*/

function apiGetCustom() {
  return axios.post(`/areas`, {
    types: ["custom_areas"]
  });
}

function* getCustomCoverageAreas() {
  try {
    const resp = yield call(apiGetCustom);
    yield put({
      type: Types.SUCCESS_GET_CUSTOM_COVERAGE_AREAS,
      payload: resp.data
    });
  } catch (error) {
    yield put({
      type: Types.FAILURE_GET_CUSTOM_COVERAGE_AREAS
    });
  }
}

export default function* root() {
  yield takeLatest(
    Types.REQUEST_GET_CUSTOM_COVERAGE_AREAS,
    getCustomCoverageAreas
  );
}
